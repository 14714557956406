import React from "react";
import theme from "../../styles/theme";
import { Stack, Typography } from "@mui/material";

export function TrapezoidCard({
    height = "300px",
    title,
    title2,
    coloredTitle,
    coloredTitle2,
    bodyColor = theme.palette.littleBand.pianogogoDesc,
    number,
    brandName,
    children,
}) {
    return (
        <Stack
            position="relative"
            alignItems="center"
            sx={{
                width: { xs: "180px", md: "230px", lg: "270px" }, //sm: `calc(${width} + 20px)`
                height: { xs: height, md: "340px", lg: "350px" },
                border: `1px solid ${theme.palette.constant.lightGray}`,
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "60px",
                borderBottomLeftRadius: "40px",
                borderBottomRightRadius: "20px",
                bgcolor: bodyColor,
                // mb: "16px",
                ml: { sm: number % 2 === 0 ? 2 : number === 3 ? 2 : 0 },
                mt: { sm: number % 2 === 0 ? 8 : 0, md: number % 2 === 0 ? 7 : 0 },
            }}>
            {coloredTitle2 && title2 ? (
                <Typography
                    sx={{
                        width: "100%",
                        fontFamily: "JalnanGothic",
                        fontSize: { xs: "24px", md: "30px", xl: "32px" },
                        fontWeight: 700,
                        userSelect: "none",
                        padding: { xs: "32px 60px 32px 15px", md: "32px 60px 32px 15px" },
                        span: { display: "block" },
                    }}>
                    {title}
                    <span style={{ color: theme.palette[brandName].main }}>{coloredTitle2}</span>
                    {title2}
                </Typography>
            ) : (
                <Typography
                    sx={{
                        width: "100%",
                        fontFamily: "JalnanGothic",
                        fontSize: { xs: "24px", md: "30px", xl: "32px" },
                        fontWeight: 700,
                        userSelect: "none",
                        padding: { xs: "32px 60px 32px 15px", md: "32px 60px 32px 15px" },
                        span: { display: "block" },
                    }}>
                    <span style={{ color: theme.palette[brandName].main }}>{coloredTitle}</span>
                    <span>{title}</span>
                    <span>{title2}</span>
                </Typography>
            )}

            <Typography
                position="absolute"
                sx={{
                    bottom: 0,
                    right: "-0.2px",
                    width: "100%",
                    fontSize: { xs: "13px", md: "15px", lg: "17px" },
                    padding: "24px 15px",
                    bgcolor: "white",
                    borderBottomLeftRadius: "40px",
                    borderBottomRightRadius: "20px",
                    span: { display: "block" },
                }}>
                {children}
            </Typography>
        </Stack>
    );
}
