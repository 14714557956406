const music = [
    {
        params: "500",
        title: "제4제 27회 전국 어린음악대 워크샵(1)",
        date: "2023.12.05",
        thumbnail: "/images/gallery/DSC00596.JPG",
        type: "갤러리",
        des: [
            "/images/gallery/KakaoTalk_20231204_155507790_01.jpg",
            "/images/gallery/DSC01181.JPG",
            "/images/gallery/DSC01184.JPG",
            "/images/gallery/DSC00055.JPG",
            "/images/gallery/KakaoTalk_20231204_155507790_19.jpg",
            "/images/gallery/KakaoTalk_20231204_155507790_20.jpg",
            "/images/gallery/DSC00596.JPG",
            "/images/gallery/DSC01077.JPG",
            "/images/gallery/KakaoTalk_20231204_155507790_23.jpg",
            "/images/gallery/KakaoTalk_20231204_155507790_07.jpg",
        ],
    },
];
const art = [
    {
        params: "123",
        title: "제4회 어린화가들 학원장/교사 워크숍",
        date: "2020.02.01",
        thumbnail: "/images/gallery/748019529.jpg",
        type: "갤러리",
        des: [
            "/images/gallery/748019529.jpg",
            "/images/gallery/854333592.jpg",
            "/images/gallery/854333592.jpg",
            "/images/gallery/615513105.jpg",
            "/images/gallery/1531097939.jpg",
            "/images/gallery/1814098154.jpg",
            "/images/gallery/1326997493.jpg",
        ],
    },
    {
        params: "124",
        title: "헬리오시티원 학부모세미나 개최!",
        date: "2019.12.12",
        thumbnail: "/images/gallery/1858890957_KakaoTalk_20191212_143912100.jpg",
        type: "갤러리",
        des: [
            "/images/gallery/1293254432_KakaoTalk_20191212_143912100_04.jpg",
            "/images/gallery/1365575109_KakaoTalk_20191212_143912100_02.jpg",
            "/images/gallery/1633137846_KakaoTalk_20191212_143912100_01.jpg",
            "/images/gallery/1858890957_KakaoTalk_20191212_143912100.jpg",
        ],
    },
];
const jueun = [
    {
        params: "600",
        title: "제4제 27회 전국 어린음악대 워크샵",
        date: "2023.12.05",
        thumbnail: "/images/gallery/KakaoTalk_20231204_155507790_23.jpg",
        type: "갤러리",
        des: [
            "/images/gallery/KakaoTalk_20231204_155507790_01.jpg",
            "/images/gallery/DSC01181.JPG",
            "/images/gallery/DSC01184.JPG",
            "/images/gallery/DSC00055.JPG",
            "/images/gallery/KakaoTalk_20231204_155507790_19.jpg",
            "/images/gallery/KakaoTalk_20231204_155507790_20.jpg",
            "/images/gallery/DSC00596.JPG",
            "/images/gallery/DSC01077.JPG",
            "/images/gallery/KakaoTalk_20231204_155507790_23.jpg",
            "/images/gallery/KakaoTalk_20231204_155507790_07.jpg",
        ],
    },
];
const gallery_data = { music, art, jueun };

export default gallery_data;
