import React, { Fragment } from "react";
import Slider from "react-slick";
import styled from "@emotion/styled";

import { Stack, Typography } from "@mui/material";

import NextArrowBtn from "../elements/slickButtons/NextArrowBtn";
import PrevArrowBtn from "../elements/slickButtons/PrevArrowBtn";

export default function ImgSlickCard({ sliderArr, descArr, sliderStyle, stackStyle, imgStyle, btnBgColor, children }) {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1700,
        speed: 700,
        nextArrow: <NextArrowBtn btnBgColor={btnBgColor} />,
        prevArrow: <PrevArrowBtn btnBgColor={btnBgColor} />,
    };

    return (
        <Stack>
            {/* img slider */}
            <Stack
                sx={{
                    borderRadius: descArr ? "30px 30px 0 0" : "30px 30px 30px 30px",
                    border: "1px solid",
                    borderColor: "constant.lightGray",
                    overflow: "hidden",
                    bgcolor: "#f5f5f5",
                    ...sliderStyle,
                }}>
                {sliderArr.length === 1 ? (
                    <Stack
                        sx={{
                            py: 3,
                            backgroundColor: sliderArr[0].backgroundColor
                                ? sliderArr[0].backgroundColor
                                : "transparent",
                        }}>
                        <img src={sliderArr[0].imgSrc} style={imgStyle} alt="card img" />
                    </Stack>
                ) : (
                    <CustomSlider {...settings}>
                        {sliderArr.map(item => {
                            return (
                                <Fragment key={item.imgSrc}>
                                    <Stack
                                        className="image-box"
                                        sx={{
                                            // py: 3,
                                            backgroundColor: item.backgroundColor
                                                ? item.backgroundColor
                                                : "transparent",
                                            ...stackStyle,
                                        }}>
                                        <img src={item.imgSrc} style={imgStyle} alt="card img" />
                                    </Stack>
                                </Fragment>
                            );
                        })}
                    </CustomSlider>
                )}
                {/* slickslide 밑에 설명 필요한 경우 사용 */}
                {children && (
                    <Typography
                        variant="subtitle3"
                        sx={{ fontWeight: 500, textAlign: "center", bgcolor: "white", p: "20px 68px" }}>
                        {children}
                    </Typography>
                )}
            </Stack>
            {/* description */}
            {descArr && (
                <Stack
                    spacing={1}
                    sx={{
                        py: 3,
                        px: 2,
                        alignItems: "center",
                        borderLeft: "1px solid",
                        borderBottom: "1px solid",
                        borderRight: "1px solid",
                        borderColor: "constant.lightGray",
                        borderRadius: "0 0 30px 30px",
                        backgroundColor: "constant.white",
                    }}>
                    {descArr.map((item, index) => {
                        return (
                            <Stack
                                key={index}
                                flexDirection="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                sx={{
                                    width: "100%",
                                    height: { xs: "49px" },
                                    p: " 7px 6px 6px 7px",
                                    bgcolor: "#f5f5f5",
                                    borderRadius: "30px",
                                }}>
                                <Typography
                                    sx={{
                                        width: "36px",
                                        height: "36px",
                                        textAlign: "center",
                                        lineHeight: 1.2,
                                        borderRadius: "50px",
                                        bgcolor: "white",
                                        color: "#3390ff",
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        padding: "5px 13px 7px",
                                    }}>
                                    {index + 1}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        ml: 1,
                                    }}>
                                    {item}
                                </Typography>
                            </Stack>
                        );
                    })}
                </Stack>
            )}
        </Stack>
    );
}

const CustomSlider = styled(Slider)`
    .image-box {
        display: flex !important;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    #slick-next-button {
        top: calc(50% - 20px);
        right: 0;
        margin-right: 8px;
    }

    #slick-prev-button {
        top: calc(50% - 20px);
        left: 0;
        margin-left: 8px;
    }
`;
