import React from "react";

import { Stack, Typography } from "@mui/material";

import ContainerLayout from "../../layouts/ContainerLayout";
import { TitleComponents } from "../../titleComponent/TitleComponents";

export default function HistoryTab() {
    return (
        <ContainerLayout sx={{ pt: 8 }}>
            <Stack alignItems="center" sx={{ width: "100%" }}>
                <TitleComponents sx={{ mb: 5.5 }}>
                    <TitleComponents.MainTitle>기업연혁</TitleComponents.MainTitle>
                    <TitleComponents.Description>
                        <span>한걸음씩 걸어온</span>
                        <span>주은교육의 발자취를 담았습니다</span>
                    </TitleComponents.Description>
                </TitleComponents>
            </Stack>
            {/* 연혁 */}
            <Stack alignItems="center" sx={{ width: "100%" }}>
                <Stack
                    sx={{
                        ml: 1,
                        mb: 8,
                        pl: 3,
                        pb: 4,
                        borderLeft: "1px solid",
                        borderColor: "constant.darkGray",
                    }}>
                    {historyArr.map((item, index) => (
                        <Stack key={`${item.year}`} sx={{ position: "relative", mt: index === 0 ? "-12px" : 4 }}>
                            <RedCircle />
                            <Stack sx={{ mb: 2 }}>
                                <Typography variant="h3" sx={{ color: "primary.main" }}>
                                    {item.year}
                                </Typography>
                            </Stack>
                            <Stack sx={{ span: { display: "block" } }}>
                                <Typography variant="body1" sx={{ lineHeight: 1.3 }}>
                                    {item.description}
                                </Typography>
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}

function RedCircle() {
    return (
        <Stack
            sx={{
                position: "absolute",
                width: 16,
                height: 16,
                top: 10,
                left: -33,
                border: "2px solid",
                backgroundColor: "constant.white",
                borderColor: "primary.main",
                borderRadius: "50%",
            }}></Stack>
    );
}

const historyArr = [
    {
        year: 2023,
        description: (
            <>
                <span>e피아노고고 드럼 서비스 오픈</span>
                &nbsp;
                <span>e피아노고고 아트톡톡 서비스 오픈</span>
                &nbsp;
                <span>e피아노고고 체험 서비스 오픈</span>
                &nbsp;
                <span>5월, 주은교육 창립 15주년</span>
                &nbsp;
            </>
        ),
    },
    {
        year: 2022,
        description: (
            <>
                <span>e피아노고고/학원관리자(HMS) 서비스 오픈</span>
                &nbsp;
                <span>악보 제작 기능 등 발명특허 출원 및 등록</span>
                &nbsp;
                <span>원격 피아노 레슨 등 발명특허 출원 및 등록</span>
                &nbsp;
                <span>상표/디자인권 5건 출원, S/W저작권 3건 등록</span>
                &nbsp;
                <span>뮤직고고 러너 서비스 출시</span>
                &nbsp;
            </>
        ),
    },
    {
        year: 2021,
        description: (
            <>
                <span>뮤직고고 사이트(https://musicgogo.co.kr) 오픈</span>
                &nbsp;
                <span>4월, 정부과제 디자인혁신역량강화사업 주관 업체 선정</span>
                &nbsp;
                <span>1월, 주은교육 기업 정보 보호 컨설팅 사업참여 및 우수사례 선정</span>
                &nbsp;
            </>
        ),
    },
    {
        year: 2020,
        description: (
            <>
                <span>3월, 서울사무소(송파구) 오픈</span>
                &nbsp;
                <span>7월, 20회 어린음악대 워크숍 온라인 라이브 진행</span>
                &nbsp;
                <span>캄보디아 1호원 센속원, 2호원 칩몽원 오픈</span>
                &nbsp;
                <span>10월, 아이뮤즈몰(https://imuz.co.kr) 리뉴얼</span>
                &nbsp;
            </>
        ),
    },
    {
        year: 2019,
        description: (
            <>
                <span>4월,직영원 헬리오시티원 어린음악대, 어린화가들 개원 및 학부모설명회</span>
                &nbsp;
                <span>5월,중국 엉클샘교육그룹과 업무협약식</span>
                &nbsp;
                <span>6월,중국 엉클샘교육그룹과 함께 '주은베이징' 설립</span>
                &nbsp;
                <span>대한민국 우수기업대상, 교육대상 수상</span>
                &nbsp;
            </>
        ),
    },
    {
        year: 2018,
        description: (
            <>
                <span>5월, 주은교육 창립 10주년</span>
                &nbsp;
                <span>8월, 제1회 비르투오소 피아노 콩쿠르 개최</span>
                &nbsp;
                <span>전국순회 경영특강 및 개원교육</span>
                &nbsp;
                <span>2018년 소비자가 뽑은 가장 신뢰하는 브랜드 대상 수상(음악학원프랜차이즈부문)</span>
                &nbsp;
            </>
        ),
    },
    {
        year: 2017,
        description: (
            <>
                <span>2월, 제1회 어린화가들 가맹원 워크숍</span>
                &nbsp;
                <span>직영 융합센터 어린음악대,어린화가들 '죽곡원'오픈</span>
                &nbsp;
                <span>어린음악대,어린화가들 총 300개원 돌파</span>
                &nbsp;
                <span>텅드럼 1급,2급 자격증 과정 개설</span>
                &nbsp;
            </>
        ),
    },
    {
        year: 2016,
        description: (
            <>
                <span>(주)알톤스포츠 제휴</span>
                &nbsp;
                <span>어린음악대 244개원 가맹</span>
                &nbsp;
                <span>어린화가들 34개원 가맹</span>
                &nbsp;
                <span>어린음악대 홈페이지_모바일 ver 출시</span>
                &nbsp;
                <span>어린음악대 모바일 App 출시</span>
            </>
        ),
    },
    {
        year: 2015,
        description: (
            <>
                <span>어린음악대 독점 교재 'WOW 이론&계이름' 런칭</span>
                &nbsp;
                <span>오은영박사 전국 강의 후원</span>
                &nbsp;
                <span>어린음악대 220개원 가맹</span>
                &nbsp;
                <span>어린화가들 22개원 가맹</span>
                &nbsp;
                <span>통합형 레슨프로그램 피아노고고 런칭</span>
            </>
        ),
    },
    {
        year: 2014,
        description: (
            <>
                <span>어린화가들 런칭</span>
                &nbsp;
                <span>어린화가들 홈페이지 오픈</span>
                &nbsp;
                <span>온라인 쇼핑몰 아이뮤즈몰 오픈</span>
                &nbsp;
                <span>어린음악대 쿠팡 프로모션</span>
                &nbsp;
                <span>어린음악대 180개원 가맹</span>
                &nbsp;
                <span>한국헤르만헤세 MOU 체결</span>
            </>
        ),
    },
    {
        year: 2013,
        description: (
            <>
                <span>어린음악대 독점교재 레슨 GOGO 런칭</span>
                &nbsp;
                <span>어린음악대 독점교재 씽씽 GOGO 런칭</span>
                &nbsp;
                <span>어린음악대 독점 교재 베이직 GOGO(이론, 실기) 런칭</span>
                &nbsp;
                <span>어린음악대 148개원 가맹</span>
                &nbsp;
                <span>뮤직고고 단계별 워크북 런칭</span>
            </>
        ),
    },
    {
        year: 2012,
        description: (
            <>
                <span>어린음악대 독점교재 런칭 이론고고&계이름고고</span>
                &nbsp;
                <span>어린음악대 103개원 가맹</span>
                &nbsp;
                <span>어린음악대 홈페이지 리뉴얼</span>
            </>
        ),
    },
    {
        year: 2011,
        description: (
            <>
                <span>음악체험 통합놀이교육 뮤직고고 런칭</span>
                &nbsp;
                <span>전국 860여 초등학교 납품</span>
                &nbsp;
                <span>품질경영시스템(ISO) 획득</span>
                &nbsp;
                <span>공정거래위원회 가맹사업거래 정보공개서 등록</span>
                &nbsp;
                <span>어린음악대 61개원 가맹</span>
            </>
        ),
    },
    {
        year: 2010,
        description: (
            <>
                <span>어린음악대 21개원 가맹</span>
                &nbsp;
                <span>어린음악대 홈페이지 오픈</span>
            </>
        ),
    },
    {
        year: 2009,
        description: (
            <>
                <span>조달업체 등록</span>
                &nbsp;
                <span>'음표랑 놀자', '쉼표랑 놀자' 출판</span>
                &nbsp;
                <span>세광출판사 계약체결</span>
                &nbsp;
                <span>홈플러스 납품 계약체결</span>
                &nbsp;
                <span>아이디어 상호지원사업 선정(중소기업청)</span>
            </>
        ),
    },
    {
        year: 2008,
        description: (
            <>
                <span>교구 특허 획득(오감을 느낄 수 있는 교육용 교구)</span>
                &nbsp;
                <span>법인설립 - 주은교육(주)</span>
                &nbsp;
                <span>영아트 OEM 생산 납품 계약체결</span>
                &nbsp;
                <span>이마트 납품 계약 체결(100개점 입점)</span>
                &nbsp;
                <span>'나도 피아노를 배워요'(1~6권) 출판</span>
                &nbsp;
                <span>세광출판사 계약체결</span>
            </>
        ),
    },
    {
        year: 2007,
        description: (
            <>
                <span>GS마트 납품 계약체결</span>
                &nbsp;
                <span>교보문고, 영풍문고 전국점 판매 계약</span>
                &nbsp;
                <span>'푸른아이'에서 '주은교육'으로 상호 변경</span>
            </>
        ),
    },
    {
        year: 2006,
        description: (
            <>
                <span>'푸른아이' 사업자 등록</span>
                &nbsp;
                <span>세광출판사 음악교구 납품 계약체결</span>
                &nbsp;
                <span>교구 특허출원(오감을 느낄 수 있는 교육용 교구)</span>
            </>
        ),
    },
    {
        year: 2005,
        description: (
            <>
                <span>찍찍이의 음악여행 음악 전문 교구 출시</span>
            </>
        ),
    },
];
