import React from "react";
import RoundedButton from "./RoundedButton";

export default function ImuzmallLinkButton() {
    return (
        <RoundedButton
            onClick={() => window.open("https://imuz.co.kr/product/list.html?cate_no=399")}
            color="littleBand.main"
            size="large"
            otherHoverColor="constant.white"
            startIcon={
                <img
                    src="/images/imuzemall/imuzmall-button-icon.svg"
                    alt="imuzmall icon"
                    style={{ marginBottom: 2, marginRight: 4 }}
                />
            }>
            바로가기
        </RoundedButton>
    );
}
