const music = [
    {
        params: "100",
        date: "2024.01.23",
        title: "어린음악대 제69기 개원교육 진행!",
        thumbnail: "/images/news/KakaoTalk_20240122_103923377.jpg",
        type: "공지",
        des: [
            {
                text: `안녕하세요~!!!
지난 토요일 1월20일(토) 에는 어린음악대 제 69기 개원교육이 
대구 직영원인 어린음악대 어린화가들 호산원에서 진행되었어요^^
어린음악대의 정체성부터 피아노고고 교육 등에대해
정말 뜨겁게 활기차게 진행되었는데요.

원장님께서 열정적으로 수업에 참여해주신 덕분에 
제 69기 교육도 즐겁게 
마무리 할 수 있었습니다^^

아침 이른시간부터 참석해주시고 교육에
잘 참여해주셔서 정말 감사하단 말씀을 드립니다.`,
            },
            { image: "/images/news/KakaoTalk_20240122_103923377.jpg" },
            {
                image: "/images/news/KakaoTalk_20240122_103914957.jpg",
            },

            {
                image: "/images/news/KakaoTalk_20240122_103919353.jpg",
            },

            { image: "/images/news/KakaoTalk_20240122_103919829.jpg" },
        ],
    },
    {
        params: "99",
        date: "2023.11.01",
        title: "제27회 전국 어린음악대 학원장 워크숍 안내",
        thumbnail: "/images/news/littleband/231101-littleband.png",
        type: "소식",
        des: [
            {
                text: `안녕하세요. 주은교육입니다!
아침, 저녁으로 쌀쌀한 날씨가 겨울이 성큼 다가 왔음을 알려주는 것 같습니다.
건강 관리 유의하시기 바랍니다.
2023년 한해를 마무리하고 2024년을 준비하는 〈제27회 전국 어린음악대 학원장 워크숍〉에 대해 안내해드립니다. 학원장 워크숍은 한해를 잘 마무리하고 다가오는 새해를 잘 준비하기 위한 매우 중요한 행사입니다.
접수마감은 2023년 11월 13일(월) 17:00까지이며 원장님들의 많은 참여 부탁드립니다..`,
            },
            { image: "/images/news/littleband/231101-littleband.png" },
        ],
    },
    {
        params: "98",
        date: "2022.06.27",
        title: "제24회 전국 어린음악대 학원장 워크숍 안내",
        thumbnail: "/images/news/littleband/220702-littleband.png",
        type: "소식",
        des: [
            {
                text: `전국 어린음악대 원장님, 안녕하세요.
따사로운 햇살이 가득했던 봄을 지나 여름을 알리는 더위가 성큼 찾아 온 것 같습니다. 일교차가 심하니 건강 관리에 유의하시기 바랍니다.
다름 아니 오라 제 24회 전국 어린음악대 학원장 워크숍에 대해 안내 드리고자 합니다.
4월부터 일상 속 방역 체계로 전환되면서 거리두기가 해제됨에 따라 이번 워크숍은 인원 제한 없이 전국 어린음악대 원장님들을 모두 모시고 오프라인 워크숍을 진행합니다. 움츠렸던 날개를 활짝 펴고 다시 한번 도약하는 마음으로 전국 가맹원 원장님은 반드시 이번 워크숍에 참석해주시기 바랍니다.`,
            },
            { image: "/images/news/littleband/220702-littleband.png" },
        ],
    },
    {
        params: "97",
        date: "2022.04.27",
        title: `[프라임 헤럴드] 창원 어린음악대월영원 강세현 대표, “누구나 음악이 주는 행복을 온전히 느낄 수 있기를”`,
        thumbnail: "/images/news/littleband/220427-littleband-thumbnail.jpg",
        type: "뉴스",
        des: [
            {
                text: `인간과 음악은 오래 전부터 떼놓을 수 없는 관계다. 인간은 지금처럼 악기가 발달하기 전부터도 각종 도구를 이용해 음악을 연주했고 인간에게 중요한 순간마다 음악이 빠지지 않았다. 즐거운 축제의 장에서는 활기찬 선율의 음악으로 기쁜 마음을 드러내고 분위기를 무르익게 했고 반대로 슬픈 상황에서도 음악이 언어를 초월해 인간의 마음을 위로했다.

이처럼 음악은 성별과 나이, 국적을 떠나 인간의 감정을 움직이는 힘을 지녔다. 듣는 사람은 물론, 그 음악을 연주하는 사람에게까지도 심리적인 안정과 즐거움을 가져다주는 것이다. 그런 점에서 음악의 중요성과 역할은 시대가 바뀌어도 결코 무뎌지지 않으며 오히려 점점 커지고 있다.

이와 관련하여 창원에서 어린음악대월영원을 운영하는 강세현 대표를 만나 이야기를 들어보았다.
`,
            },
            { image: "/images/news/littleband/220427-littleband-thumbnail.jpg" },
            {
                text: `Q. 어린음악대월영원의 설립 취지를 말씀해 주십시오.

A. 음악은 현대사회에 필수적인 교육이다. 언어와도 같은 음악을 음악 고유의 방법으로 읽고 느끼고 소통하는 과정에서 삶을 윤택하게 만들고 아이들에게 큰 행복감을 주기 때문이다. 음악교육이 두뇌개발에 도움이 된다는 연구 결과도 있을 정도다. 뿐만 아니라 어릴 때부터 음악교육을 받은 아이들은 그렇지 못한 아이들보다 정서적으로 안정된다는 연구결과도 있다.

그럼 필수적인 음악을 어떻게 하면 더 즐겁고, 더 쉽게 가르치고 배울 수 있을까. 그 고민에 대한 ‘해답’이 바로 어린음악대월영원의 설립 취지다. 쉽게 배우고 즐겁게 배우고 배움의 과정이 행복한 교육 공간을 여기에 만들어 보고자 한다.

Q. 어린음악대월영원의 주 서비스를 소개해 주십시오.

A. 이곳은 유·아동을 전문으로 가르치는 음악학원이지만 성인들이나 입시를 준비하는 학생들도 얼마든지 함께할 수 있다. 누구나 원하는 악기를 익혀 연주할 수 있도록 피아노고고, 뮤직고고, e피아노, 바이올린, 플롯, 기타 등 다양한 수업을 운영하고 있다.
`,
            },
            { image: "/images/news/littleband/220427-littleband-2.jpeg" },
            {
                text: `Q. 어린음악대월영원만의 특징을 말씀해 주십시오.
                
A. 어린음악대월영원은 다른 곳에서 만나보기 어려운 교재와 교구를 자랑한다. 국내 최고의 음악교육 프랜차이즈로서 독점교재와 특허 받은 교구들은 어린음악대에서만 누릴 수 있다.

Q. 어린음악대월영원 운영에 있어 가장 우선으로 보는 가치관과 철학은 무엇입니까?

A. 발전하는 교육과정에 발맞추어 음악교육도 변하고 달라지고 있다. 이처럼 시대가 변하고 아이들이 변하고 있지만, 여전히 낡은 교육방식을 고수하는 음악학원들이 비일비재하다. 교사로서 항상 공부하고 연구하는 태도는 교사의 의무이기도 하다. 꾸준히 공부하고 낮은 자세로 아이들과 눈높이를 맞추는 자세는 교사와 학생이 함께 행복해지는 음악교육의 필수요건이라고 생각한다.

Q. 가장 큰 보람을 느낀 사례나 기억에 남는 순간이 있다면 자유롭게 말씀해 주십시오.

A. 고사리 같은 손으로 피아노를 배우러 왔던 어린 아이들이 어느덧 성인이 되었다. 그 중에는 아이들에게 피아노를 가르치며 나와 같은 일을 하는 제자들도 여럿 생겼다. 재밌고 즐거워서 배웠던 음악이 그 아이들의 희망이 되었고 진로가 되는 장면들을 보게 된 것이다. 나로서는 너무나 감사하고 감동적인 일이다. 특히 “선생님이 피아노를 재밌게 가르쳐주신 덕분에 음악을 사랑하게 되었고 피아노 전공의 길로 갈 수 있었어요” 라는 말을 들었을 때 가슴이 뭉클했다.
`,
            },
            { image: "/images/news/littleband/220427-littleband-3.jpeg" },
            {
                text: `Q. 현재의 사업장과 시스템을 만들 수 있었던 노하우(Know-how)를 말씀해 주십시오.
                
A. 사람을 ‘먼저’ 보는 것이 노하우다. 학생은 물론, 함께 일하는 강사 선생님들, 학부모님들 먼저 눈을 보고 그 사람의 마음을 읽는 것이다. 가장 중요한 것은 사람이고 마음이기 때문이다. 학생과 교사와 학부모가 다 같이 만족하는 교육을 하기 위해서는 서로를 잘 이해하고 최선을 다해야 한다. 설령 실수가 있더라고 잘못을 인정하고 진심으로 다가가는 것이 바른 자세다. ‘하늘을 우러러 한 점 부끄럼 없이….’ 라는 말이 있듯이 내 소신과 마음이 진실이라면 다 통하게 되어 있다고 믿는다.

Q. 앞으로의 전망과 목표를 말씀해 주십시오.

A. 음악학원이라고 하면 어린음악대가 가장 먼저 좋은 느낌으로 떠오르면 좋겠다. 앞으로 이렇게 될 수 있도록 만드는 것이 나의 목표다.

Q. 해당 인터뷰 기사를 접하게 될 독자에게 전하실 말씀이 있다면

A. 음악이 주는 행복을 아는가. 그 행복을 아신다면 놓치지 말길 바란다. 그리고 내 사랑하는 자녀에게 음악교육을 선물해주시면 더없이 좋을 것 같다.`,
            },
            {
                text: "원문 출처 : http://www.primeherald.co.kr/11595",
            },
        ],
    },
    {
        params: "96",
        date: "2022.02.14",
        title: `[아이티비즈] 피아노학원 어린음악대, 블렌디드 음악수업 뮤직고고 러너 오픈`,
        thumbnail: "/images/news/littleband/220214-littleband-thumbnail.jpeg",
        type: "뉴스",
        des: [
            {
                image: "/images/news/littleband/220214-littleband-thumbnail.jpeg",
            },
            {
                text: `유아동 전문 음악학원 어린음악대가 블렌디드 음악수업 The all new뮤직고고 ‘러너’를 오픈한다고 14일 밝혔다. 

뮤직고고는 한국아동예술창의연구소에서 직접 연구, 개발한 체계적이고 전문적인 커리큘럼에 따라 악기연주, 신체활동, 음악이론, 감상&표현 등 음악의 전 영역을 경험하고 표현하는 어린음악대의 시그니처 특강 프로그램이다.

The all new 뮤직고고는 기존 뮤직고고에 새로운 영상컨텐츠와 1인교구패키지를 더해 더욱 확장된 사고와 음악적 경험을 심어주는 프로그램으로 지난해 3월 ‘플레이어”를 처음 런칭하여 학부모들에게 큰 호평을 받았다. 다가오는 3월부터는 새롭게 ‘러너’가 업데이트되어 서비스를 시작한다.

뮤직고고플레이어가 기초과정이라면 러너는 더욱 다채로운 음악활동으로 다양한 음악 경험과 초등 눈높이에 맞춰 확장된 사고와 음악적 역량을 키워갈 수 있는 심화과정이다. 초등 교과서 수록곡을 삽입해 학교 수업과도 연계해 아이들이 쉽고 재미있게 음악을 배울 수 있다.

어린음악대 본사 주은교육 홍혜숙 대표는 “이제 음악교육시장도 디지털 컨텐츠로의 전환이 필요한 시기에 놓여 있다. 다만 음악, 미술과 같은 예술교육의 경우 배우는 환경이나 지도자의 역할이 중요한 만큼 어린음악대는 온라인과 오프라인의 장점을 융합한 블렌디드 수업을 추구하고 있다”며 “앞으로도 다양한 컨텐츠 개발은 물론 시대에 맞춘 새로운 수업형태의 구상도 계속해 우리 아이들에게 최적화된 음악교육을 제공할 것”이라고 강조했다.

한편, 어린음악대 본사 주은교육는 정부 지원 사업중 하나인 ‘음악교육을 위한 UX & 플랫폼 디자인 개발’ 사업의 업체로 선정되어 더욱 풍부한 컨텐츠와 다양한 액티비티가 장착된 피아노레슨프로그램 ‘e피아노고고’를 올 상반기 런칭을 하기 위해 개발에 박차를 가하고 있다.`,
            },
            {
                text: "원문 출처 : https://www.it-b.co.kr/news/articleView.html?idxno=57142",
            },
        ],
    },
    {
        params: "95",
        date: "2022.02.14",
        title: `[서울경제] 피아노학원 어린음악대, 블렌디드 음악수업 뮤직고고 러너 런칭`,
        thumbnail: "/images/news/littleband/220214-littleband-thumbnail.jpeg",
        type: "뉴스",
        des: [
            {
                image: "/images/news/littleband/220214-littleband-thumbnail.jpeg",
            },
            {
                text: `유아동 전문 음악학원 어린음악대가 블렌디드 음악수업 The all new뮤직고고 ‘러너’를 오픈한다고 밝혔다.

뮤직고고는 한국아동예술창의연구소에서 직접 연구, 개발한 체계적이고 전문적인 커리큘럼에 따라 악기연주, 신체활동, 음악이론, 감상&표현 등 음악의 전 영역을 경험하고 표현하는 어린음악대의 시그니처 특강프로그램이다.

The all new 뮤직고고는 기존 뮤직고고에 새로운 영상컨텐츠와 1인교구패키지를 더해 더욱 확장된 사고와 음악적 경험을 심어주는 프로그램으로 지난해 3월 ‘플레이어”를 처음 런칭하여 학부모들에게 큰 호평을 받았다. 다가오는 3월부터는 새롭게 ‘러너’가 업데이트되어 서비스를 시작한다.




뮤직고고플레이어가 기초과정이라면 러너는 더욱 다채로운 음악활동으로 다양한 음악 경험과 초등 눈높이에 맞춰 확장된 사고와 음악적 역량을 키워갈 수 있는 심화과정이다. 초등 교과서 수록곡을 삽입해 학교 수업과도 연계해 아이들이 쉽고 재미있게 음악을 배울 수 있다.

어린음악대 본사 주은교육㈜ 홍혜숙 대표는 “이제 음악교육시장도 디지털 컨텐츠로의 전환이 필요한 시기에 놓여있다. 다만 음악, 미술과 같은 예술교육의 경우 배우는 환경이나 지도자의 역할이 중요한 만큼 어린음악대는 온라인과 오프라인의 장점을 융합한 블렌디드 수업을 추구하고 있다.”며 “앞으로도 다양한 컨텐츠 개발은 물론 시대에 맞춘 새로운 수업형태의 구상도 계속해 우리 아이들에게 최적화된 음악교육을 제공할 것”이라 강조했다.

한편, 어린음악대 본사 주은교육㈜는 정부 지원 사업중 하나인 ‘음악교육을 위한 UX & 플랫폼 디자인 개발’ 사업의 업체로 선정되어 더욱 풍부한 컨텐츠와 다양한 액티비티가 장착된 피아노레슨프로그램 ‘e피아노고고’를 올 상반기 런칭을 하기 위해 개발에 박차를 가하고 있다.`,
            },
            {
                text: "원문 출처 : https://www.sedaily.com/NewsView/2624ZBIY5J",
            },
        ],
    },
    {
        params: "94",
        date: "2021.12.16",
        title: `[서울경제] 에듀테크 기업 주은교육㈜, 피아노 음악 학원 위한 신규 레슨 프로그램 ‘e피아노고고’ 공개`,
        thumbnail: "/images/news/littleband/211216-littleband-thumbnail.jpeg",
        type: "뉴스",
        des: [
            {
                image: "/images/news/littleband/211216-littleband-thumbnail.jpeg",
            },
            {
                text: `유-아동 전문 음악 미술 학원 에듀테크 기업 ‘주은교육㈜’이 정부 지원 사업 중 하나인 ‘음악 교육을 위한 UX & 플랫폼 디자인 개발 사업’ 업체로 선정돼 지난 11일 제23회 전국 어린음악대 학원장 워크숍에서 사업의 진행과 계획을 공유하고 신규 블렌디드 피아노프로그램 ‘e피아노고고’ 데모 버전을 공개했다.

‘e피아노고고’는 피아노 음악 학원 기업 주은교육㈜이 세계최고 수준의 사용자 맞춤 음악 학습 서비스 플랫폼을 개발하겠다는 목표를 가지고 준비한 프로젝트다. 리듬과 테크닉, 음감과 음악이론까지 통합적으로 레슨하는 뮤직 플로우(FLOW) 통합 레슨 프로그램 ‘피아노고고’를 디지털 콘텐츠로 전환해, 온라인과 오프라인 레슨의 장점을 결합한 새로운 형태의 블렌디드 레슨 프로그램이다.

이번 e피아노고고 데모 버전은 어린음악대 아이덴티티를 잘 반영한 음표 캐릭터와 함께 우주여행을 하며 주어지는 미션을 클리어하듯 레슨을 즐길 수 있다. 특히 아이들이 쉽고 재미있게 레슨을 경험하고 음악 이론을 자연스럽게 받아들일 수 있도록 스토리텔링과 함께 다양한 액티비티와 콘텐츠를 짜임새 있게 구성함으로써 자기주도적 레슨이 가능하도록 했다.




주은교육㈜은 올해 3월 어린음악대의 또 다른 대표 프로그램인 ‘뮤직고고’를 온라인 환경에 접목해 다양한 영상 콘텐츠와 1인 맞춤교구, 워크북 등을 제공함으로써 더욱 풍부한 음악 경험을 할 수 있도록 하는 ‘the all-new뮤직고고’를 론칭하기도 했다.

이와 관련해 주은교육㈜ 홍혜숙 대표는 “팬데믹 여파로 인해 급격하게 변화하는 교육 시장에서 음악학원만 그 변화를 비켜갈 수 없다. 온라인 수업 환경에 적응하는 아이들 눈높이에 맞춰 디지털 콘텐츠로 새롭고 다양한 음악적 경험을 주고, 대면 레슨을 통해 더욱 깊이 있는 수업을 진행한다면 도리어 어린음악대가 추구하는 신개념 음악체험 놀이터라는 개념에 가장 부합하는 교육이 될 수 있을 것이다. 앞으로 에듀테크기업으로서 음악교육시장을 주도해 나갈 수 있도록 노력할 것”이라고 포부를 밝혔다.

한편, 주은교육㈜은 매년 2회에 걸쳐 전국 학원장 워크숍을 개최, 새학기 학원운영 전략과 새로운 교육프로그램 도입 등 교육의 질을 높이기 위한 노력을 기울이고 있다. 코로나로 인해 그동안 온라인으로 대체됐지만 이번에는 백신 2차 접종을 완료한 자에 한해 입장 전 QR체크인, 자가진단키트를 활용하는 등 방역수칙을 철저히 지켜가며 오프라인 워크숍을 진행해 성료했다.`,
            },
            {
                text: "원문 출처 : https://www.sedaily.com/NewsView/2624ZBIY5J",
            },
        ],
    },
    {
        params: "93",
        date: "2021.11.10",
        title: "제 23회 전국 어린음악대 학원장 워크숍 안내",
        thumbnail: "/images/news/littleband/211110-littleband.png",
        type: "소식",
        des: [
            {
                text: `전국 어린음악대 원장님 안녕하세요. 한 해를 마무리 하는 겨울을 맞아 주은교육에서 주최하는 제 23회 전국 어린음악대 워크숍 안내를 드리고자 합니다.
코로나를 이기고 다시 시작하는 마음으로 준비한 워크숍에 어린음악대 가맹원장님들을 초대하여 의미있는 자리를 마련하였습니다.
REBOOT! 새로운 출발을 알리고 다시 시작하는 어린음악대 워크숍에서 만나요!`,
            },
            { image: "/images/news/littleband/211110-littleband.png" },
        ],
    },
    {
        params: "92",
        date: "2021.07.24",
        title: `[에듀동아] 피아노학원 어린음악대, 블렌디드 음악수업 The all new 뮤직고고 ‘러너’ 런칭`,
        thumbnail: "/images/news/littleband/220214-littleband-thumbnail.jpeg",
        type: "뉴스",
        des: [
            {
                image: "/images/news/littleband/220214-littleband-thumbnail.jpeg",
            },
            {
                text: `유아동 전문 음악학원 어린음악대가 블렌디드 음악수업 The all new 뮤직고고 ‘러너’를 오픈한다고 밝혔다. 

뮤직고고는 한국아동예술창의연구소에서 직접 연구, 개발한 체계적이고 전문적인 커리큘럼에 따라 악기연주, 신체활동, 음악이론, 감상&표현 등 음악의 전 영역을 경험하고 표현하는 어린음악대의 시그니처 특강프로그램이다.




The all new 뮤직고고는 기존 뮤직고고에 새로운 영상컨텐츠와 1인교구패키지를 더해 더욱 확장된 사고와 음악적 경험을 심어주는 프로그램으로 지난해 3월 ‘플레이어”를 처음 런칭하여 학부모들에게 큰 호평을 받았다. 다가오는 3월부터는 새롭게 ‘러너’가 업데이트되어 서비스를 시작한다.

 

뮤직고고플레이어가 기초과정이라면 러너는 더욱 다채로운 음악활동으로 다양한 음악 경험과 초등 눈높이에 맞춰 확장된 사고와 음악적 역량을 키워갈 수 있는 심화과정이다. 초등 교과서 수록곡을 삽입해 학교 수업과도 연계해 아이들이 쉽고 재미있게 음악을 배울 수 있다.

 

어린음악대 본사 주은교육㈜ 홍혜숙 대표는 “이제 음악교육시장도 디지털 컨텐츠로의 전환이 필요한 시기에 놓여있다. 다만 음악, 미술과 같은 예술교육의 경우 배우는 환경이나 지도자의 역할이 중요한 만큼 어린음악대는 온라인과 오프라인의 장점을 융합한 블렌디드 수업을 추구하고 있다.”며 “앞으로도 다양한 컨텐츠 개발은 물론 시대에 맞춘 새로운 수업형태의 구상도 계속해 우리 아이들에게 최적화된 음악교육을 제공할 것”이라 강조했다.



한편, 어린음악대 본사 주은교육㈜는 정부 지원 사업중 하나인 ‘음악교육을 위한 UX & 플랫폼 디자인 개발’ 사업의 업체로 선정되어 더욱 풍부한 컨텐츠와 다양한 액티비티가 장착된 피아노레슨프로그램 ‘e피아노고고’를 올 상반기 런칭을 하기 위해 개발에 박차를 가하고 있다.`,
            },
            {
                text: "원문 출처 : http://edu.donga.com/?p=article&ps=view&at_no=20220215102145120260",
            },
        ],
    },
    {
        params: "91",
        date: "2021.07.23",
        title: "아동 맞춤 실용음악 교재 똑똑 재즈피아노친구 출시",
        thumbnail: "/images/news/littleband/210723-littleband.png",
        type: "소식",
        des: [{ image: "/images/news/littleband/210723-littleband.png" }],
    },
    {
        params: "90",
        date: "2020.08.31",
        title: `[서울경제] 커티스음악원 피아니스트 아클리 희석, “한국 유아동 음악교육, 해외에서도 통했다”`,
        thumbnail: "/images/news/littleband/200831-littleband-thumbnail.jpeg",
        type: "뉴스",
        des: [
            {
                image: "/images/news/littleband/200831-littleband-thumbnail.jpeg",
            },
            {
                text: `지난 7월, 영국을 중심으로 활동해온 젊은 피아니스트 ‘엘리아스 아클리 희석’ (Elias Ackerley 희석)군이 그에게 처음 피아노를 가르쳤던 주은교육㈜ 홍혜숙 대표를 만나 감사함을 전했다.

2001년 영국에서 태어난 엘리아스 아클리 희석(이하 아클리 희석)은 이듬해 한국의 대구로 이주해 만 5세에 어린음악대 호산원에서 처음 피아노를 배우기 시작했다. 이후 2012년 구미 전국 피아노 대회 우승을 비롯해 한국의 여러 대회에서 수상하고, 2013년 11세의 나이로 영국 체스터에서 첫 개인 리사이틀로 데뷔한 이래 영국을 중심으로 활동해온 젊은 피아니스트다. 또한 이제 20살이 된 그는 미국 필라델피아 ‘커티스 음악원’에 입학해 무섭게 성장하고 있다.

15년 전 스승인 홍혜숙 대표를 만난 그는 “어린 시절, 마음껏 즐겁게 연주할 수 있는 환경 속에서, 음악이론을 기초부터 탄탄하게 배울 수 있었던 것이 연주활동과 대학입시에 큰 도움이 되었다”며 홍혜숙 대표에게 감사함을 전했다.

이에 홍혜숙 대표는 “키와 손이 너무 작아서 피아노를 잘 배울 수 있을지 걱정했던 5살 꼬마 희석이 멋진 청년 피아니스트로 성장하여 만나게 되니 큰 보람과 기쁨을 느낀다”고 전했다.

아클리 희석이 다니고 있는 ‘커티스 음악원’은 줄리어드 음악원과 함께 미국의 대표적인 음악학교로 미국 대학 가운데 입학 경쟁률이 가장 높은 곳으로 손꼽힌다. 아클리 희석군의 어머니는 “영국에 있을 때, 유명 교수님이 희석의 탄탄한 기초 실력에 놀라며, 한국에서 어떤 레슨을 받았는지 궁금해 할 정도였다”며 “처음 음악을 접하고 배우는 아이에게 한국의 음악교육시스템만큼 좋은 것은 보지 못했다”고 극찬했다.`,
            },
            { image: "/images/news/littleband/200831-littleband-2.jpeg" },
            {
                text: `아클리 희석은 음악을 배우는 어린 친구들에게 “열심히 하는 것도 중요하지만 즐기는 것이 가장 중요하다. ‘어린음악대’에서의 기억도 즐겁게 배웠던 기억이 많다.”며 “지속해 나갈 수 있는 열정과 즐거움을 가지고 평생의 동반자로 음악을 배워갔으면 좋겠다”고 전했다.

뒤이어 아클리 희석군의 어머니 또한 “재정적으로 여유가 있어서 아이를 해외에 보내고 피아노를 가르친 것은 아니다. 다만, 열정이 있다면 어떤 일이라도 가능하다는 것을 믿고, 밀 때는 밀어주고 기다려줄 땐 기다려주며 지속적인 지원 해주는 것이 부모의 역할이라고 생각한다.”며 “다른 부모님들께도 좋은 사례로 기억되길 바란다”고 전했다.

아클리 희석이 다닌 ‘어린음악대 호산원’은 현재 전국 330여개 음악학원프랜차이즈로 성장한 어린음악대의 모태가 된 1호원이다. 최근에는 중국과 캄보디아까지 진출하여 유아동 전문 예술 교육기관으로 성장하고 있다.`,
            },
            {
                text: "원문 출처 : https://www.sedaily.com/NewsView/1Z6SK3YJPR",
            },
        ],
    },
    {
        params: "89",
        date: "2020.08.14",
        title: `[뉴스1] ‘어린음악대, 어린화가들 갈현이화원’ 서울시 방역우수학원 1호 선정`,
        thumbnail: "/images/news/littleband/200814-littleband-thumbnail.jpeg",
        type: "뉴스",
        des: [
            {
                image: "/images/news/littleband/200814-littleband-thumbnail.jpeg",
            },
            {
                text: `프랜차이즈 주은교육㈜의 음악미술학원 브랜드 ‘어린음악대, 어린화가들 갈현이화원’(은평구 소재)이 서울특별시교육청과 한국학원총연합회 서울특별시지회가 선정하는 ‘방역우수학원’ 제1호로 선정되어 8월 11일 조희연 서울시 교육감이 직접 방문하여 인증 스티커를 부착했다고 밝혔다.

조희연 서울시교육감은 학원자율방역지원단과 함께 손소독제, 발열체크, 출입자 명부 등 코로나19 방역 현장을 점검한 후, 직접 아이들의 발열을 체크하고 방역 수칙을 설명했다. 특히, 코로나19 감염을 예방하기 위해 개인위생을 철저히 해줄 것을 아이들의 눈높이에 맞춰 당부했다.

‘어린음악대&어린화가들 갈현이화원’의 조윤주 원장은 ‘서울시 방역우수학원 1호’로 선정된 소감을 묻는 말에 “같은 학부모의 입장에서 아이들의 안전을 걱정하는 부모의 마음을 충분히 이해하기 때문에 엄마의 마음으로 아이들의 안전을 챙겼을 뿐이다. 코로나19 초기 단계에서부터 본사와 소통하며 방역에 힘써 왔다”고 전했다.

프랜차이즈 본사인 주은교육㈜의 홍혜숙 대표는 “코로나19 초기 단계에서부터 본사와 모든 가맹원이 경각심을 가지고 함께 힘을 모아 아이들의 안전을 위해 철저한 방역을 한 결과이며, 앞으로도 방역에 최선을 다해 학부모들이 아이들을 믿고 맡길 수 있는 안전한 학원으로 만들 것”이라는 다짐을 밝혔다.`,
            },
            {
                text: "원문 출처 : https://www.news1.kr/articles/?4026811",
            },
        ],
    },
];
const art = [
    //     {
    //         params: "122",
    //         date: "2024.01.23",
    //         title: "주은교육 기업 정보 보호 컨설팅 사업참여 및 우수사례 선정",
    //         thumbnail: "/images/news/16116480849.jpg",
    //         type: "공지",
    //         des: [
    //             {
    //                 text: `안녕하세요~
    //
    // 오늘은 기업정보보호에 관련해서 이야기하려고 해요.
    // 요즘 코로나로 인해 온라인 컨텐츠나 교육이 보편화 되고 있죠? 이 보편화 속도가 아마 더욱 빨라질 것 같은데요.
    // 이렇게 온라인 교육이나 컨텐츠 시장이 확대될때는 반드시 짚고 넘어가야 하는게 바로 정보의 보관과 관리가 아닐까 생각합니다.
    //             `,
    //             },
    //             {
    //                 image: "/images/news/16116480849.jpg",
    //             },
    //             {
    //                 text: `그래서 주은교육에서는
    // 수준 높은 교육품질과 컨텐츠 정보 보호를 위해 지난 해 6월 ICT중소기업 정보보호
    // 컨설팅 지원 사업에 참여 했는데요.
    // 어린음악대, 어린화가들을 비롯한 기업 내의 정보보호정책 및 지침을 새로 작성하고
    // 정보 자산의 중요도에 따라 분류작업을 실시하면서 더욱 안전하고 체계적인 관리시스템을 마련하게 되었답니다.`,
    //             },
    //             {
    //                 image: "/images/news/16116483458.jpg",
    //             },
    //             {
    //                 text: `이러한 컨설팅 사업 참여 후에는 기업 정보보호 우수사례로 선정되기도 했는데요.
    // 앞으로도 지속적인 교육과 보완을 통해 한 단계 더 발전된 시스템 체계를 구축해 나갈 수 있을 것 같습니다`,
    //             },
    //             { image: "/images/news/16116483878.jpg" },
    //             { line: "md" },
    //             {
    //                 text: `정보보호! 누구나 알고 있는 필수분야이지만 놓치고 가기 쉬운 부분이죠?
    // 우리 어린음악대, 어린화가들 그리고 주은교육은 앞으로도 함께
    // 정보자산에 대한 이해와 관리의 중요성을 인식하고 최선을 다해 이를
    // 지켜나갈 수 있기를 바랍니다.
    //
    // 올해 우리 모두의 슬로건은 3up!
    // level up!
    // jump up!
    // boost up!
    //
    // 정보보호분야에서도 한 단계 더 업그레이드되는 "우리"가 되길 바랍니다!
    //
    // 화이팅!`,
    //             },
    //         ],
    //     },
    {
        params: "123",
        date: "2023.12.05",
        title: `[프라임헤럴드] 경기 광주시 어린음악대어린화가들 양벌원 김혜미 대표, “생각을 더 창의적인 시각으로 변화시키는 전시회”`,
        thumbnail: "/images/news/littleartist/231205-littleartist-thumbnail.png",
        type: "뉴스",
        des: [
            {
                text: `아동기는 성인이 되어서 필요한 다양한 신체적•정신적 발달이 빠르게 이루어지는 시기이다. 이 시기에 어떤 활동을 했는지
에 따라 성인기에 그가 세상을 보는 시각과 인지하는 방법이 달라질 수 있다. 이런 점에서 아동기의 미술 교육은 아동의 표
현력과 상상력, 집중력 향상에 긍정적인 영향을 미치며 정서 발달에도 중요한 역할을 한다.

미술은 아이들이 그림 속에서 무엇을 이야기하고 싶은지, 무엇을 알아주었으면 하는지를 그대로 드러내기 때문에 아이들
과의 진정한 교감을 원한다면 전시를 통해 어느 정도 그 목적을 달성할 수 있을 것이다.

이에 관하여 어린음악대어린화가들 양벌원을 운영하는 김혜미 대표, 강민주 지도자를 만나 이야기를 들어보았다.`,
            },
            {
                image: "/images/news/littleartist/231205-littleartist-thumbnail.png",
            },
            {
                text: `Q. 어린음악대어린화가들 양벌원의 주요 프로그램 분야를 소개해 주십시오.

A. 우리 학원의 누리과정은 마음의 건강과 바른 인성, 조화로운 발달을 추구하고 미술의 기초 능력을 함양하며 창의적으로
표현하는 방법과 미적 감수성을 기르는 것은 목표로 한다. 그뿐만 아니라 4. 5세부터 13세까지, 누리과정에서 초등교육까
지 아우르며 창의 융합 능력을 기르고자 한다.

Q. 어린음악대어린화가들 양벌원만의 특징을 소개해 주십시오.

A. 회화, 작품 감상 등 미술 교육에 놀이 미술을 융합하였다. 어린 화가들은 사회, 과학, 생활, 언어, 수학, 문화 등 다양한 분
야를 경험하고 작품 감상, 작품 재해석, 미디어 활용 방법을 배우게 된다. 이를 통해 사고의 확장과 창의적인 탐구 능력을
키우게 된다. 또한 예술에 예술을 더한 아트플러스 교육을 통해 창의력을 기르고 사고를 확장하는 방법을 배우게 된다.`,
            },
            { image: "/images/news/littleartist/231205-littleartist-2.png" },
            {
                text: `0. 진행 예정인 프로모션이 있다면 소개해 주십시오.

A. 아이들과 함께 공감과 치유의 동화를 만들었다. 아름답고 행복한 결말의 동화를 만든 전시인 <빨강 망토 드림 트립
(Dream Trip)>전이 12월 9일부터 10일까지 진행될 예정이다. 우리 동네 전시장에서 관객들이 동심으로 돌아가 힐링할 기
회가 되었으면 좋겠다.

0. 향후 목표에 대해 말씀해 주십시오.

A. 아이들의 웃음이 가득한 행복한 동화 같은 학원이 되고 싶다. 또한 상상력을 발휘할 수 있는 환경에서 오감을 자극하는
경험과 함께 꿈을 키울 수 있는 학원이 되고 싶다. 정기적으로 동화 전시회를 열면서 스토리텔링 미술학원으로 거듭날 것이
다.

Q. 독자들에게 전할 말

A. 아이들의 눈으로 보는 세상은 아름다움으로 가득하다. 이 아이들은 창의적인 작품을 접하면서 꿈을 키워나가게 될 것이
다. 또한 미술과 음악이 함께하는 공간에서 창의적인 활동을 할 수 있다면 행복한 아이로 성장할 수 있을 것이며 꿈을 향해
나아갈 수 있게 될 것이다.`,
            },
            {
                text: "원문 출처 : http://www.primeherald.co.kr/27440",
            },
        ],
    },
    {
        params: "124",
        date: "2023.05.17",
        title: `[뉴스비타민] 달성군 4년만에 돌아온 헬로아티스트사생미술대회, 가족과 함께 참여하는 미술축제`,
        thumbnail: "/images/news/littleartist/230517-littleartist-thumbnail.jpeg",
        type: "뉴스",
        des: [
            {
                image: "/images/news/littleartist/230517-littleartist-thumbnail.jpeg",
            },
            {
                text: `[대구_뉴스비타민=뉴스비타민기자]지난 5월 13일, 한국아동예술창의연구소에서 강정보디아크광장을 대관하여 코로나로 인해 한동안 잠잠했던 미술대회를 4년 만에 주최 했다. 이는 곧 코로나의 종식을 본격적으로 알리는 신호탄을 의미했다.

행사 시작 시간인 9시 30분 전부터 이미 참가하는 아이들은 부모님과 손을 잡고 자리 잡기에 여념이 없었다. 오랜만에 진행되는 행사인지 아이들은 한껏 들떠 있었고, 부모님은 자녀의 그림 실력을 궁금해 하시며 기대감을 내비치는 미소를 띠었다.

행사가 시작되고 아이들은 저마다 자신의 갈고 닦은 미술 실력을 뽐내며 그림 그리기에 열중했고, 이를 지켜보는 부모님들은 뿌듯함과 대견함이 공존했다. 미술대회이기에 긴장감이 감도는 줄 알았으나, 가족나들이 형식으로 진행되어 즐겁고 편안한 분위기에서 재미있게 진행됐다. 그림을 다 그린 아이들은 본부석에 작품을 제출 후 밝은 미소를 띠며 강정보디아크광장에서 가족들과 즐거운 시간을 보냈다.

수상은 대상, 최우수상, 우수상, 장려상, 입선으로 하여 5월 중 발표 예정이다. 한국아동예술창의연구소에서는“아이들이 자신의 예술성을 표현할 수 있는 문화의장으로 만들고, 하반기에도 진행예정이다.”며“이번 대회를 계기로 유아아동 예술성 향상과 가족이 함께 참여하는 대회로 정기적 진행을 목표로 하고 있다.”고 전했다.

한편, 참가자 가족과 수많은 나들이객으로 인해 어수선하거나 행여 사고가 발생할까 우려되는 시선도 있었으나, 현장 스태프들의 책임감 있는 현장 진행과 시민 분들의 높은 시민의식이 합쳐져 사고 없이 원활하게 행사는 종료됐다.`,
            },
            {
                text: "원문 출처 : http://www.newstamin.com/news/view.php?idx=291970",
            },
        ],
    },
    {
        params: "125",
        date: "2023.01.09",
        title: "제 5회 어린화가들 워크숍 개최 안내",
        thumbnail: "/images/news/littleartist/230109-littleartist.png",
        type: "소식",
        des: [
            {
                image: "/images/news/littleartist/230109-littleartist.png",
            },
            {
                text: `
안녕하세요. 어린화가들입니다.
어느 덧 새로운 한해가 시작되었습니다.
작년 한해를 돌아보면 만족보다 아쉬움이 많지만 또 다시 새로운 한해를 바라보면서 밝은 희망을 가져 봅니다.
2023년을 준비하기 위한 <제5회 전국 어린화가들 학원장 교사 워크숍>이 개최 될 예정입니다.
오랜만에 개최되는 오프라인 워크숍인만큼 원장님뿐만 아니라 담당 교사 분들도 반드시 참여해야 하는 자리이니 <아 래> 일정을 확인하셔서 꼭 참석해주시기 바랍니다.

<아 래>
1. 일정 : 2023년 2월 4일(토) 09:30 ~ 16:00
2. 장소 : 대구 벤처센터 대회의실 2층(대구 동구 동대구로 475 벤처센터빌딩) 
* 동대구역에서 600m(차량 5분, 도보 10분)
3. 대상 : 어린화가들 학원장 & 담당교사, 어린화가들에 관심이 있는 신규 오픈희망 원장
4. 참가비 : 원장 5만원, 교사 4만원 (교사 참가비의 경우 아이뮤즈몰 적립금 사용 가능)
5. 입금계좌 : 농협 301-5587-4449-21 예금주 : 주은교육(주)
6. 신청마감 : 1월 13일(금)까지
            `,
            },
        ],
    },
    // {
    //     params: "126",
    //     date: "2021.01.18",
    //     title: `[MBN뉴스] 주은교육, 지역아동에 감성동화 50박스 기증…"아이들에 휴식처되길"`,
    //     thumbnail: "/images/news/16109588748.jpg",
    //     type: "뉴스",
    //     des: [
    //         {
    //             image: "/images/news/16109588748.jpg",
    //         },
    //         {
    //             text: "원문 출처 : https://www.mbn.co.kr/news/society/4401870",
    //         },
    //     ],
    // },
    {
        params: "127",
        date: "	2020.11.10",
        title: "어린화가들 쏘아베광주원, 마산중앙원 수상을 축하합니다!",
        thumbnail: "/images/news/1419367975.jpg",
        type: "소식",
        des: [
            {
                image: "/images/news/1419367975.jpg",
            },
            {
                text: "축하합니다!!!!!",
            },
        ],
    },
    // {
    //     params: "128",
    //     date: "2020.09.16",
    //     title: "[서울경제]주은교육㈜, 코로나에도 해외 사업 순항… 캄보디아 프놈펜에 2호원까지 오픈",
    //     thumbnail: "/images/news/1742489104.jpg",
    //     type: "뉴스",
    //     des: [
    //         {
    //             image: "/images/news/1742489104.jpg",
    //         },
    //         {
    //             text: "원문보기 : https://www.sedaily.com/NewsView/1Z7VM63EYT",
    //         },
    //     ],
    // },
    // {
    //     params: "129",
    //     date: "2020.08.18",
    //     title: "어린음악대, 어린화가들 갈현이화원’ 서울시 방역우수학원 1호 선정",
    //     thumbnail: "/images/news/ghewa_20200818.png",
    //     type: "뉴스",
    //     des: [
    //         {
    //             image: "/images/news/ghewa_20200818.png",
    //         },
    //         {
    //             text: "원문보기 : https://www.news1.kr/articles/?4026811",
    //         },
    //     ],
    // },
    // {
    //     params: "130",
    //     date: "2020.06.19",
    //     title: "[뉴스1] 주은교육㈜, AR, VR개발업체 리얼위드와 콘텐츠 개발을 위한 MOU",
    //     thumbnail: "/images/news/1523053524.jpg",
    //     type: "뉴스",
    //     des: [
    //         {
    //             image: "/images/news/1523053524.jpg",
    //         },
    //         {
    //             text: "원문보기 : https://www.news1.kr/articles/?3969602",
    //         },
    //     ],
    // },
    {
        params: "131",
        date: "	2019.11.26",
        title: "제5회 헬로아티스트 미술대회 수상자 발표",
        thumbnail: "/images/news/littleartist/191126-littleartist-1.jpg",
        type: "소식",
        des: [
            {
                image: "/images/news/littleartist/191126-littleartist-1.jpg",
            },
            {
                image: "/images/news/littleartist/191126-littleartist-2.jpg",
            },
            {
                image: "/images/news/littleartist/191126-littleartist-3.jpg",
            },
            {
                image: "/images/news/littleartist/191126-littleartist-4.jpg",
            },
            {
                image: "/images/news/littleartist/191126-littleartist-5.jpg",
            },
            {
                image: "/images/news/littleartist/191126-littleartist-6.jpg",
            },
            {
                image: "/images/news/littleartist/191126-littleartist-7.jpg",
            },
        ],
    },
    {
        params: "132",
        date: "	2019.09.16",
        title: "2019 어린화가들 미술축제 개최!",
        thumbnail: "/images/news/littleartist/190916-littleartist-1.jpg",
        type: "소식",
        des: [
            {
                image: "/images/news/littleartist/190916-littleartist-1.jpg",
            },
        ],
    },
    {
        params: "133",
        date: "	2019.09.16",
        title: "제5회 어린화가들 헬로아티스트 미술대회!",
        thumbnail: "/images/news/littleartist/190916-littleartist-2.jpg",
        type: "소식",
        des: [
            {
                image: "/images/news/littleartist/190916-littleartist-2.jpg",
            },
        ],
    },
];
const jueun = [
    {
        params: "400",
        date: "2022.12.28",
        title: "주은교육 '어린음악대', '올해의 우수브랜드대상' 수상",
        thumbnail: "/images/news/jueun_edu/2022-12-28.jpeg",
        type: "뉴스",
        des: [
            {
                image: "/images/news/jueun_edu/2022-12-28.jpeg",
            },
            {
                text: `주은교육(대표 홍혜숙)은 제이와이네트워크가 주최하고 중앙일보가 후원하는 '2022년 올해의 브랜드대상'에서 자사 피아노학원 프랜차이즈 '어린음악대'가 유아아동교육 부문 1위를 수상했다고 28일 밝혔다.

주은교육 측은 "이번 수상은 코로나로 인해 침체된 교육시장에서도 스마트한 교육 트렌드에 맞춰 'e피아노고고'를 개발한 성과"라면서 "'e피아노고고'는 컴퓨터와 결합한 스마트피아노시스템으로, 현재 디지털기기에 익숙한 유아아동이 대상"이라고 말했다. 이어 "'e피아노고고'는 시스템 하나로 1인 스마트 교사, HMS(학원관리프로그램), 출결관리, 학부모 앱(애플리케이션) 등의 기능을 제공한다"면서 "학원 경영자는 물론 아이들에겐 피아노의 재미를, 학부모에겐 만족감을 주는 교육시스템"이라고 덧붙였다.

홍혜숙 주은교육 대표는 "학부모들은 아이들이 즐기고 체험하면서 음악을 배우길 바란다"면서 "예술교육시장이 더욱 커지고 있는 가운데 학부모 니즈에 대응하고자 다양한 변화를 시도하고 있다"고 말했다. 이어 "앞으로 온·오프라인을 융합한 스마트한 예술교육을 통해 에듀테크사업, 방문예술교육사업을 활성화할 것"이라면서 "캄보디아, 베트남 등 동남아 지역에도 한국 예술 교육이 뿌리내릴 수 있도록 앞서 나가겠다"고 덧붙였다.`,
            },
            {
                text: "원문보기 : https://news.mt.co.kr/mtview.php?no=2022122815163328665",
            },
        ],
    },
    {
        params: "401",
        date: "2022.12.27",
        title: "[중앙일보][2022 올해의 우수브랜드 대상 1위] 아이들 위한 음악 전문 학원",
        thumbnail: "/images/news/2024-02-03-12-41-26.png",
        type: "뉴스",
        des: [
            {
                image: "/images/news/2024-02-03-12-41-26.png",
            },
            {
                text: `어린음악대가 ‘2022 올해의 우수브랜드 대상’에서 유아아동음악교육 부문 1위를 수상했다.

음악학원 프랜차이즈 브랜드 어린음악대는 유아아동 음악 전문 학원으로 시대의 흐름에 맞는 스마트한 교육 트렌드를 개발해 미래의 교육 음악시장을 대비하고 있다.
                
어린음악대 본사 주은교육 홍혜숙 대표는 “어린음악대는 무엇보다 아이들이 마음껏 즐기고 체험하고 경험할 수 있는 음악교육을 원하는 것이 학부모들의 니즈이며 이를 성장시킨 브랜드”라며 “예술교육 시장이 더욱 커져가고 있는 지금 그 니즈를 충족시키기 위한 다양한 변화를 시도하고 있다”고 말했다. 이어 “앞으로 스마트한 온라인과 오프라인을 융합한 예술교육을 통해서 에듀테크 사업을 활성화하고 캄보디아·베트남 등 동남아 지역에도 한국 예술교육이 뿌리내릴 수 있도록 추진해 나갈 것”이라고 전했다.`,
            },
            {
                text: "원문보기 : https://www.joongang.co.kr/article/25128947#home",
            },
        ],
    },
    {
        params: "402",
        date: "2022.11.21",
        title: "올해의 우수브랜드 대상1위 (주은교육)",
        thumbnail: "/images/news/jueun_edu/2022-11-21.png",
        type: "소식",
        des: [
            {
                image: "/images/news/jueun_edu/2022-11-21.png",
            },
            {
                text: `국내 최초 최대 용합예술프랜차이즈 음악전문학원 어린음악대 본사 주교육(주)이 2022 종앙일보 주최 대한민국 올해의 우수브랜드 대상을 수상했습니다.
                어린음악대를 믿고 인정해 주신 학부모님들께 진심으로 감사드리며, 앞으로도 아이들의 빛나는 예술적 감성을 위해 최고의 교육과 최선의 노력을 다할 것을 익속 드립니다.`,
            },
        ],
    },
    {
        params: "403",
        date: "2022.12.16",
        title: "[에듀동아]주은교육㈜, 레슨 프로그램 ‘e피아노고고’ 공개해 피아노 음악 학원 에듀테크화 도모",
        thumbnail: "/images/news/jueun_edu/2022-12-16.jpeg",
        type: "뉴스",
        des: [
            {
                image: "/images/news/jueun_edu/2022-12-16.jpeg",
            },
            {
                text: `유아동 전문 음악 미술 학원 에듀테크 기업 ‘주은교육㈜’이 신규 블렌디드 피아노프로그램 ‘e피아노고고’ 데모 버전을 공개해 눈길을 끈다.

정부 지원 사업 중 하나인 ‘음악 교육을 위한 UX & 플랫폼 디자인 개발 사업’ 업체로 선정된 주은교육㈜은 지난 12월 11일 제23회 전국 어린음악대 학원장 워크숍에서 사업의 진행과 계획을 공유하고 신규 레슨 프로그램 데모 버전을 공개했다.
                
‘e피아노고고’는 리듬과 테크닉, 그리고 음감과 음악이론까지 통합적으로 레슨하는 뮤직 플로우(FLOW) 통합 레슨 프로그램 ‘피아노고고’를 디지털 콘텐츠로 전환해, 온라인과 오프라인 레슨의 장점을 결합한 새로운 형태의 블렌디드 레슨 프로그램이다.
                 
금번 공개된 e피아노고고 데모 버전은 아이들이 쉽고 재미있게 레슨을 경험하고 음악 이론을 자연스럽게 받아들일 수 있도록 스토리텔링과 함께 다양한 액티비티와 콘텐츠를 짜임새 있게 구성함으로써 자기주도적 레슨이 가능하도록 한 것이 특징이다.

특히 어린음악대 아이덴티티를 잘 반영한 음표 캐릭터와 함께 우주여행을 하며 주어지는 미션을 클리어하듯 레슨을 즐길 수 있다.
                
주은교육㈜ 홍혜숙 대표는 “장기화되는 팬데믹 여파로 인해 빠르게 변화하는 교육 시장에서 음악학원만 그 변화를 비켜갈 수 없다고 생각한다. 온라인 수업 환경에 적응하는 아이들 눈높이에 적합한 디지털 콘텐츠로 새롭고 다양한 음악적 경험을 주고, 대면 레슨을 통해 더욱 깊이 있는 수업을 진행한다면 도리어 어린음악대가 추구하는 신개념 음악체험 놀이터라는 개념에 가장 부합하는 교육이 될 수 있을 것이다. 추후에도 에듀테크기업으로서 음악교육시장을 주도해 나갈 수 있도록 노력할 것”이라고 말했다.
                
한편 주은교육㈜은 올해 3월 어린음악대의 또 다른 대표 프로그램인 ‘뮤직고고’를 온라인 환경에 접목해 다양한 영상 콘텐츠와 1인 맞춤교구, 워크북 등을 제공함으로써 더욱 풍부한 음악 경험을 할 수 있도록 하는 ‘the all-new뮤직고고’를 론칭하기도 했다. 

`,
            },
            {
                text: "원문보기 : http://m.edu.donga.com/news/view.php?at_no=20211216152438272977",
            },
        ],
    },
    {
        params: "404",
        date: "2021.10.14",
        title: "[음악교육신문]커버스토리 어린음악대 홍혜숙 소장",
        thumbnail: "/images/news/jueun_edu/2021-10-14.png",
        type: "뉴스",
        des: [
            {
                image: "/images/news/jueun_edu/2021-10-14.png",
            },
            {
                image: "/images/news/jueun_edu/2021-10-14(2).png",
            },
            {
                image: "/images/news/jueun_edu/2021-10-14(3).png",
            },
            {
                text: `국내 최초이자 최대 음악학원 프랜차이즈로서 국내 음악교육의 선구자 역할을 톡톡히 해내며 꾸준한 사랑을 받고 있는 주은교육(주)의 어린음악대. 올해로 10주년을 맞이한 이들이 지난 1월, [2018 소비자가 뽑은 가장 신뢰하는 브랜드 대상] 음악학원 프랜차이즈 부문 최초로 선정되었다는 기쁜 소식을 전해왔다. 다가 오는 봄의 꽃만큼 활짝 만개할 이들의 행보와 성공신화를 듣기 위해 대구에 위치한 본사를 방문해 홍혜숙 소장을 만나보았다.

2018 소비자가 뽑은 가장 신뢰하는 브랜드 대상 수상
                
“올해 회사법인을 설립한지 10주년을 맞아 수상하게 되어 더욱 뜻 깊습니다. 음악전문회 사가 한결같이 10년을 걸어오기란 결코 쉽지 않았는데요. 임직원들과 가맹원 원장님들, 학부모님들, 학생들이 주은교육(주)에 보내주신 성원과 신뢰가 있었기에 가능했다고 생각합니다. 앞으로도 그 믿음에 보답할 수 있도록 즐겁고 알찬 음악체험교육을 위해 끊임없이 연구하고 노력하겠습니다.” 보통 피아노 교육프로그램을 사용하는 학원들을 프랜차이즈라 생각하고 있는 경우가 많은데, 이는 현판을 사용하는 것이지 진정한 프랜차이즈라 보기 어렵다. 실상 국내 최초로 예능교육의 프랜차이즈를 실현시킨 것은 ‘주은교육’이다. 동일한 간판과 디자인, 실내 인테리어, 교재, 교구, 교육법, 홍보방법으로 전국 어린음악대 270여개, 어린화가들 50여개의 가맹점을 두며 국내 최대 규모를 자랑하고 있다.
                
‘어린음악대’의 시작
                
음악교육 대학원 석사 학위까지 취득한 홍혜숙 소장은 ‘어린음악대’의 모태가 된 학원을 운영하며 아이들과 학부모들의 니즈를 정확히 파악했다. 7세 이전부터 자녀들의 피아노 교육을 원하는 학부모들의 문의와 요청이 거듭됨에 따라 어린 아이들도 원생으로 받기로 결심한 홍소장은, 지도하는 교사들의 부담과 힘을 덜어 주고 아이들의 이해도와 흥미를 높이기 위한 방안을 마련하기 시작했다.
                
“전문적인 음악교구라는 것이 제대로 형성되지 않았던 때라 처음에는 간단하게 재료를 사서 교구를 제작하게 되었어요. 막상 만들다 보니 15종류나 되었더라고요.(웃음) 교사와 학생들 모두 교구를 통한 수업에 만족도가 상당히 높았는데, 학생들의 손을 거치다보니 부러지고 상하는 등 교구의 수명이 길지 않았죠. 그래서 오랜 시간 동안 교구를 활용할 수 있도록 하고 자 주말마다 서울 충무로 인쇄골목과 방산시장에 방문해 직접 발로 뛰어다니며 재료와 인쇄기술에 대한 정보를 모았습니다. 그 결과 이전 문제점들을 보완한 튼튼하고 안전한 교구를 완성하게 되었고, 제작한 교구는 특허까지 받게 되었습니다.”
                
특허 받은 교구를 활용한 교육으로 초등학생은 물론, 유아, 유치원생들의 피아노 교육까지 섭렵한 홍혜숙 소장의 행보는 금세 입소문을 타기 시작했다. 전국에서 그녀의 노하우에 관심과 궁금증을 갖고 문을 두드리는 학원장들이 늘어남에 따라 자신의 교육법과 경영철학, 상담법 등을 아낌없이 전수했고 이후 2011년, 정식 프랜차이즈로서 ‘어린음악대’의 첫 발을 내딛었다.
`,
            },
            {
                image: "/images/news/jueun_edu/2021-10-14(4).png",
            },
            {
                text: `국내 순수 토종 주은교육(주)의 체계적인 예능융합교육

                1) 어린음악대: 어린음악대 독점 교재 및 특허 받은 교구를 활용하여 좌뇌와 우뇌를 균형 있게 종합 발달시키는 국내 최초 두뇌밸런스 음악교육 프랜차이즈입니다. ‘뮤직 고고’, ‘피아노 고고’를 통해 보다 신개념 음악체험 감성놀이터인 ‘음률체험교실’에서 체계적인 음악교육이 이뤄지며, 수업은 개인별 1:1 담임제로 진행 되며 3회 레슨 시스템으로 이뤄지고 있습니다.
                
                2) 뮤직고고: 유·아동에게 최적화된 음악적 환경 속에서 악기연주, 신체활동, 음악이론, 감상& 표현을 포함한 음악교육 전 영역 음악체험 통합 놀이 교육 프로그램입니다. 일반음악학원에서 진행하고 있는 특강 및 악기는 초등학생 위주인 것에 반해 뮤직고고는 4세부터 다양하게 음악의 영역을 접할 수 있도록 하고자 소리 탐색부터 시작악기 연주로 표현력과 상상력 향상을 이끌어내며 차별화 된 교육을 시행하고 있습니다. 특히 신체활동은 음악을 틀어놓고 음악이론에 대한 내용을 바탕으로 교구들과 함께 마음껏 몸으로 즐길 수 있는 게임 활동으로 진행하다 보니 아이들의 흥미지수가 굉장히 높습니다.
                
                3) 피아노 고고: 어린음악대의 독창적인 독점 음악교구를 활용한 자기주도적 몰입(FLOW) 과정을 통해 두뇌를 열어주는 통합형 레슨 프로그램입니다. 피아노를 연습하고, 리듬악기를 통해 리듬과 박자의 개념과 감을 익히고, 교구를 통해 이론을 쉽게 접하고 마지막으로 스마트e피아노로 음악동화, 노래, 플레이고 고, 레벨테스트 등 다양한 콘텐츠를 활용하며 평가하는 총 4단계의 체계적인 프로그램입니다. 단순히 피아노 앞에 앉아 스스로의 의지로 연습해야하는 어려움에서 벗어나 탄탄한 프로그램을 바탕으로 진행 되다 보니 선생님들도 계획적으로 수업할 수 있고, 아이들도 피아노를 더 이상 지루하고 힘든 악기로 생각하지 않습니다.
                
                4) 어린 화가들: 융합교육의 중요성이 대두됨에 따라 한국아동예술창의연구소의 연구진은 컬러풀 통합미술 감성놀이터인 ‘어린화가들’을 선보이게 되었습니다. 음악학원과 미술학원을 각각 따로 보내야하는 불편함을 해소하고, 세분화된 맞춤형 수업과 교과과정 연계로 선행 학습을 유도하여 유치원, 학교에서 미술과목에 대한 자신감을 상승시켜주고 있습니다.
                
                5) 아트플러스: 영상자료를 활용한 특강 수업으로 주제에 맞는 영상자료로 청각, 시각, 공감 각을 확장하는 헬로미디어아트, 다양한 미술 작품을 영상을 통해 관찰하고 미술의 시대적 배경 등을 살펴보는 헬로큐레이터, 다채로운 미술재료에 창의적 상상력을 더해 입체적으로 표현하는 헬로창의조형, 이렇게 3가지로 구분 되어 진행합니다.
                
                이렇게 주은교육은 국내 순수 토종교육프로 그램으로 무엇보다 현재 한국학생들의 문화에 맞추어 그들의 니즈와 교육환경을 그대로 반영한 교육을 행하고 있다. 더불어 달라지는 교육정책에 맞추어 계속해서 새로운 프로그램을 개발하기 위해 노력하고 있다.
`,
            },
            {
                image: "/images/news/jueun_edu/2021-10-14(5).png",
            },
            {
                text: `트렌드에 발맞춘 스마트한 교육, 스마트한 관리!

                “영어만 하더라도 현 시대에 맞게 핸드폰이나 스마트 기계를 통한 교육이 자연스럽게 이뤄지고 있습니다. 더군다나 요즘 아이들이 자라는 환경을 살펴보면 관심사와 흥미를 느끼는 부분이 기계와 콘텐츠 등과 같은 요소들이기에 음악 교육과 모바일 콘텐츠의 접목은 불가피하지요.
                
                이러한 시대적 흐름에 맞춰 주은교육에서는 ‘스 마트e피아노’와 ‘국내 최초 피아노 레슨프로그램 연동 모바일 앱’을 개발하였습니다. 이를 위해 많은 투자와 지속적인 연구를 해왔는데요.
                
                특히 앱 서비스 같은 경우, 학부모님들의 반응이 상당히 뜨겁습니다. 출결관리부터 수업장면, 레슨비 결제까지 트렌드에 맞게 개발하는데 애를 먹었지만 좀 더 간편하고 편리해져 학부모님과 학원장님들의 만족도가 아주 높습니다.”
                `,
            },
            {
                image: "/images/news/jueun_edu/2021-10-14(6).png",
            },
            {
                text: `  홍혜숙 소장만의 특별한 경영방법과 담임제 시스템
                
                “많은 원장님들의 관심은 어떤 교재로, 또는 어떤 프로그램으로 학생들을 교육하는지, 티칭에 대한 관심은 굉장히 높으신 반면 경영은 뒤처지기 일쑤인데요. 어린음악대가 10년 동안 프랜차이즈로써 자리 잡고 꾸준히 영역을 넓혀갈 수 있었던 중요한 요소는 ‘경영’이에요. 각 지점 마다 동일한 간판과 인테리어, 교육법, 프로그램, 특강 등을 진행하는 것과 더불어 원장님들께 경영 컨설팅 지원, 학원 운영법, 환경조성, 서비스 상담 교육을 철저하게 하고 있어요. 처음 에는 세세한 부분까지 관여한다고 생각하시는 분들이 많지만, 그대로 이행했을 때 오는 변화들을 보고 느끼시며 인정해주세요. 학생들과 학부모님들은 작고, 섬세한 부분에서부터 만족도의 차이를 느끼시거든요.”
                
                더불어 홍혜숙 소장은 현장감을 잃지 않기 위해 어린음악대의 모태가 되는 학원과 2호, 3 호까지 3개의 학원을 여전히 운영하고 있다.
                
                주은교육의 대표로서 눈코 뜰 새 없이 바쁜 일정을 소화하고 있지만 그럼에도 학원을 원활하게 운영할 수 있는 이유는 바로 주은교육의 ‘시스템’이다.
                
                “학원을 방문해 보면 가장 바쁜 사람은 원장님이에요. 하지만 원장님은 경영자로서 학원을 위해 충분히 생각할 시간이 필요한 사람이라고 생각해요. 학원의 전체적인 일정과 계획부터 연주회, 홍보, 원생들과 교사들 관리까지 전체적으로 아우르는 넓은 시야와 고민할 시간이 필요하죠. 그래서 원장님이 없어도 학원이 운영될 수 있는 철저한 담임제 시스템을 만들어 적용했어요. 유치원처럼 반별로 방을 두고, 그 안에 레슨이 포함돼있는 구조인거죠. 그래서 학부모님들은 원장님이 아닌 직접 지도하는 담임선생님과 상담하실 수 있고, 아이들도 교육하는 선생님이 고정적이다 보니 안정감을 느끼고 있어요. 대신 원장님들은 학원이 마치는 시간에 교사들과 회의를 통해 의견을 주고받고, 문제점에 대한 피드백을 나누며 교사들과의 소통을 이어갑니다.”
                
                이로써 각 지점마다 차별화된 경영방식, 그리고 독특한 학원 내부 구조와 시스템으로 학원 장과 교사, 학생, 학부모 모두가 만족을 느끼는 이상적인 모습을 연출하니 자연스레 어린음악 대는 성행할 수밖에 없다.
                
                주은교육의 앞으로의 행보가 기대되는 이유
                
                “지속적으로 시행하고 있는 학부모 설명회를 더욱 활성화 시켜 많은 분들께 예능교육의 중요성과 주은교육의 독자적인 교육법을 알릴 계획이며, 여기에 멈추지 않고 국내 최대 규모의 예능융합교육센터로 거듭날 수 있도록 저희의 발걸음을 멈추지 않을 것입니다. 또한 학원경영에 관련된 세분화된 과목들을 개설하여 원장님들이 전문적으로 이수할 수 있는 방법을 고안하고 있습니다.”
                
                홍혜숙 소장은 학부모와 원생, 학원장, 그리고 교사 또한 고객이라고 말한다. 그녀의 머릿속은 늘 고객들을 어떻게 만족시킬까에 대한 고민들로 가득 차있다. 그래서 좀 더 좋은 프로그램으로, 좀 더 좋은 교구로, 좀 더 좋은 환경과 시스템으로 모두를 만족시키기 위해, 생각하고 실행한다. 그녀의 바람처럼 전국의 어린 음악대가 아이들의 음악놀이터로 자리 잡으며 음악과 함께 마음껏 뛰어 놀 수 있는 아름다운 예술교육현장이 되기를 소망해본다.`,
            },
            {
                text: "원문보기 : http://www.musiced.co.kr/bbs/board.php?tbl=cover_story&mode=VIEW&category=&chr=&num=147",
            },
        ],
    },
    {
        params: "405",
        date: "2021.01.05",
        title: "[서울경제] 주은교육㈜, 지역아동들을 위한 어린이 음악 미술 감성동화 50박스 기증",
        thumbnail: "/images/news/jueun_edu/2021-01-05.jpeg",
        type: "뉴스",
        des: [
            {
                image: "/images/news/jueun_edu/2021-01-05.jpeg",
            },
            {
                text: `음악 미술 학원 프랜차이즈 ‘어린음악대 어린화가들’ 본사 ‘주은교육㈜’은 지난 1월 12일 달성복지재단을 통해 지역아동들을 위한 감성동화 50박스(1,070만원 상당)를 기증했다.

주은교육이 기증한 감성동화는 예술적 감성과 다양한 배경지식을 함께 쌓아갈 수 있도록 흥미로운 이야기들로 풀어낸 음악 미술 동화책이다. 유치원 누리 교육과정, 초등 교육과정을 반영해 유치원생 혹은 초등학생 아이들이 꼭 알아야 할 이야기를 중심으로 선별한 동화책 세트다.
                
감성동화 전집은 어린음악대 어린화가들 음악 미술학원에서도 수업과 연계해 사용하고 있다. 명화와 음악을 통해 감성을 깨우고 논리력과 창의력, 사고력을 확장하는 데 많은 도움을 주며 실제 학부모들 사이에서 만족도가 높은 책이기도 하다.
                
주은교육㈜ 홍혜숙 대표는 “지난 2020년부터 이어진 현 코로나바이러스 감염증(코로나 19) 여파로 인해 어려움을 겪고 있는 이웃들이 많다. 이러한 상황 속에서 자라나는 우리 아이들을 위한 도움을 작게나마 실천할 수 있어 기쁘다. 감성동화가 현 시점에서 많이 지친 아이들에게 좋은 휴식처가 될 수 있기를 바란다”고 전했다.
                
이에 대해 달성복지재단 차준용 이사장은 “책 속에 길이 있다는 말이 있듯이 아동들이 도서를 활용해 다양한 정보를 얻고, 상상력을 마음껏 펼칠 수 있는 기회를 마련해주셔서 감사하다”고 전했다.

한편 주은교육㈜은 오랜 시간 유아-아동 음악 미술 전문 교육기업으로서 아이들의 올바른 감성발달과 예술교육 발전을 위해 다방면으로 꾸준한 노력을 이어오고 있다. 전국 330여개 가맹원이 있는 음악 미술학원 프랜차이즈 어린음악대 어린화가들 본사이기도 한 주은교육은 중국, 캄보디아 등지에서도 사업을 확대하고 있다.

또한 현 코로나 여파 속에도 다양한 디지털 콘텐츠 개발과 학원운영 시스템 개발에 힘을 쏟으며 ‘아날로그와 디지털 예술융합교육’이라는 새로운 교육포맷을 제시하고 있다. 오프라인과 온라인의 강점을 하나로 묶어 유아-아동 음악 미술 교육의 전문성을 높이는 등 새로운 교육환경을 대비한 다양한 사업전략을 시행하고 있다.

끝으로 홍혜숙 대표는 “앞으로도 자사는 자라나는 우리 아이들이 즐겁게 음악 미술을 만나고 배워서 다양한 색채를 가진 사람으로 자라날 수 있는 예술교육을 위해, 또 그러한 예술교육이 사회 전반에 걸쳐 선한 영향력을 펼칠 수 있도록 노력하겠다”고 포부를 밝혔다.
`,
            },
            {
                text: "원문보기 : https://www.sedaily.com/NewsView/22H9LAYV47",
            },
        ],
    },
    {
        params: "406",
        date: "2020.09.16",
        title: "[서울경제]주은교육㈜, 코로나에도 해외 사업 순항… 캄보디아 프놈펜에 2호원까지 오픈",
        thumbnail: "/images/news/jueun_edu/2020-09-16.jpeg",
        type: "뉴스",
        des: [
            {
                image: "/images/news/jueun_edu/2020-09-16.jpeg",
            },
            {
                text: `유아동 전문 음악미술학원 프랜차이즈 기업 주은교육㈜의 어린음악대, 어린화가들이 코로나 위기 상황 속에서도 지난 5월 캄보디아 수도 프놈펜 '센속구'에 제1호원을 오픈한 것에 이어, 3개월여만에 프놈펜 센속구 '칩몽파크랜드'에 제 2호원을 오픈함으로써 순조롭게 해외사업을 추진해 나가고 있다.

                캄보디아 수도 프놈펜 센속은 여러 국제학교가 들어서 있고 캄보디아의 코리아타운 ‘캄코시티’가 위치해 있어 빠른 성장 가능성을 보여주고 있는 곳이다. 이 곳에 먼저 자리 잡은 어린음악대, 어린화가들의 제 1호원인 ‘센속원’은 지난 5월 개원 후 입소문을 타면서 먼 거리임에도 불구하고 직접 체험 수업을 경험하기 위해 등록하려는 학부모들이 많았다.
                
                이러한 입소문에 힘입어 어린음악대, 어린화가들은 지난 8월 프놈펜 내 유동인구가 가장 많은 칩몽파크랜드에 제2호원을 계약하고 오픈 준비에 들어갔다. 칩몽파크랜드는 제1호원 센속원보다 교통이나 지리적 요건이 뛰어나며, 프놈펜 서부지역의 개발 가속화로 인해 앞으로 큰 규모의 중산층이 입주할 것으로 예상되어 성장잠재력이 클 것으로 예상되고 있다.
                `,
            },
            {
                image: "/images/news/jueun_edu/2020-09-16(2).jpeg",
            },
            {
                text: `주은교육㈜ 홍혜숙 대표는 “캄보디아의 교육시장은 앞으로 가능성이 무궁무진하다. 한국 시장에서의 성공을 발판으로 해외에서도 충분히 그 역량을 발휘 할 수 있을 것이라 확신한다.”며 “k뷰티, k팝, k드라마를 넘어 한국 음악, 미술 교육이 세계적인 경쟁력을 갖춰나갈 수 있도록 계속해서 노력하겠다”고 전했다.

유아동 전문 음악미술학원 어린음악대, 어린화가들은 이미 국내에서는 전국 330여개 가맹원이 운영 중에 있는 대형 프랜차이즈다. 주은교육㈜은 2018년부터 해외시장 개척을 추진했다. 2019년, 중국 유명 교육기업인 엉클샘교육그룹과 손을 맞잡았고, 2020년에는 캄보디아에 첫 가맹원을 오픈하는 등 해외사업의 역량을 키워가고 있다. 또한 주은교육㈜은 코로나라는 전세계적인 위기상황 이후 전개될 음악미술학원 시장의 상황에 대해 주도면밀하게 주의를 기울이고 있으며, 예체능 비대면 교육 시스템 및 디지털컨텐츠 개발에 박차를 가하고 있다.
            `,
            },
            {
                text: "원문보기 : https://www.sedaily.com/NewsView/1Z7VM63EYT",
            },
        ],
    },
    {
        params: "407",
        date: "2020.06.18",
        title: "[뉴스1] 주은교육㈜, AR, VR개발업체 리얼위드와 콘텐츠 개발을 위한 MOU",
        thumbnail: "/images/news/jueun_edu/2020-06-18.jpeg",
        type: "뉴스",
        des: [
            {
                image: "/images/news/jueun_edu/2020-06-18.jpeg",
            },
            {
                text: `음악, 미술학원 프랜차이즈 업체인 주은교육㈜이 AR, VR개발업체인 ㈜리얼위드와 혼합현실 기반의 음악, 미술 교구재 개발을 위한 전략적 MOU를 체결한다고 밝혔다. 
  
주은교육㈜은 이번 협약식을 통해 그동안 개발해온 음악, 미술 교구와 다양한 교육용 콘텐츠를 AR, VR기술로 재탄생시켜 유통할 계획이라고 전했다. 특히 ㈜리얼위드에서 개발한 마인드 컬러링 XR의 경우 어린 화가들의 병행교재로 채택하기 위해 “아이들의 색채심리분석을 위한 샘플링 작업에 들어간다”고 주은교육 측 관계자는 밝혔다.
                  
이번 샘플링 작업에서 적합성이 입증되면 마인드컬러링을 통해 분석된 데이터를 학부모들에게 직접 제공함으로써 각 가정과의 소통도구로 활용할 계획이다. 또한 주은교육 측은 각 가맹원의 신체활동놀이공간인 '음률체험교실'에 리얼위드의 센서기반 솔루션인 'RARA 어드벤처'를 설치할 경우 놀이요소가 가미된 학습 프로그램을 통해 아이들의 호기심과 즐거움을 배가시키고 학습능률향상을 높여 줄 것으로 기대하고 있다.
                
홍혜숙 주은교육 대표는 “리얼위드와의 금번 업무협약식을 통해 주은교육의 특허받은 수많은 교구재가 새롭게 재탄생해 기존 가맹원들과 신규가맹원들에게 새로운 활력을 불어넣어 줄 것”이라고 MOU에 따른 소감을 전했다.
`,
            },
            {
                image: "/images/news/jueun_edu/2020-06-18(2).jpeg",
            },
            {
                text: "원문보기 : https://www.news1.kr/articles/?3969602",
            },
        ],
    },
    {
        params: "408",
        date: "2020.06.17",
        title: "2021 한중디자이너 초대전 또리소리 캐릭터인형 패키지 작품 출품!",
        thumbnail: "/images/news/jueun_edu/2020-06-17.png",
        type: "소식",
        des: [
            {
                image: "/images/news/jueun_edu/2020-06-17.png",
            },
            {
                text: `안녕하세요~
어린음악대엔 또 좋은소식이 가득가득!
한국과 중국 디자인계의 오랜 우정과 국제적 문화교류를 위한 2021 한중디자이너초대전에 또리소리 인형 패키지 작품을 출품하게 되었습니다! 앞으로도 또리소리의 멋진 모습을 많이 많이 기대해주세요!
어린음악대는 언제나 한단계 더 앞서나가는 교육서비스와 컨텐츠 품질을 여러분께 선보이겠습니다.
감사합니다.`,
            },
        ],
    },
    {
        params: "409",
        date: "2020.06.15",
        title: "주은교육(주)주은교육(주) AR,VR 개발업체 리얼위드와 업무협약 체결",
        thumbnail: "/images/news/jueun_edu/2020-06-15.png",
        type: "소식",
        des: [
            {
                text: `안녕하십니까.
지난 6월 15일 월요일,주은교육은 AR.VR 개발업체 리얼위드와 콘텐츠 개발과 관련하여 MOU를 맺고 다양한 컨텐츠 개발과 시스템도입을 결정했습니다.
`,
            },
            {
                image: "/images/news/jueun_edu/2020-06-15.png",
            },
            {
                text: `
                리얼위드는 확장현실(XR+AR+VR)+AI 기술을 바탕으로 한교육 및 게임 콘텐츠 개발사로서 SK Telecom 5G True innovation 유망 벤처기업으로 선정된 기업인데요. 주은교육은 리얼위드에서 개발한 마인드컬러링(아동 색채심리분석), RARA어드벤처(리얼위드 활동 현실 지역 어드벤처 게임) 등을 어린음악대, 어린화가들에 도입해 우리 아이들에게 더욱 수준 높은 교육환경을 제공하고 가맹원 원장님들의 교육현장에 더욱 활력을 불어 넣어드리기 위해 다양한 컨텐츠 개발을 하기로 했습니다.
가맹원 원장님들도, 학부모님들도 함께 성장하고 있는 음악교육시장의 여러 관계자분들도 기대하실만한 성과를 낼 수 있도록 주은교육이 크게 앞장서도록 노력하겠습니다.
많은 기대와 응원 그리고 도움을 부탁드립니다. 감사합니다.
감사합니다.`,
            },
        ],
    },
    {
        params: "410",
        date: "2020.06.04",
        title: "[머니투데이]주은교육, 예술·경영 임원진 개편..신사업 강화",
        thumbnail: "/images/news/jueun_edu/2020-06-04.png",
        type: "뉴스",
        des: [
            {
                image: "/images/news/jueun_edu/2020-06-04.png",
            },
            {
                text: `융합 예술 창의 교육 프랜차이즈 주은교육(대표 홍혜숙, 안중현)이 각 분야 전문가를 임원진으로 전격 영입, 신규 사업 및 해외 사업 확대에 나선다고 4일 밝혔다.

                주은교육은 올해로 창립 12주년을 맞은 예술 교육 전문 업체다. 전국에 330여개의 가맹원을 두고 음악 및 미술을 융합, 교육을 펼치고 있다. 이번에 조직 개편과 함께 임원진을 새롭게 배치했다. 이를 통해 사업 전반적으로 모멘텀을 확보하고, 외부 자본도 유치하겠다는 방침이다.
                
                회사는 이번 조직 개편으로 4인 체제의 임원진을 결성했다. 홍혜숙 주은교육 대표와 배기만 전무, 김덕주 상무, 서정하 이사 등이 임원진이다. 이 가운데 새로 영입한 임원은 김 상무와 서 이사다.
                
                김 상무는 SK글로벌(현 SK네트웍스) 전략기획실과 메가스터디 신사업 개발 실장 등을 거쳤다. 서 이사는 넥슨코리아 영상·사운드 총괄 프로듀서와 뮤직에듀벤처(장원교육 계열사) 사업 총괄 등을 역임했다. 홍 대표는 음악 교육 브랜드 '어린음악대'를, 배기만 전무는 프랜차이즈 기틀을 만들었다는 평을 받고 있다. 이번 4인 체제로 국내외 사업을 확장하고 브랜드 경영 전략에 힘쓰기로 했다.
                
                아울러 주은교육은 최근 서울시 송파구에 새로운 사무실을 오픈하고 해외TF(태스크포스)도 구성했다. 앞으로 딥러닝 및 온라인 콘텐츠와 게이미피케이션(놀이적 요소) 연구 등에 투자를 확대키로 했다. 수도권 중심의 가맹원 확보에도 속도를 낼 방침이다.
                
                홍혜숙 주은교육 대표는 "미래에 펼쳐질 교육은 양질의 콘텐츠와 재미 요소가 중요해질 것"이라면서 "어느 누구나 쉽게 접근하고 자연스럽게 배우는 스마트 환경이 형성될 것"이라고 했다. 이어 "'아이들이 즐거워야 진짜 교육'이라는 모토를 중심으로 선진형 창의 육합 센터를 확장해 가겠다"고 덧붙였다.
감사합니다.`,
            },
            {
                text: "원문보기 : https://news.mt.co.kr/mtview.php?no=2020060416133852296",
            },
        ],
    },
    {
        params: "411",
        date: "2019.07.31",
        title: "[데일리그리드]음악미술학원 프랜차이즈 어린음악대 어린화가들 중국 시장에서 첫 인사",
        thumbnail: "/images/news/jueun_edu/2020-07-31.png",
        type: "뉴스",
        des: [
            {
                image: "/images/news/jueun_edu/2020-07-31.png",
            },
            {
                text: `지난 26일(금) 베이징에서 국제소양교육포럼과 중국 엉클샘교육그룹의 창립 10주년 기념행사가 개최됐다. 

이 날 행사에는 중국 아시아 경제 개발협회 주관으로 영국, 미국, 호주, 일본, 한국 등 많은 해외 교육업계 인사들이 대거 참석하며 성대하게 치러졌고, 미래를 앞서 나가는 교육 핵심사업의 프로젝트에 어린음악대·어린화가들이 소개됐다.
                
중국 엉클샘교육그룹은 그동안 중국의 유·아동교육시장, 특히 영어분야에 큰 성장을 이뤘다. 중국 내 200개 도시에 800여개의 캠퍼스를 두고 있으며, 영어 뿐만 아니라 엉클샘 유치원, 국제아동발달센터 등 유·아동 교육사업에 큰 영향력을 가진 기업이다. 
                
엉클샘교육그룹은 창립 10주년을 맞아 ‘새로운 교육생태계 조성 프로젝트’로 세계의 유명 교육 프랜차이즈와 손을 잡고 만 0세부터 18세의 전 연령에 전방위적 맞춤 교육을 제공한다고 밝혔다. 
                
그 중에서도 어린음악대·어린화가들은 중국 내 한국 교육서비스 및 문화컨텐츠에 대한 높은 지지를 바탕으로 청소년 소양교육의 핵심사업으로 발표되었다. 특히 중국에서 체계적인 소양교육사업이 제대로 시행되는 것은 이번이 처음으로 행사에 참석한 영국, 일본 등의 해외의 유수 교육기업에서도 큰 관심을 받았다.   
                
약 500여명이 참석한 이번 행사에는 주은교육(주) 홍혜숙 대표와 임원진이 참석하여 엉클샘교육 그룹의 창립 10주년을 축하하고 주은베이징의 첫 투자설명회를 가졌다. 
`,
            },
            {
                image: "/images/news/jueun_edu/2020-07-31(2).png",
            },
            {
                text: `
홍혜숙 대표는 어린음악대·어린화가들의 교육철학과 한국에서의 성공사례 등을 자세하게 설명하며, 중국에서의 어린음악대·어린화가들의 성공 가능성을 피력했다. “단순히 지식을 주입하는 교육은 의미가 없다. 무엇보다 아이들의 눈높이에서 예술을 충분히 경험하고 즐기며 배울 수 있는 차별화된 교육환경이 중요하다.”며 “많은 시행착오를 겪으며 발전한 한국에서의 성공모델이 중국에서도 반드시 통할 것이라 확신한다. 아이가 즐기며 배우는 예술교육의 효과는 주입식 교육효과와는 교육의 질적인 측면에서 확연히 다를 것이다.”고 강조했다. 

한편 이 날 오후에는 국제소양교육포럼이 개최되었다. 다양한 영역의 학문과 각 분야의 전문가들이 함께 모여 청소년 소양교육자원 협력, 교육생태계 발전, 미래 교육의 흐름 등을 주제로 심도 있게 토론했다. 
                
국제 소양교육포럼에 참석한 주은교육(주) 김덕주 경영전략본부장은 한국 예술 교육시장 발전과정과 현 시장의 변화에 대해 이야기하며, 한국 뿐만 아니라 중국 예술교육의 미래에 중요시 될 3가지 자기주도 동기 교육, 창의교육, 융합교육에 대해 자세히 설명했다.
                
중국은 경제성장과 함께 개개인의 소득수준이 높아지면서 사교육시장도 빠르게 성장 하고 있다. 현재 중국의 사교육시장은 4330억 위안(한화 약 74조원)이며, 2020년에는 5000억 위안(한화 약 85조원)까지 확대될 전망이다. 특히 중산층 가정의 프리미엄 교육이 급속도로 성장하고 있어 주은베이징의 성장가능성도 높이 점쳐지고 있다.  
                
주은교육(주) 홍혜숙 대표는 “앞으로 어린음악대·어린화가들은 다양한 예술 활동 교류와 컨텐츠 개발 및 공유를 통해 한국과 중국 간의 교육문화산업의 교두보 역할을 하게 될 것이다.”며 “주은교육과 엉클샘교육그룹이 함께 세계적인 교육기업으로서 발돋움하기를 기대하고 있다."고 포부를 밝혔다.
`,
            },
            {
                text: "원문보기 : https://news.mt.co.kr/mtview.php?no=2020060416133852296",
            },
        ],
    },
    {
        params: "412",
        date: "2019.05.24",
        title: "주은교육(주) 2019 대한민국 우수기업대상 교육대상 수상",
        thumbnail: "/images/news/jueun_edu/2019-05-24.png",
        type: "소식",
        des: [
            {
                text: `
                주은교육(주)2019 대한민국 우수기업대상 교육대상 수상!
                안녕하십니까.
                금일 5월 24일(금)! 서울 프레스센터에서
                2019 대한민국 우수기업대상 시상식이 있었습니다.
                어린음악대 어린화가들 본사 주은교육(주)이 2019 대한민국 우수기업대상 교육대상을 수상했습니다!
                전국에 계신 모든 학부모님들과 어린음악대·어린화가들 친구들, 그리고 모든 가맹원 원장님과 선생님들께서
                보내주신 모든 믿음과 응원, 성원에 깊이 깊이 감사드리며, 앞으로도 최고의 교육과 최선의 노력을 다할 것을 굳게 약속드립니다.
                감사합니다!
`,
            },
            {
                image: "/images/news/jueun_edu/2019-05-24.png",
            },
        ],
    },
    {
        params: "413",
        date: "2019.05.22",
        title: "주은교육(주) & 중국 엉클샘교육그룹 협약체결",
        thumbnail: "/images/news/jueun_edu/2019-05-22.png",
        type: "소식",
        des: [
            {
                text: `
주은교육(주) & 중국 엉클샘교육그룹 협약체결!!안녕하십니까.바로 어제 5월 21일(화) 서울 워커힐 호텔에서주은교육(주) & 중국 엉클샘교육그룹
이 협약식을 가지고앞으로 중국 사업 성공을 위해 다양한 협력을 해 나가기로 약속했습니다.이번 주은교육(주)과 중국 엉클샘교육그룹의 협력은
한국의 음악미술교육이 해외시장에서 성공적인 자리매김을 하고 한국과 중국의 글로벌인재양성과 문화예술발전에 함께한다는 취지에서 매우 중요한 시작이 될 것입니다.
참석해 주신 모든 내외빈 분들께 진심으로 감사드리며,앞으로 주은교육(주)의 다양한 행보에많은 기대와 응원 부탁드립니다.감사합니다.
감사합니다!
`,
            },
            {
                image: "/images/news/jueun_edu/2019-05-22.png",
            },
        ],
    },
    {
        params: "414",
        date: "2018.10.15",
        title: "주은교육(주) 계명대학교 산학협력(가족회사) 체결",
        thumbnail: "/images/news/jueun_edu/2018-10-15.png",
        type: "소식",
        des: [
            {
                text: `
안녕하세요.
유·아동 전문 예술교육기업 주은교육(주)는
대구, 영남지역의 우수한 인재들이 많이 다니는 역사깊은 계명대학교 음악대학과 산학협력 체결(가족회사)을 맺었습니다.
`,
            },
            {
                image: "/images/news/jueun_edu/2018-10-15.png",
            },
            {
                text: `
가족회사제는 기업과 대학이 지속적인 연계를 맺고 인적, 물적 자원을 체계적으로 공유하는 맞춤형 산·학 협력체제를 말하는 것으로,
기업과 대학간의 기술자문이나 정보교류는 물론 인적자원 교류를 통해 우수한 인재들을 배출해 낼 수 있는 기반을 마련하고 기술혁신과 신재품 개발에도 여러가지 긍정적 효과가 기대됩니다.
그동안 대학과 기업간의 산학협력체결은 많이 추진되어 왔지만 사실 음악대학과의 산학협력은 많지 않았습니다.
음악, 미술교육시장의 확대와 발전을 위해서 달려가고 있는 저희 주은교육으로서는 이번 가족회사 체결을 계기로
음악, 미술교육의 가치가 제대로 증명되고 그 가치를 더욱 높일 수 있는 교육문화를 계명대학교의 우수한 인재들과 만들어갈 수 있을 것이라는 가슴벅찬 기대와 설레임을 많이 느끼고 있습니다.
앞으로도 더 많은 대학과 좋은 산학협력의 관계를 맺을 수 있길 바라며, 우리 음악, 미술교육의 발전에도 큰 도움이 될 수 있길 기도합니다. 감사합니다.
`,
            },
        ],
    },
    {
        params: "415",
        date: "2018.05.09",
        title: "[에듀동아] 2018년 5월 9일(수) 10주년 보도기사",
        thumbnail: "/images/news/jueun_edu/2018-05-09.png",
        type: "뉴스",
        des: [
            {
                text: `작은 피아노학원에서 예능융합교육기업이 되기까지`,
            },
            {
                image: "/images/news/jueun_edu/2018-05-09.png",
            },
            {
                image: "/images/news/jueun_edu/2018-05-09(2).png",
            },
            {
                text: `국내 최초이자 최대 규모인 유, 아동 전문 음악, 미술학원 프랜차이즈 사업을 진행하고 있는 주은교육(주)(대표: 홍혜숙, 안중현)이 올해로 10주년을 맞이했다. 

주은교육은 지난 2008년 5월 음악교구사업을 시작하여 올해 2018년 10주년이 되기까지 음악, 미술 교육과 관련된 다양한 영역으로 사업을 확장하며 꾸준히 성장해왔다. 
특히 주은교육이 자랑하는 대표적인 피아노학원, 미술학원 프랜차이즈 어린음악대와 어린화가들은 2011년부터 가맹원이 꾸준히 증가해 현재 전국 300여개 가맹원을 두고 있는 국내 최대 규모 프랜차이즈로 자리 잡은 것으로 알려져 있다. 
여기에 올해 1월 소비자가 뽑은 가장 신뢰하는 브랜드 음악학원 프랜차이즈 부문에서 대상을 수상하며 전국 학부모들과 가맹원의 열렬한 지지를 얻고 있다.


주은교육은 교구 사업이나 음악학원, 미술학원 프랜차이즈 사업 외에도 교육 프로그램, 교육 콘텐츠 개발에도 심혈을 기울이고 있다. 
주은교육은 부설연구소 한국아동예술창의연구소를 설립해 음악의 전 영역을 아우르는 음악 체험 통합 놀이교육 프로그램 뮤직고고, 연주 테크닉뿐만 아니라 멜로디, 리듬, 음악이론 등을 통합적으로 배우는 피아노 레슨 프로그램 피아노고고, 다양한 미디어를 활용한 미술 특강 프로그램 아트플러스 등 다양한 음악, 미술 전문 프로그램을 개발하고 이를 활용하기 위한 교육 시스템을 구축했다. 
무엇보다 4세~7세의 유치부에서부터 초등 고학년까지 교육과정과 연계된 연령별, 단계별 맞춤 커리큘럼을 개발해 프랜차이즈 사업에 도입함으로써 전문적이고 체계적인 예능 교육 기업으로 자리 잡아왔다. 

주은교육 홍혜숙 소장은 “하루에도 수많은 콘텐츠가 쏟아지고 수많은 교육프로그램이 나타났다 사라지는 현 교육시장에서 10년이라는 시간 동안 성장해 오기가 쉽지는 않은 일이다. 누구보다 한 발 앞서 교육 시장 변화에 관심을 가지고 최적의 교육 솔루션을 제시하기 위해 노력해왔다. 예능 교육 시장의 변화를 이끌어왔고 또한 새로운 변화를 위해 준비 중이다.”고 밝혔다.
이어 “주은교육의 앞으로의 10년은 국내 시장에만 머무르지 않을 것이다. 국내 교육 시장뿐만 아니라 중국 교육 시장으로 발을 넓혀 중국 내 여러 기업과 협력방안을 모색하고 있다. 또한 새로운 컨텐츠 개발과 함께 이를 스마트 환경에 접목해 활용하는 등 다양한 변화를 준비하고 있다.”고 전했다.
`,
            },
            {
                text: "원문보기 : http://edu.donga.com/?p=article&ps=view&at_no=20180509141926984572",
            },
        ],
    },
    {
        params: "416",
        date: "2018.01.24",
        title: "어린음악대, 2018년 소비자가 뽑은 브랜드대상 수상",
        thumbnail: "/images/news/jueun_edu/2018-01-24.png",
        type: "소식",
        des: [
            {
                text: `
                안녕하십니까.
                주은교육의 대표적인 브랜드 "어린음악대"가
~디지털조선일보가 주최하고 조선일보, TV조선, 산업통상자원부, 농림축산식품부가 후원하는
2018년 소비자가 뽑은 가장 신뢰하는 브랜드 대상 음악학원프랜차이즈부문 대상을 수상하였습니다! 
늘 뜨거운 관심과 지지를 보내주시는 학부모님과 끊임없는 열정과 노력으로 어린음악대를 이끌어나가주시는 전국의 어린음악대 원장님들, 교사분들께 깊은 감사의 말씀 드립니다. 
감사합니다.^^
`,
            },
            {
                image: "/images/news/jueun_edu/2018-01-24.png",
            },
        ],
    },
];
const news_data = { music, art, jueun };

export default news_data;
