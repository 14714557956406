import { Stack } from "@mui/material";
import React from "react";
import { TitleComponents } from "../titleComponent/TitleComponents";
import RoundedButton from "../elements/RoundedButton";

export default function FindHakwon() {
    const brandPathName = window.location.pathname.split("/")[1];
    const brandName = brandPathName === "littleband" ? "어린음악대" : "어린화가들";
    const naverLink = () => {
        const openNewWindow = window.open("about:blank");
        openNewWindow.location.href = `https://map.naver.com/v5/search/${brandName}`;
    };

    const kakaoMapLink = () => {
        const openNewWindow = window.open("about:blank");
        openNewWindow.location.href = `https://map.kakao.com/link/search/${brandName}`;
    };

    return (
        <Stack alignItems="center" sx={{ p: "64px 16px" }}>
            {/* 타이틀 */}
            <Stack sx={{ mb: "44px" }}>
                <TitleComponents sx={{ alignItems: "center" }}>
                    <TitleComponents.MainTitle
                        stackStyle={{ textAlign: "center" }}
                        typoStyle={{ color: "text.primary" }}>
                        <span>학원 찾기</span>
                    </TitleComponents.MainTitle>
                    <TitleComponents.Description stackStyle={{ textAlign: "center" }}>
                        <span>예술교육 NO.1</span>
                        <span>{`${brandName}를 찾아보세요`}</span>
                    </TitleComponents.Description>
                </TitleComponents>
            </Stack>
            <Stack sx={{ width: "168px" }}>
                <img src={`/images/${brandPathName}/findhakwon/map.webp`} />
            </Stack>
            <Stack spacing={1.5} sx={{ mt: "40px" }}>
                <RoundedButton onClick={naverLink} size="large" variant="contained" bgcolor="#3cb400">
                    네이버 지도로 찾기
                </RoundedButton>
                <RoundedButton
                    onClick={kakaoMapLink}
                    size="large"
                    variant="contained"
                    bgcolor="#fbe102"
                    otherColor="#343434">
                    카카오맵으로 찾기
                </RoundedButton>
            </Stack>
        </Stack>
    );
}
