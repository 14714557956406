import React from "react";
import ContainerLayout from "../../layouts/ContainerLayout";
import { TitleComponents } from "../../titleComponent/TitleComponents";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import ImgCard from "../../cards/ImgCard";

export default function JueunTab() {
    return (
        <>
            {/* 인사말 */}
            <ContainerLayout sx={{ pb: 8 }}>
                <Stack
                    spacing={{ xs: 0, md: 3 }}
                    sx={{ alignItems: "center", mt: { xs: 6, md: 8 }, px: { xs: 0, md: 15 } }}>
                    <TitleComponents.MainTitle
                        stackStyle={{ mt: 8 }}
                        typoStyle={{ color: "primary.main", textAlign: "center" }}>
                        주은교육은?
                    </TitleComponents.MainTitle>
                    <ImgCard
                        bodyColor="#fff2f5"
                        smallImgSrc="/images/jueun-edu/jueun-edu-logo.svg"
                        smallImgWidth="245px"
                        smallImgTop="75px"
                        descStyle={{ span: { display: "block" } }}
                        cardStyle={{ flex: 1, width: "100%" }}>
                        <span>오감 놀이 예술 교육을 통해</span>
                        <span>아이들이 스스로 놀이와 학습을 동시에 즐길 수 있는</span>
                        <span>재미있고 바른 교육을 지향합니다</span>
                    </ImgCard>
                </Stack>
            </ContainerLayout>
            <Divider />
            <ContainerLayout bgColor="constant.darkWhite" spacing={2} sx={{ pt: 9.5, pb: 8 }}>
                <Stack direction={{ xs: "column", lg: "row" }}>
                    <TitleComponents sx={{ flexGrow: 1, mb: 5.5 }}>
                        <TitleComponents.MainTitle>인사말</TitleComponents.MainTitle>
                        <TitleComponents.Description>
                            <span>예술은 사람의 마음을 움직일 수 있습니다</span>
                            <span>그리고 감동을 줍니다</span>
                        </TitleComponents.Description>
                    </TitleComponents>
                    <Stack
                        sx={{
                            ml: "auto",
                            maxWidth: 763,
                            px: { xs: 2.5, md: 4 },
                            py: 4,
                            backgroundColor: "constant.white",
                            borderRadius: "30px",
                        }}>
                        <Typography variant="body1" sx={{ span: { display: "block" } }}>
                            <span>
                                실수없이 완벽하게 피아노를 연주하고, 미술대회에서 상을 많이받는 것만이 올바른
                                예술교육일까요? 입시를 위한 예술교육은 기계적인 반복 학습으로 자라나는 아이들의창의력을
                                제한할 뿐입니다.
                            </span>
                            &nbsp;
                            <span>
                                예술교육의 진정한 의미는 예술을 통한 건강한 자존감 형성에 있습니다. 예술은 본인 뿐만
                                아니라 그것을 듣고 보고 느낄 수 있는 모든 사람과의 공유이며, 그 어떠한 말과 눈빛,
                                손길보다 아름다운 소통 수단일 수있습니다.
                            </span>
                            &nbsp;
                            <span>
                                주은교육㈜는 세상을 향해 한 걸음씩 다가가는 소중한 우리 아이들이 음악교육을 통해 풍부한
                                감성을 기르고 내면의 소리에 귀를 기울여 자신을 사랑하는 건강한 자존감을 가진 인격체로
                                성장하길 바랍니다.
                            </span>
                            &nbsp;
                            <span>주은교육 임원진 일동.</span>
                        </Typography>
                    </Stack>
                </Stack>
            </ContainerLayout>
            <Divider />
            <ContainerLayout sx={{ pt: 6, pb: 8 }}>
                <Stack justifyContent="space-between" direction={{ xs: "column", lg: "row" }}>
                    <TitleComponents sx={{ flexGrow: 1, mb: 5.5 }}>
                        <TitleComponents.MainTitle>기업이념</TitleComponents.MainTitle>
                    </TitleComponents>
                    <Stack
                        sx={{
                            ml: "auto",
                            flexShrink: 0,
                            width: { xs: "100%", md: 763 },
                            py: 8,
                            backgroundColor: "primary.main",
                            border: "1px solid",
                            borderColor: "constant.lightGray",
                            borderRadius: 8,
                        }}>
                        <img
                            style={{ maxHeight: 280, objectFit: "contain" }}
                            src="/images/jueun-edu/ideology.png"
                            alt="ideology"
                        />
                    </Stack>
                </Stack>
            </ContainerLayout>
            <Divider />
            <ContainerLayout bgColor="constant.darkWhite">
                <Stack sx={{ position: "relative", pt: 8, pb: { xs: 5.5, md: 10 }, overflow: "hidden" }}>
                    <TitleComponents sx={{ flex: 1, mb: 5.5, mr: "auto" }}>
                        <TitleComponents.MainTitle>슬로건</TitleComponents.MainTitle>
                    </TitleComponents>
                    <Stack
                        sx={{
                            maxWidth: { xs: "100%", md: "auto" },
                            width: { xs: "auto", md: 763 },
                            mt: 17,
                            ml: "auto",
                            pt: 2,
                            pb: 2,
                            px: { xs: 2, md: 4 },
                            zIndex: 1,
                            backgroundColor: "constant.white",
                            border: "1px solid",
                            borderColor: "constant.lightGray",
                            borderRadius: 8,
                        }}>
                        <Stack alignItems="center" justifyContent="center" direction="row" spacing={{ xs: 3, md: 5 }}>
                            <Typography variant="h2" sx={{ color: "primary.main" }}>
                                "
                            </Typography>
                            <Typography variant="subtitle1" sx={{ lineHeight: 3.0 }}>
                                아이들의 눈으로 세상을 향합니다
                            </Typography>
                            <Typography variant="h2" sx={{ color: "primary.main" }}>
                                "
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack
                        sx={{
                            img: {
                                position: "absolute",
                                bottom: 0,
                                right: -48,
                                zIndex: 0,
                                maxWidth: { xs: 425, md: 575 },
                            },
                        }}>
                        <img src="/images/jueun-edu/slogan.webp" alt="slogan" />
                    </Stack>
                </Stack>
            </ContainerLayout>
            <Divider />
            <ContainerLayout sx={{ py: 8 }}>
                <Stack justifyContent="space-between" direction={{ xs: "column", lg: "row" }}>
                    <TitleComponents sx={{ flexGrow: 1, mb: 5.5 }}>
                        <TitleComponents.MainTitle>가치추구</TitleComponents.MainTitle>
                        <TitleComponents.Description>
                            기본과 가치를 담은 예술교육, 주은교육에서 시작합니다
                        </TitleComponents.Description>
                    </TitleComponents>
                    <Stack sx={{ width: "100%", alignItems: "flex-end" }}>
                        <Grid
                            container
                            spacing={{ xs: 0, md: 2 }}
                            sx={{ ml: "auto", maxWidth: { xs: "100%", md: 768 } }}>
                            {Array.map((item, index) => (
                                <Grid item key={item.title} xs={12} lg={6}>
                                    <Stack
                                        direction={{ xs: "row", md: "column" }}
                                        sx={{
                                            alignItems: { xs: "flex-start", md: "center" },
                                            ml: { xs: 0, md: index % 2 === 0 ? "auto" : 0 },
                                            mb: { xs: 2, md: 0 },
                                            pt: 3,
                                            pb: 1.5,
                                            px: 3.5,
                                            width: { xs: "100%" },
                                            minHeight: 184,
                                            whiteSpace: "nowrap",
                                            backgroundColor: item.bgColor,
                                            borderRadius: 8,
                                        }}>
                                        <Typography variant="h3" sx={{ flex: { xs: 1, md: 0 } }}>
                                            {item.title}
                                        </Typography>
                                        <Stack sx={{ mt: "auto" }}>
                                            <img
                                                style={{ maxWidth: 108, maxHeight: 108 }}
                                                src={item.iconSrc}
                                                alt="icon"
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                </Stack>
            </ContainerLayout>
        </>
    );
}

const Array = [
    { title: "교육문화 선도기업", iconSrc: "/images/jueun-edu/icon/01.svg", bgColor: "rgba(254, 210, 0, 0.5)" },
    { title: "교육효과 극대화", iconSrc: "/images/jueun-edu/icon/02.svg", bgColor: "rgba(241, 156, 193, 0.5)" },
    { title: "맞춤형 교구개발", iconSrc: "/images/jueun-edu/icon/03.svg", bgColor: "rgba(130, 192, 45, 0.5)" },
    { title: "학원경영 시스템", iconSrc: "/images/jueun-edu/icon/04.svg", bgColor: "rgba(0, 171, 235, 0.5)" },
];
