import { IconButton, Stack } from "@mui/material";
import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export default function NewsDetailBtn({ onClick }) {
    return (
        <Stack
            sx={{
                borderRadius: "50% 0 0 0",
                backgroundColor: "constant.white",
            }}>
            <IconButton
                onClick={onClick}
                sx={{
                    width: { xs: 32, md: 40 },
                    height: { xs: 32, md: 40 },
                    borderRadius: "50%",
                    backgroundColor: "#f5f5f5",
                    color: "white",
                    overflow: "hidden",
                }}>
                <ArrowBackIosNewIcon sx={{ fontSize: { xs: "1.25rem", md: "1.5rem" }, color: "#c6c6c6" }} />
            </IconButton>
        </Stack>
    );
}
