import React from "react";
import { Stack, Typography } from "@mui/material";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import ContainerLayout from "../../layouts/ContainerLayout";

export default function MusicGogoStep() {
    return (
        <ContainerLayout bgColor="constant.darkWhite" sx={{ py: 8 }}>
            <Stack direction="row" spacing={1}>
                <Stack
                    sx={{
                        justifyContent: "center",
                        px: 1,
                        borderRadius: 6,
                        backgroundColor: "littleBand.musicgogo",
                    }}>
                    <Typography
                        variant="body1"
                        sx={{
                            color: "constant.white",
                            fontWeight: "bold",
                            writingMode: "vertical-rl",
                            letterSpacing: 2,
                        }}>
                        음악영역
                    </Typography>
                </Stack>
                <Stack
                    sx={{
                        width: "100%",
                        borderRadius: 8,
                        border: "1px solid",
                        borderColor: "constant.lightGray",
                        overflow: "hidden",
                    }}>
                    <Stack
                        direction="row"
                        sx={{
                            justifyContent: "space-around",
                            pt: 2,
                            pb: 1,
                            px: 2,
                            backgroundColor: "constant.white",
                        }}>
                        <img src="/images/littleband/music-gogo/icon-1.svg" alt="icon-1.svg" />
                        <img src="/images/littleband/music-gogo/icon-2.svg" alt="icon-2.svg" />
                        <img src="/images/littleband/music-gogo/icon-3.svg" alt="icon-3.svg" />
                        <img src="/images/littleband/music-gogo/icon-4.svg" alt="icon-4.svg" />
                    </Stack>
                    <Stack
                        direction="row"
                        sx={{
                            justifyContent: "space-around",
                            px: 2,
                            py: 1,
                            height: "100%",
                            backgroundColor: "constant.lightGray",
                        }}>
                        <Typography sx={{ width: 67, whiteSpace: "nowrap", textAlign: "center" }}>악기연주</Typography>
                        <Typography sx={{ width: 67, whiteSpace: "nowrap", textAlign: "center" }}>신체활동</Typography>
                        <Typography sx={{ width: 67, whiteSpace: "nowrap", textAlign: "center" }}>음악이론</Typography>
                        <Typography sx={{ width: 67, whiteSpace: "nowrap", textAlign: "center" }}>감상&표현</Typography>
                    </Stack>
                </Stack>
            </Stack>
            <KeyboardArrowDownOutlined fontSize="large" sx={{ ml: 0.2, color: "littleBand.musicgogo" }} />
            <Stack direction="row" spacing={1}>
                <Stack
                    sx={{
                        justifyContent: "center",
                        px: 1,
                        borderRadius: 6,
                        backgroundColor: "littleBand.musicgogo",
                    }}>
                    <Typography
                        variant="body1"
                        sx={{
                            color: "constant.white",
                            fontWeight: "bold",
                            writingMode: "vertical-rl",
                            letterSpacing: 2,
                        }}>
                        수업진행
                    </Typography>
                </Stack>
                <Stack
                    sx={{
                        width: "100%",
                        borderRadius: 8,
                        border: "1px solid",
                        borderColor: "constant.lightGray",
                        overflow: "hidden",
                    }}>
                    <Stack
                        direction="row"
                        sx={{
                            justifyContent: "space-around",
                            pt: 2,
                            pb: 1,
                            px: 2,
                            backgroundColor: "constant.white",
                        }}>
                        <img src="/images/littleband/music-gogo/icon-5.svg" alt="icon-1.svg" />
                        <img src="/images/littleband/music-gogo/icon-6.svg" alt="icon-2.svg" />
                    </Stack>
                    <Stack
                        direction="row"
                        sx={{
                            justifyContent: "space-around",
                            px: 2,
                            py: 1,
                            height: "100%",
                            backgroundColor: "constant.lightGray",
                        }}>
                        <Typography sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                            전문강사의 온라인 수업
                        </Typography>
                        <Typography sx={{ width: 125, whiteSpace: "nowrap", textAlign: "center" }}>
                            1인교구 활용, 활동
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            <KeyboardArrowDownOutlined fontSize="large" sx={{ ml: 0.2, color: "littleBand.musicgogo" }} />
            <Stack direction="row" spacing={1}>
                <Stack
                    sx={{
                        justifyContent: "center",
                        px: 1,
                        borderRadius: 6,
                        backgroundColor: "littleBand.musicgogo",
                    }}>
                    <Typography
                        variant="body1"
                        sx={{
                            color: "constant.white",
                            fontWeight: "bold",
                            writingMode: "vertical-rl",
                            letterSpacing: 2,
                        }}>
                        수업심화
                    </Typography>
                </Stack>
                <Stack
                    sx={{
                        width: "100%",
                        borderRadius: 8,
                        border: "1px solid",
                        borderColor: "constant.lightGray",
                        overflow: "hidden",
                    }}>
                    <Stack
                        direction="row"
                        sx={{
                            justifyContent: "space-around",
                            pt: 2,
                            pb: 1,
                            px: 2,
                            backgroundColor: "constant.white",
                        }}>
                        <img src="/images/littleband/music-gogo/icon-5.svg" alt="icon-1.svg" />
                        <img src="/images/littleband/music-gogo/icon-6.svg" alt="icon-2.svg" />
                    </Stack>
                    <Stack
                        direction="row"
                        sx={{
                            justifyContent: "space-around",
                            px: 2,
                            py: 1,
                            height: "100%",
                            backgroundColor: "constant.lightGray",
                        }}>
                        <Typography sx={{ width: 125, whiteSpace: "nowrap", textAlign: "center" }}>
                            학원선생님 심화활동
                        </Typography>
                        <Typography sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                            1인 워크북 활동, 마무리
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}
