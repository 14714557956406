import React from "react";

import { Stack } from "@mui/material";

import { TitleComponents } from "../titleComponent/TitleComponents";
import ContainerLayout from "./ContainerLayout";
import useSetJumboTitle from "../../hooks/useSetJumboTitle";

export default function JumboTitle({ currentTab, children }) {
    const [jumboInfo] = useSetJumboTitle({ contextState: currentTab });

    return (
        <Stack
            sx={{
                position: "relative",
                minHeight: { xs: 300, md: 375 },
                backgroundImage: jumboInfo.backgroundImage,
                backgroundAttachment: { xs: "scroll", md: "fixed" },
                backgroundRepeat: "no-repeat",
                backgroundSize: { xs: "cover", md: "auto" },
                backgroundPosition: { xs: "center", md: "top" },
            }}>
            {/* HomepageNavbar */}
            {children}
            <Stack
                sx={{
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    backdropFilter: "blur(5px)",
                }}
            />
            <ContainerLayout bgColor="transparent">
                <TitleComponents
                    sx={{
                        alignItems: "flex-start",
                        position: "absolute",
                        bottom: { xs: 16, md: 32 },
                    }}>
                    <TitleComponents.MainTitle typoStyle={{ color: "constant.white" }}>
                        {jumboInfo.title}
                    </TitleComponents.MainTitle>
                </TitleComponents>
            </ContainerLayout>
        </Stack>
    );
}
