import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Divider, IconButton, Stack } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import MusicGogoIntro from "../../components/littleband/music-gogo/MusicGogoIntro";
import MusicGogoStep from "../../components/littleband/music-gogo/MusicGogoStep";
import MusicGogoTool from "../../components/littleband/music-gogo/MusicGogoTool";
import Footer from "../../components/layouts/Footer";
import RoundedButton from "../../components/elements/RoundedButton";
import ContainerLayout from "../../components/layouts/ContainerLayout";

export default function MusicGoGoDetail() {
    const navigate = useNavigate();

    const goToLittleBandProgram = () => {
        navigate("/littleband/program");
    };

    const goToMusicGogo = () => {
        const openNewWindow = window.open("about:blank");
        openNewWindow.location.href = "https://musicgogo.co.kr/";
    };

    // 최초 진입시 스크롤 최상단으로 이동
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>어린음악대 - 뮤직고고 소개</title>
                <meta
                    name="description"
                    content="유·아동에게 최적화된 악기연주, 신체활동, 음악이론, 감상&표현을 포함한 음악교육 전 영역의 음악체험 통합놀이 교육 프로그램"
                    data-react-helmet="true"
                />
                <link rel="icon" type="image/x-icon" href="/favicon/little-band/favicon-16x16.ico" />
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:locale" content="ko_KR" data-react-helmet="true" />
                <meta property="og:url" content="https://ikidsband.com/musicgogo" data-react-helmet="true" />
                <meta property="og:site_name" content="어린음악대" data-react-helmet="true" />
                <meta property="og:title" content="어린음악대 - 뮤직고고" data-react-helmet="true" />
                <meta
                    property="og:description"
                    content="유·아동에게 최적화된 악기연주, 신체활동, 음악이론, 감상&표현을 포함한 음악교육 전 영역의 음악체험 통합놀이 교육 프로그램"
                    data-react-helmet="true"
                />
                <meta
                    property="og:image"
                    content="https://jueun-edu.com/images/og-jueun-edu.png"
                    data-react-helmet="true"
                />

                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
                <meta name="twitter:title" content="어린음악대 - 뮤직고고" data-react-helmet="true" />
                <meta
                    name="twitter:description"
                    content="유·아동에게 최적화된 악기연주, 신체활동, 음악이론, 감상&표현을 포함한 음악교육 전 영역의 음악체험 통합놀이 교육 프로그램"
                    data-react-helmet="true"
                />
                <meta
                    name="twitter:image"
                    content="https://jueun-edu.com/images/og-jueun-edu.png"
                    data-react-helmet="true"
                />
            </Helmet>
            <ContainerLayout
                sx={{ position: "fixed", width: "100%", top: 0, zIndex: 1, backgroundColor: "constant.white" }}>
                <Stack direction="row" sx={{ py: 2, alignItems: "center", justifyContent: "space-between" }}>
                    <IconButton onClick={goToLittleBandProgram} sx={{ backgroundColor: "constant.darkWhite" }}>
                        <ArrowBackIosNewIcon />
                    </IconButton>
                    <img src="/images/littleband/musicgogo-logo.svg" alt="musicgogo logo" style={{ maxWidth: 78 }} />
                    <Stack sx={{ width: 40 }}></Stack>
                </Stack>
            </ContainerLayout>
            <Divider />
            {/* 인트로 */}
            <MusicGogoIntro />
            <Divider />
            {/* 단계 설명 */}
            <MusicGogoStep />
            <Divider />
            {/* 교구 설명 */}
            <MusicGogoTool />
            {/* 뮤직고고 바로가기 */}
            <Stack sx={{ alignItems: "center", mb: 8 }}>
                <RoundedButton
                    onClick={goToMusicGogo}
                    size="large"
                    variant="outlined"
                    otherColor="littleBand.musicgogo"
                    bgcolor="littleBand.musicgogo"
                    otherHoverColor="constant.white">
                    뮤직고고 바로가기
                </RoundedButton>
            </Stack>
            <Footer />
        </>
    );
}
