import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

import { Stack } from "@mui/material";

export default function ScrollMouse({ onClick }) {
    return (
        <Stack
            onClick={onClick}
            sx={{
                display: { xs: "flex", md: "none" },
                width: "100%",
                alignItems: "center",
                position: "absolute",
                bottom: 46,
            }}>
            <ScrollMouseDown>
                <ScrollPointer />
            </ScrollMouseDown>
        </Stack>
    );
}

const ScrollMouseDown = styled.span`
    position: relative;
    display: inline-block;
    width: 28px;
    height: 46px;
    margin: 0 auto;
    border: 2px solid #fff;
    border-radius: 20px;
`;

const scrollDown = keyframes`
    0% {
        top: 10px;
        opacity: 0;
    }
    15% {
        top: 10px;
        opacity: 1;
    }
    30% {
        top: 10px;
        opacity: 1;
    }
    45% {
        top: 28px;
        opacity: 1;
    }
    100% {
        top: 28px;
        opacity: 0;
    }
`;

const ScrollPointer = styled.span`
    position: absolute;
    top: 14px;
    left: 10px;
    width: 4px;
    height: 4px;
    background-color: #fff;
    border-radius: 50%;
    animation: ${scrollDown} 2.5s infinite;
`;
