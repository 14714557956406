import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SouthIcon from "@mui/icons-material/South";
import theme from "../../styles/theme.js";
import { Grid, Stack } from "@mui/material";
import NewsCard from "../cards/NewsCard";
import RoundedButton from "../elements/RoundedButton.js";
import news_data from "../data/news_data.js";

export default function News({ brand }) {
    const [count, setCount] = useState(6);

    const navigate = useNavigate();

    const goToNewsDetail = params => {
        navigate(`/notice/news/${params}`, {
            state: {
                brand: brand === "어린음악대" ? "littleband" : brand === "어린화가들" ? "littleartists" : "jueunedu",
                params: params,
            },
        });
    };
    const handleAddCount = () => {
        setCount(count + 3);
    };
    const DATA = brand === "어린음악대" ? news_data.music : brand === "어린화가들" ? news_data.art : news_data.jueun;

    return (
        <Stack>
            <Grid container spacing={2}>
                {DATA.filter((_, i) => i < count).map((info, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Grid item xs={12} md={6} xl={4} key={`${info.title}-${index}`}>
                        <NewsCard
                            // key={`${info.title}-${index}`}
                            date={info.date}
                            title={info.title}
                            thumbnail={info.thumbnail}
                            type={info.type}
                            onClick={() => goToNewsDetail(info.params)}
                        />
                    </Grid>
                ))}
            </Grid>

            <Stack alignItems="center" sx={{ mt: "24px", mb: "64px" }}>
                {count <= DATA.length && (
                    <RoundedButton
                        size="large"
                        otherColor={theme.palette.littleBand.main}
                        otherHoverColor="white"
                        bgcolor={theme.palette.littleBand.main}
                        onClick={handleAddCount}
                        endIcon={<SouthIcon />}>
                        더보기
                    </RoundedButton>
                )}
            </Stack>
        </Stack>
    );
}
