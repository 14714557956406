import React from "react";

import { HomePageLayout } from "./components/layouts/HomePageLayout";
import ComponentTest from "./pages/test/ComponentTest";
import NotFound from "./pages/NotFound";
// 주은교육
import Introduce from "./pages/brand/Introduce";
import Brand from "./pages/brand/brand";
import Notice from "./pages/littleband/notice";
import { NewsDetail } from "./pages/littleband/newsdetail";
import { GalleryDetail } from "./pages/littleband/gallerydetail";
// 어린음악대
import Littleband from "./pages/littleband/littleband";
import BandProgram from "./pages/littleband/BandProgram";
import PianoGoGoDetail from "./pages/littleband/PianoGoGoDetail";
import MusicGoGoDetail from "./pages/littleband/MusicGoGoDetail";
// 어린화가들
import LittleArists from "./pages/littleartists/littleartists";
import ArtistProgram from "./pages/littleartists/ArtistProgram";

const routes = [
    {
        path: "/",
        element: <HomePageLayout menu="brand" />,
        children: [
            { index: true, element: <Introduce /> },
            { path: "brand", element: <Brand /> },
            { path: "notice", element: <Notice /> },
        ],
    },
    {
        path: "/default",
        element: <HomePageLayout menu="brand" />,
        children: [
            { index: true, element: <Introduce /> },
            { path: "brand", element: <Brand /> },
            { path: "notice", element: <Notice /> },
        ],
    },
    {
        path: "littleband",
        element: <HomePageLayout menu="littleband" />,
        children: [
            {
                index: true,
                element: <Littleband />,
            },
            {
                path: "program",
                element: <BandProgram />,
            },
            { path: "notice", element: <Notice /> },
        ],
    },
    { path: "musicgogo", element: <MusicGoGoDetail /> },
    { path: "pianogogo", element: <PianoGoGoDetail /> },
    {
        path: "/notice/news/:params",
        element: <NewsDetail />,
    },
    {
        path: "/notice/gallery/:params",
        element: <GalleryDetail />,
    },
    {
        path: "littleartists",
        element: <HomePageLayout menu="littleartists" />,
        children: [
            {
                index: true,
                element: <LittleArists />,
            },
            {
                path: "program",
                element: <ArtistProgram />,
            },
            { path: "notice", element: <Notice /> },
        ],
    },
    {
        path: "franchise",
        element: <HomePageLayout menu="franchise" />,
    },
    { path: "component", element: <ComponentTest /> },
    { path: "/*", element: <NotFound /> },
];

export default routes;
