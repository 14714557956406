import React, { Fragment } from "react";

import { Divider, Stack } from "@mui/material";
import { TitleComponents } from "../../titleComponent/TitleComponents";
import ImgCard from "../../cards/ImgCard";
import ImgSlickCard from "../../cards/ImgSlickCard";
import RoundedButton from "../../elements/RoundedButton";
import ContainerLayout from "../../layouts/ContainerLayout";
import ImuzmallLinkButton from "../../elements/ImuzmallLinkButton";

export default function ArtPlusTab() {
    const openArtistCafe = () => {
        const openNewWindow = window.open("about:blank");
        openNewWindow.location.href = "https://cafe.naver.com/jueunart";
    };

    return (
        <>
            {/* 요약 */}
            <ContainerLayout>
                <Stack sx={{ mt: 5, pb: 8, alignItems: "center" }}>
                    <TitleComponents sx={{ mb: 5, textAlign: "center" }}>
                        <TitleComponents.MainTitle>아트플러스</TitleComponents.MainTitle>
                    </TitleComponents>
                    <ImgCard
                        smallImgSrc="/images/littleartists/art-plus-logo.png"
                        smallImgWidth="244px"
                        height="358px"
                        bodyColor="#fffcc7"
                        cardStyle={{ width: { xs: "100%", md: "50%" } }}
                        descStyle={{ span: { display: "block" } }}>
                        <span>사고력, 상상력, 창의력, 표현력을 한번에!</span>
                        <span>
                            주제 접근, 작품 감상, 발상 표현, 입체 조형 활동을 통해
                        </span>
                        <span>창의적으로 문제를 해결하며 생각의 힘을 키우는</span>
                        <span>어린화가들의 창의 융합 프로그램</span>
                    </ImgCard>
                </Stack>
            </ContainerLayout>
            <Divider />
            {artPlusArr.map((item, index) => (
                <Fragment key={item.mainTitle}>
                    {index !== 0 && <Divider sx={{ backgroundColor: "constant.gray" }} />}
                    <ContainerLayout>
                        <Stack direction={{ xs: "column", md: "row" }} sx={{ px: { xs: 0, md: 8 } }}>
                            <TitleComponents sx={{ mt: 8, mb: { md: 8 }, mr: "auto" }}>
                                <TitleComponents.Subtitle typoStyle={{ color: item.subtitleColor }}>
                                    {item.subtitle}
                                </TitleComponents.Subtitle>
                                <TitleComponents.MainTitle>{item.mainTitle}</TitleComponents.MainTitle>
                                <TitleComponents.Description>{item.description}</TitleComponents.Description>
                            </TitleComponents>
                            <Stack sx={{ mt: 5, mb: 8 }}>
                                <ImgSlickCard
                                    sliderArr={item.sliderArr}
                                    stackStyle={{ height: 212 }}
                                    imgStyle={{ width: "80%", height: "100%", objectFit: "contain" }}
                                    btnBgColor="transparent"
                                    sliderStyle={{ width: { md: 300, lg: 400 } }}></ImgSlickCard>
                            </Stack>
                        </Stack>
                    </ContainerLayout>
                </Fragment>
            ))}
            {/* SNS 버튼 */}
            <Stack spacing={2} sx={{ mb: 8, alignItems: "center" }}>
                <RoundedButton onClick={openArtistCafe} size="large" variant="contained" bgcolor="#3cb400">
                    네이버 카페 바로가기
                </RoundedButton>
                <ImuzmallLinkButton />
            </Stack>
        </>
    );
}

const artPlusArr = [
    {
        mainTitle: "큐레이터",
        subtitle: "작품 감상과 재해석",
        subtitleColor: "littleArtist.main",
        description: (
            <>
                <span>동·서양 미술 작품을 영상 자료를 통해 감상하고</span>
                <span>작품 속 이야기를 통해 작가와 작품 시대적 배경 등</span>
                <span>미술 작품에 흥미와 관심을 가지며</span>
                <span>미술 비평 활동을 통해 작품을 재해석하고 새로운 시각으로 재창조</span>
            </>
        ),
        sliderArr: [
            { imgSrc: "/images/littleartists/program/curator/큐레이터-1.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/curator/큐레이터-2.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/curator/큐레이터-3.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/curator/큐레이터-4.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/curator/큐레이터-5.jpg", backgroundColor: "constant.white" },
        ],
    },
    {
        mainTitle: "디자이너",
        subtitle: "디자인 감상과 표현",
        subtitleColor: "littleArtist.main",
        description: (
            <>
                <span>시각, 환경, 산업 분야의 디자인 된 현대 작품들을 감상하고,</span>
                <span>사용 목적에 따라 형태와 색상, 장식 등을 계획하여</span>
                <span>우리 생활에 도움을 주는 아름답고 쓸모 있는 디자인 표현</span>
            </>
        ),
        sliderArr: [
            {
                imgSrc: "/images/littleartists/program/designer/디자이너-01.jpg",
                backgroundColor: "constant.white",
            },
            { imgSrc: "/images/littleartists/program/designer/디자이너-02.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/designer/디자이너-03.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/designer/디자이너-04.jpg", backgroundColor: "constant.white" },
        ],
    },
    {
        mainTitle: "플레이어",
        subtitle: "창의적 조형 활동",
        subtitleColor: "littleArtist.main",
        description: (
            <>
                <span>한국사, 세계사, 과학, 쿠킹, 게임, 동화 등 다양한 주제를</span>
                <span>미술로 경험해 보며 영상, 워크시트, 전개도, 레시피, 게임지 등</span>
                <span>월별 특성에 따라 제공되는 자료를 이용해 놀이처럼 즐기는</span>
                <span>과정 중심의 창의적 조형 활동</span>
            </>
        ),
        sliderArr: [
            {
                imgSrc: "/images/littleartists/program/player/플레이어-01.jpg",
                backgroundColor: "constant.white",
            },
            { imgSrc: "/images/littleartists/program/player/플레이어-02.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/player/플레이어-03.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/player/플레이어-04.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/player/플레이어-05.jpg", backgroundColor: "constant.white" },
        ],
    },
];
