import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { Stack } from "@mui/material";

import theme from "../../styles/theme";
import RoundedButton from "../../components/elements/RoundedButton";
import LittleBandBrand from "../../components/brand/brand/LittleBandBrand";
import LittleArtistBrand from "../../components/brand/brand/LittleArtistBrand";
import CreativeLab from "../../components/brand/brand/CreativeLab";
import ContainerLayout from "../../components/layouts/ContainerLayout";

const MENUS = [
    { title: "어린음악대", color: theme.palette.littleBand.main },
    {
        title: "어린화가들",
        color: theme.palette.littleArtist.main,
    },
    { title: "창의연구소", color: "#fbc101" },
];
const DESC =
    "국내 유아동 예술 시장을 선도하는 주은교육㈜ 공식 홈페이지입니다. 스마트 아트톡톡, 스마트 드럼고고, 스마트 뮤직플레이, 스마트 피아노 프로그램을 통해 아이들의 음악, 미술 창의적 문제 해결력을 위한 디지털 에듀테크 솔루션을 제공합니다.";

export default function Brand() {
    const [selectedMenu, setSelectedMenu] = useState("어린음악대");

    return (
        <>
            <Helmet>
                <title>주은교육 브랜드 소개</title>
                <meta name="description" content="어린음악대, 어린화가들, 창의연구소 소개" />
                {/* 파비콘 */}
                <link rel="icon" type="image/x-icon" href="/favicon/jueun-edu/favicon-16x16.ico" />
                {/* OG 태그 */}
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="ko_KR" />
                <meta property="og:url" content="https://jueun-edu.com/brand" />
                <meta property="og:site_name" content="주은교육" />
                <meta property="og:title" content="주은교육 브랜드 소개" />
                <meta property="og:description" content={DESC} />
                <meta property="og:image" content="https://jueun-edu.com/images/og-jueun-edu.png" />
                {/* 트위터 OG 태그 */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="주은교육 브랜드 소개" />
                <meta name="twitter:description" content={DESC} />
                <meta name="twitter:image" content="https://jueun-edu.com/images/og-jueun-edu.png" />
            </Helmet>
            {/* 메인 desc */}
            <Stack>
                <ContainerLayout
                    sx={{
                        position: "sticky",
                        top: "-40px",
                        zIndex: 1,
                        pb: 1,
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        backdropFilter: "blur(30px)",
                    }}>
                    {/* 소메뉴 */}
                    <Stack flexDirection="row" justifyContent="center" sx={{ mt: "48px" }}>
                        {MENUS.map((menu, index) => {
                            return (
                                <RoundedButton
                                    key={index}
                                    onClick={() => {
                                        setSelectedMenu(menu.title);
                                        const ele = document.getElementById("content");
                                        ele.scrollIntoView({ behavior: "smooth" });
                                    }}
                                    variant={selectedMenu === menu.title ? "contained" : "outlined"}
                                    otherColor={menu.color}
                                    bgcolor={menu.color}
                                    otherHoverColor="white"
                                    stickyColor
                                    sx={{
                                        ml: index === 1 ? "8px" : 0,
                                        mr: index === 1 ? "8px" : 0,
                                    }}>
                                    {menu.title}
                                </RoundedButton>
                            );
                        })}
                    </Stack>
                </ContainerLayout>
                {/* 컨텐츠 */}

                <Stack id="content" sx={{ pt: 8 }}>
                    {selectedMenu === "어린음악대" && <LittleBandBrand />}
                    {selectedMenu === "어린화가들" && <LittleArtistBrand />}
                    {selectedMenu === "창의연구소" && <CreativeLab />}
                </Stack>
            </Stack>
        </>
    );
}
