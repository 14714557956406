import React from "react";
import { useRoutes } from "react-router-dom";

import { ThemeProvider } from "@mui/material";
import theme from "./styles/theme";
import GlobalStyles from "./styles/GlobalStyles";
import routes from "./routes";

function App() {
    const routing = useRoutes(routes);

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            {routing}
        </ThemeProvider>
    );
}

export default App;
