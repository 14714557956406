import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Stack, Grid } from "@mui/material";
import SouthIcon from "@mui/icons-material/South";
import theme from "../../styles/theme.js";

import NewsCard from "../cards/NewsCard";
import RoundedButton from "../elements/RoundedButton";
import gallery_data from "../data/gallery_data.js";

export default function Gallery({ brand }) {
    const [count, setCount] = useState(4);
    const navigate = useNavigate();
    const goToGalleryDetail = params => {
        navigate(`/notice/gallery/${params}`, {
            state: {
                brand: brand === "어린음악대" ? "littleband" : brand === "어린화가들" ? "littleartists" : "jueunedu",
                params: params,
                type: "갤러리",
            },
        });
    };
    const handleAddCount = () => {
        setCount(count + 3);
    };
    const DATA =
        brand === "어린음악대" ? gallery_data.music : brand === "어린화가들" ? gallery_data.art : gallery_data.jueun;
    return (
        <Stack>
            <Grid container spacing={2}>
                {DATA.filter((_, i) => i < count).map((info, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Grid item xs={12} md={6} xl={4} key={`${info.title}-${index}`}>
                        <NewsCard
                            // key={`${info.title}-${index}`}
                            date={info.date}
                            title={info.title}
                            thumbnail={info.thumbnail}
                            type={info.type}
                            onClick={() => goToGalleryDetail(info.params)}
                        />
                    </Grid>
                ))}
            </Grid>

            <Stack alignItems="center" sx={{ mt: "24px", mb: "64px" }}>
                {count <= DATA.length && (
                    <RoundedButton
                        size="large"
                        otherColor={theme.palette.littleBand.main}
                        otherHoverColor="white"
                        bgcolor={theme.palette.littleBand.main}
                        onClick={handleAddCount}
                        endIcon={<SouthIcon />}>
                        더보기
                    </RoundedButton>
                )}
            </Stack>
        </Stack>
    );
}
