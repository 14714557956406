import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { Stack } from "@mui/material";

import RoundedButton from "../../components/elements/RoundedButton";
import FindHakwon from "../../components/littleband/FindHakwon";
import Character from "../../components/littleartist/Character";
import { BrandIntroForArt } from "../../components/littleartist/BrandIntroForArt";
import ContainerLayout from "../../components/layouts/ContainerLayout";

const MENUS = [
    { title: "어린화가들" },
    {
        title: "캐릭터 소개",
    },
    { title: "학원 찾기" },
];
const DESC =
    "어린 예술가들이 꿈꾸는 행복한 미술을 지향하는 컬러풀 통합 미술 감성 놀이터 어린화가들! ‘어린화가들’의 공식 홈페이지입니다. 다양한 프로그램과 새로운 소식을 만나보세요.";

export default function LittleArists() {
    const [selectedMenu, setSelectedMenu] = useState("어린화가들");

    return (
        <>
            <Helmet>
                <title>어린화가들</title>
                <meta name="description" content={DESC} />
                {/* 파비콘 */}
                <link rel="icon" type="image/x-icon" href="/favicon/little-artist/favicon-16x16.ico" />
                {/* OG 태그 */}
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="ko_KR" />
                <meta property="og:url" content="https://ikidsart.co.kr/littleartists" />
                <meta property="og:site_name" content="어린화가들" />
                <meta property="og:title" content="어린화가들 소개" />
                <meta property="og:description" content={DESC} />
                <meta property="og:image" content="https://jueun-edu.com/images/og-jueun-edu.png" />
                {/* 트위터 OG 태그 */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="어린화가들 소개" />
                <meta name="twitter:description" content={DESC} />
                <meta name="twitter:image" content="https://jueun-edu.com/images/og-jueun-edu.png" />
            </Helmet>
            <Stack className="sticky-parent">
                {/* 소메뉴 */}
                <ContainerLayout
                    sx={{
                        position: "sticky",
                        top: -40,
                        zIndex: 11,
                        pb: 1,
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        backdropFilter: "blur(30px)",
                    }}>
                    <Stack flexDirection="row" justifyContent="center" sx={{ pt: "48px" }}>
                        {MENUS.map((menu, index) => {
                            return (
                                <RoundedButton
                                    key={index}
                                    onClick={() => {
                                        setSelectedMenu(menu.title);
                                        const ele = document.getElementById("content");
                                        ele.scrollIntoView({ behavior: "smooth" });
                                    }}
                                    variant={selectedMenu === menu.title ? "contained" : "outlined"}
                                    color="littleArtist.main"
                                    otherHoverColor="white"
                                    stickyColor
                                    sx={{
                                        ml: index === 1 ? "8px" : 0,
                                        mr: index === 1 ? "8px" : 0,
                                    }}>
                                    {menu.title}
                                </RoundedButton>
                            );
                        })}
                    </Stack>
                </ContainerLayout>
                {/* 컨텐츠 */}
                <Stack sx={{ pt: "18px" }} id="content">
                    {selectedMenu === "어린화가들" && <BrandIntroForArt />}
                    {selectedMenu === "캐릭터 소개" && <Character />}
                    {selectedMenu === "학원 찾기" && <FindHakwon />}
                </Stack>
            </Stack>
        </>
    );
}
