import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { Container, Stack } from "@mui/material";

import RoundedButton from "../../components/elements/RoundedButton";
import { BrandIntro } from "../../components/littleband/BrandIntro";
import BrandStory from "../../components/littleband/\bBrandStory";
import FindHakwon from "../../components/littleband/FindHakwon";

const MENUS = [
    { title: "어린음악대" },
    {
        title: "브랜드 스토리",
    },
    { title: "학원 찾기" },
];
const DESC =
    "즐기는 음악의 가치 No.1 브랜드 어린음악대! 블랜디드 음악 교육 프로그램을 제공하는 신개념 음악 체험 통합 놀이 교육. ‘어린음악대’의 공식 홈페이지입니다. 다양한 프로그램과 새로운 소식을 만나보세요.";

export default function Littleband() {
    const [selectedMenu, setSelectedMenu] = useState("어린음악대");

    return (
        <>
            <Helmet>
                <title>어린음악대</title>
                <meta name="description" content={DESC} />
                {/* 파비콘 */}
                <link rel="icon" type="image/x-icon" href="/favicon/little-band/favicon-16x16.ico" />
                {/* OG 태그 */}
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="ko_KR" />
                <meta property="og:url" content="https://ikidsband.com/littleband" />
                <meta property="og:site_name" content="어린음악대" />
                <meta property="og:title" content="어린음악대 소개" />
                <meta property="og:description" content={DESC} />
                <meta property="og:image" content="https://jueun-edu.com/images/og-jueun-edu.png" />
                {/* 트위터 OG 태그 */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="어린음악대 소개" />
                <meta name="twitter:description" content={DESC} />
                <meta name="twitter:image" content="https://jueun-edu.com/images/og-jueun-edu.png" />
            </Helmet>
            {/* 소메뉴 */}
            <Stack className="sticky-parent">
                <Stack
                    sx={{
                        position: "sticky",
                        top: -40,
                        zIndex: 11,
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        backdropFilter: "blur(30px)",
                    }}>
                    <Container
                        maxWidth="xl"
                        sx={{
                            pl: { xs: 0 },
                            pr: { xs: 0 },
                            pb: 1,
                        }}>
                        <Stack flexDirection="row" justifyContent="center" sx={{ pt: "48px" }}>
                            {MENUS.map((menu, index) => {
                                return (
                                    <RoundedButton
                                        key={index}
                                        onClick={() => {
                                            setSelectedMenu(menu.title);
                                            const ele = document.getElementById("content");
                                            ele.scrollIntoView({ behavior: "smooth" });
                                        }}
                                        variant={selectedMenu === menu.title ? "contained" : "outlined"}
                                        color="littleBand.main"
                                        otherHoverColor="white"
                                        stickyColor
                                        sx={{
                                            ml: index === 1 ? "8px" : 0,
                                            mr: index === 1 ? "8px" : 0,
                                        }}>
                                        {menu.title}
                                    </RoundedButton>
                                );
                            })}
                        </Stack>
                    </Container>
                </Stack>
                {/* 컨텐츠 */}
                <Stack sx={{ pt: "18px" }} id="content">
                    {selectedMenu === "어린음악대" && <BrandIntro />}
                    {selectedMenu === "브랜드 스토리" && <BrandStory />}
                    {selectedMenu === "학원 찾기" && <FindHakwon />}
                </Stack>
            </Stack>
        </>
    );
}
