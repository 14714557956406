import React from "react";

import { Stack, Typography } from "@mui/material";

import RoundedButton from "../components/elements/RoundedButton";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate();

    return (
        <Stack
            sx={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: `${window.innerHeight}px`,
            }}>
            <Stack
                sx={{
                    position: "relative",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 7,
                    width: "90%",
                    height: 325,
                    backgroundColor: "white",
                    border: "1px solid",
                    borderColor: "lightGray",
                    borderRadius: 8,
                }}>
                <Stack
                    sx={{
                        alignItems: "center",
                        position: "absolute",
                        top: 0,
                        zIndex: 10,
                        pt: 3,
                        backgroundColor: "white",
                        borderRadius: 8,
                    }}>
                    <Typography variant="h1" sx={{ textAlign: "center" }}>
                        404 ERROR
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 5, span: { display: "block" }, textAlign: "center" }}>
                        <span>죄송합니다. 페이지를 찾을 수 없습니다.</span>
                        <span>존재하지 않는 주소를 입력하셨거나, 요청하신 페이지의</span>
                        <span>주소가 변경/삭제되어 찾을 수 없습니다.</span>
                        &nbsp;
                        <span>감사합니다.</span>
                    </Typography>
                    <RoundedButton onClick={() => navigate("/")} sx={{ mt: 3.5 }} otherHoverColor="constant.white">
                        메인으로
                    </RoundedButton>
                </Stack>
                <img src="/images/404.svg" alt="404 image" style={{ position: "absolute", top: -110, zIndex: 2 }} />
            </Stack>
        </Stack>
    );
}
