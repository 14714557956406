import React from "react";

import { Stack, Typography } from "@mui/material";

export default function HeaderCard({
    cardStyle,
    headerTitle = "header title",
    headerBgColor = "constant.darkWhite",
    headerVariant = "h3",
    headerFontColor,
    contentBoxStyle,
    children,
}) {
    return (
        <Stack sx={{ ...cardStyle }}>
            {/* Header Title */}
            <Stack
                sx={{
                    py: 2,
                    justifyContent: "center",
                    alignItems: "center",
                    borderTop: "1px solid",
                    borderLeft: "1px solid",
                    borderRight: "1px solid",
                    borderRadius: "30px 30px 0 0",
                    borderColor: headerBgColor !== "constant.darkWhite" ? headerBgColor : "constant.lightGray",
                    backgroundColor: headerBgColor,
                }}>
                <Typography variant={headerVariant} sx={{ color: headerFontColor }}>
                    {headerTitle}
                </Typography>
            </Stack>
            {/* contents */}
            <Stack
                sx={{
                    borderLeft: "1px solid",
                    borderRight: "1px solid",
                    borderBottom: "1px solid",
                    borderColor: "#e5e5e5",
                    borderRadius: "0 0 32px 32px",
                    ...contentBoxStyle,
                }}>
                {children}
            </Stack>
        </Stack>
    );
}
