import { Stack, Typography } from "@mui/material";
import React from "react";

export default function PictogramCard({ title, children, imgUrl }) {
    return (
        <Stack
            position="relative"
            sx={{
                width: "100%",
                height: "207px",
                p: "19px 69px 120px 16px",
                borderRadius: "20px",
                bgcolor: "white",
            }}>
            {/* title */}
            <Stack flexDirection="row" alignItems="center">
                <img src="/images/brand/creativelab/checkImg.svg" style={{ width: "18px", height: "18px" }} />
                <Typography variant="subtitle1" sx={{ ml: 1 }}>
                    {title}
                </Typography>
            </Stack>
            {/* desc */}
            <Typography variant="body1" sx={{ p: "12px 69px  12px 24px" }}>
                {children}
            </Typography>
            {/* 픽토그램 */}
            <Stack
                position="absolute"
                sx={{
                    width:
                        title === "전문교구 개발"
                            ? { xs: "20%", sm: "15%", md: "20%" }
                            : { xs: "30%", sm: "20%", md: "25%" },
                    height: "90px",
                    top:
                        title === "전문교구 개발"
                            ? { xs: "115px", sm: "110px", md: "125px" }
                            : { xs: "110px", sm: "70px", md: "100px" },
                    right: "30px",
                }}>
                <img src={imgUrl} />
            </Stack>
        </Stack>
    );
}
