import React from "react";

import { Grid, Stack, Typography } from "@mui/material";
import { AddCircleRounded, KeyboardArrowDownOutlined } from "@mui/icons-material";

import { TitleComponents } from "../../titleComponent/TitleComponents";
import HeaderCard from "../../cards/HeaderCard";
import ContainerLayout from "../../layouts/ContainerLayout";

export default function PianoGogoIntro() {
    return (
        <ContainerLayout bgColor="constant.darkWhite" sx={{ py: 8 }}>
            <TitleComponents sx={{ mt: 6, mb: 5.5 }}>
                <TitleComponents.Subtitle typoStyle={{ color: "littleBand.pianogogo" }}>
                    블렌디드 뮤직플로우 피아노 교육!
                </TitleComponents.Subtitle>
                <TitleComponents.MainTitle>피아노고고란?</TitleComponents.MainTitle>
                <TitleComponents.Description>
                    <span>어린음악대의 독창적인 독점 음악 교구를 활용한</span>
                    <span>자기주도적 몰입(FLOW)과정을 통해 두뇌를 열어주는</span>
                    <span>통합형 레슨 프로그램입니다</span>
                </TitleComponents.Description>
            </TitleComponents>
            <Stack>
                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                    <HeaderCard
                        headerTitle="온라인 교육"
                        headerBgColor="littleBand.pianogogo"
                        headerFontColor="constant.white"
                        cardStyle={{ width: "100%" }}
                        contentBoxStyle={{ px: 2, py: 2, backgroundColor: "constant.white" }}>
                        <Typography sx={{ textAlign: "center", span: { display: "block" } }}>
                            <span>보고, 듣고, 느끼는</span>
                            <span>오감체험 프로그램</span>
                        </Typography>
                    </HeaderCard>
                    <AddCircleRounded fontSize="large" sx={{ color: "littleBand.pianogogo" }} />
                    <HeaderCard
                        headerTitle="오프라인 교육"
                        headerBgColor="littleBand.pianogogo"
                        headerFontColor="constant.white"
                        cardStyle={{ width: "100%" }}
                        contentBoxStyle={{ px: 2, py: 2, backgroundColor: "constant.white" }}>
                        <Typography sx={{ textAlign: "center", span: { display: { xs: "inline", md: "block" } } }}>
                            <span>독점 교재/특허 전문 교구 수업,</span>
                            <span> 리듬악기 연주</span>
                        </Typography>
                    </HeaderCard>
                </Stack>
                <KeyboardArrowDownOutlined fontSize="large" sx={{ mx: "auto", color: "littleBand.pianogogo" }} />
                <Stack sx={{ mb: 5.5, py: 1, backgroundColor: "littleBand.pianogogo", borderRadius: 8 }}>
                    <Typography variant="h3" sx={{ textAlign: "center", color: "constant.white" }}>
                        스마트 음악교육!
                    </Typography>
                </Stack>
                <Grid container spacing={2}>
                    {imageArr.map(item => (
                        <Grid key={item.alt} item xs={6} md={3}>
                            <div style={{ width: "100%", paddingTop: "100%", position: "relative" }}>
                                <img
                                    src={item.imgSrc}
                                    alt={item.alt}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "56px",
                                        objectFit: "cover",
                                    }}
                                />
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        </ContainerLayout>
    );
}

const imageArr = [
    { imgSrc: "/images/littleband/piano-gogo/picture-1.jpg", alt: "picture-1.jpg" },
    { imgSrc: "/images/littleband/piano-gogo/picture-2.jpg", alt: "picture-2.jpg" },
    { imgSrc: "/images/littleband/piano-gogo/picture-3.jpg", alt: "picture-3.jpg" },
    { imgSrc: "/images/littleband/piano-gogo/picture-4.jpg", alt: "picture-4.jpg" },
];
