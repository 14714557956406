import React, { useEffect, useRef, useState } from "react";

import { IconButton, Stack, Typography } from "@mui/material";
import { ArrowForwardRounded } from "@mui/icons-material";

export default function NewsCard({ date, title, thumbnail, type, onClick }) {
    const imgRef = useRef(null);
    const [titleState, setTitleState] = useState(title);
    const [titleWidth, setTitleWidth] = useState(0);

    const news = ["뉴스", "news", "공지", "소식"].includes(type);

    // button width + padding right + 주고 싶은 title padding
    const titlePadding = 64 + 12 + 36;

    // title width 설정
    useEffect(() => {
        if (title.length >= 40) {
            setTitleState(title.slice(0, 40) + "...");
        }
        if (imgRef.current) {
            setTitleWidth(imgRef.current.clientWidth);
        }
    }, [title]);

    // resize 시 title width 변경
    useEffect(() => {
        const handleResize = () => {
            if (imgRef.current) {
                setTitleWidth(imgRef.current.clientWidth);
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <Stack
            ref={imgRef}
            sx={{
                m: 2,
                position: "relative",
                height: 320,
                borderRadius: "30px",
                backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(${thumbnail})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "top",
                backgroundColor: news ? "littleArtist.main" : "primary.light",
            }}
            onClick={onClick}>
            {/* Category Title */}
            {news && (
                <Stack
                    sx={{
                        position: "absolute",
                        pb: 1.5,
                        pr: 1.5,
                        backgroundColor: "constant.white",
                        borderRadius: "0 0 30px 0",
                    }}>
                    <Stack sx={{ width: 80, py: 1, backgroundColor: "constant.lightGray", borderRadius: 7 }}>
                        <Typography variant="body1" sx={{ textAlign: "center" }}>
                            {type}
                        </Typography>
                    </Stack>
                </Stack>
            )}
            {/* Description */}
            <Stack
                sx={{ position: "absolute", bottom: 18, left: 24, width: titleWidth - titlePadding, cursor: "pointer" }}
                onClick={onClick}>
                <Typography variant="caption" sx={{ fontWeight: 500, color: "#f5f5f5" }}>
                    {date}
                </Typography>
                <Typography variant="subtitle2" sx={{ color: "white" }}>
                    {titleState}
                </Typography>
            </Stack>
            {/* Button */}
            <Stack
                sx={{
                    position: "absolute",
                    borderRadius: "50% 0 0 0",
                    pt: 1.5,
                    pl: 1.5,
                    bottom: 0,
                    right: 0,
                    backgroundColor: "constant.white",
                }}>
                <IconButton
                    onClick={onClick}
                    sx={{
                        width: 64,
                        height: 64,
                        borderRadius: "50%",
                        backgroundColor: "littleBand.main",
                        color: "white",
                        overflow: "hidden",
                        ":hover": {
                            backgroundColor: "littleBand.main",
                        },
                    }}>
                    <ArrowForwardRounded fontSize="large" />
                </IconButton>
            </Stack>
            {/* border */}
            {news ? (
                <>
                    <img
                        src="/images/card/border/border-right.svg"
                        style={{ position: "absolute", top: 49 }}
                        alt="border-right"
                    />
                    <img
                        src="/images/card/border/border-right.svg"
                        style={{ position: "absolute", top: 0, left: 92 }}
                        alt="border-right"
                    />
                    <img
                        src="/images/card/border/border-left.svg"
                        style={{ position: "absolute", bottom: 76, right: 0 }}
                        alt="border-right"
                    />
                    <img
                        src="/images/card/border/border-left.svg"
                        style={{ position: "absolute", right: 76, bottom: 0 }}
                        alt="border-right"
                    />
                </>
            ) : (
                <>
                    <img
                        src="/images/card/border/border-left.svg"
                        style={{ position: "absolute", bottom: 76, right: 0 }}
                        alt="border-right"
                    />
                    <img
                        src="/images/card/border/border-left.svg"
                        style={{ position: "absolute", right: 76, bottom: 0 }}
                        alt="border-right"
                    />
                </>
            )}
        </Stack>
    );
}
