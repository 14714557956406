import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { Stack } from "@mui/material";

import RoundedButton from "../../components/elements/RoundedButton";
import ProgramTab from "../../components/littleband/program/ProgramTab";
import BookTab from "../../components/littleband/program/BookTab";
import ToolTab from "../../components/littleband/program/ToolTab";
import ContainerLayout from "../../components/layouts/ContainerLayout";
import { TabContext } from "../../components/layouts/HomePageLayout";

const MENUS = [
    {
        title: "프로그램",
        description: (
            <>
                <span>어린음악대만의</span>
                <span>독창적인</span>
                <span>음악 교육 프로그램</span>
            </>
        ),
    },
    {
        title: "교재",
        description: (
            <>
                <span>두뇌밸런스 음악교육</span>
                <span>어린음악대</span>
                <span>전용 교재</span>
            </>
        ),
    },
    {
        title: "교구",
        description: (
            <>
                <span>특허인증 음악교구</span>
                <span>어린음악대</span>
                <span>전용 교구</span>
            </>
        ),
    },
];
const DESC =
    "즐기는 음악의 가치 No.1 브랜드 어린음악대! 블랜디드 음악 교육 프로그램을 제공하는 신개념 음악 체험 통합 놀이 교육. ‘어린음악대’의 공식 홈페이지입니다. 다양한 프로그램과 새로운 소식을 만나보세요.";

export default function BandProgram() {
    const [selectedMenu, setSelectedMenu] = useState("프로그램");
    const { setCurrentTab } = useContext(TabContext);

    useEffect(() => {
        setCurrentTab("프로그램");
    }, [setCurrentTab]);

    return (
        <>
            <Helmet>
                <title>어린음악대 프로그램 소개</title>
                <meta name="description" content={DESC} />
                {/* 파비콘 */}
                <link rel="icon" type="image/x-icon" href="/favicon/little-band/favicon-16x16.ico" />
                {/* OG 태그 */}
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="ko_KR" />
                <meta property="og:url" content="https://ikidsband.com/littleband/program" />
                <meta property="og:site_name" content="어린음악대" />
                <meta property="og:title" content="어린음악대 프로그램 소개" />
                <meta property="og:description" content={DESC} />
                <meta property="og:image" content="https://jueun-edu.com/images/og-jueun-edu.png" />
                {/* 트위터 OG 태그 */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="어린음악대 브랜드 소개" />
                <meta name="twitter:description" content={DESC} />
                <meta name="twitter:image" content="https://jueun-edu.com/images/og-jueun-edu.png" />
            </Helmet>
            <Stack>
                {/* 소메뉴 */}
                <ContainerLayout
                    sx={{
                        position: "sticky",
                        top: -40,
                        zIndex: 11,
                        pb: 1,
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        backdropFilter: "blur(30px)",
                    }}>
                    <Stack flexDirection="row" justifyContent="center" sx={{ pt: "48px" }}>
                        {MENUS.map((menu, index) => {
                            return (
                                <RoundedButton
                                    key={index}
                                    onClick={() => {
                                        setSelectedMenu(menu.title);
                                        setCurrentTab(menu.title);
                                        window.scrollTo({ top: 0, behavior: "smooth" });
                                    }}
                                    variant={selectedMenu === menu.title ? "contained" : "outlined"}
                                    otherHoverColor="constant.white"
                                    color="littleBand.main"
                                    stickyColor
                                    sx={{
                                        ml: index === 1 ? "8px" : 0,
                                        mr: index === 1 ? "8px" : 0,
                                    }}>
                                    {menu.title}
                                </RoundedButton>
                            );
                        })}
                    </Stack>
                </ContainerLayout>
                {/* 컨텐츠 */}
                <Stack sx={{ pt: "18px" }}>
                    {selectedMenu === "프로그램" && <ProgramTab />}
                    {selectedMenu === "교재" && <BookTab />}
                    {selectedMenu === "교구" && <ToolTab />}
                </Stack>
            </Stack>
        </>
    );
}
