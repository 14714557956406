import React from "react";
import { Outlet } from "react-router-dom";

import { Container, Stack, Typography } from "@mui/material";
import { ArrowDownwardRounded } from "@mui/icons-material";

import RoundedButton from "../../components/elements/RoundedButton";
import Footer from "../../components/layouts/Footer";
import { TitleComponents } from "../../components/titleComponent/TitleComponents";
import ImgHeaderCard from "../../components/cards/ImgHeaderCard";
import ImgTabCard from "../../components/cards/ImgTabCard";
// import NewsCard from "../../components/cards/NewsCard";
// import ImgSlickCard from "../../components/cards/ImgSlickCard";
import ImgCard from "../../components/cards/ImgCard";
// import { TrapezoidCard } from "../../components/cards/TrapezoidCard";
// import { TrapezoidCard2 } from "../../components/cards/TrapezoidCard2";

export default function ComponentTest() {
    const tabList = [
        {
            tabTitle: "피아노고고",
            tabImg: "",
            tabDescription: (
                <>
                    <Typography variant="h3" sx={{ mb: 1 }}>
                        좌뇌발달 "피아노고고"
                    </Typography>
                    <Typography variant="subtitle3" sx={{ fontWeight: "bold" }}>
                        전인발달을 돕는 음악 몰입 피아노 레슨교육!
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 0.5, span: { display: "block" } }}>
                        <span>어린음악대만의 독창적인 독점 음악 교구를 활용한</span>
                        <span>자기주도적 몰입(FLOW)과정을 통해 두뇌를</span>
                        <span>열어주는 통합형 레슨 프로그램</span>
                    </Typography>
                </>
            ),
            activeBgColor: "littleBand.pianogogo",
        },
        {
            tabTitle: "뮤직고고",
            tabImg: "",
            tabDescription: <Typography>우뇌발달 "뮤직고고"</Typography>,
            activeBgColor: "littleBand.musicgogo",
        },
    ];

    return (
        <main>
            <div>
                <Container maxWidth="lg">
                    <RoundedButton variant="contained" color="littleBand.main">
                        프로그램
                    </RoundedButton>
                    <RoundedButton variant="outlined" color="littleArtist.main">
                        교재
                    </RoundedButton>
                    <RoundedButton variant="outlined" color="littleBand.main">
                        교구
                    </RoundedButton>
                    <Stack direction="column">
                        <RoundedButton color="littleBand.main" size="large">
                            더 알아보기
                        </RoundedButton>
                        <RoundedButton
                            color="littleBand.main"
                            size="large"
                            endIcon={<ArrowDownwardRounded fontSize="small" />}>
                            더 보기
                        </RoundedButton>
                        <RoundedButton
                            color="littleBand.main"
                            size="large"
                            startIcon={
                                <img
                                    src="/images/imuzemall/imuzmall-button-icon.svg"
                                    alt="imuzmall icon"
                                    style={{ marginBottom: 4, marginRight: 4 }}
                                />
                            }>
                            바로가기
                        </RoundedButton>
                    </Stack>
                    <Stack>
                        <TitleComponents>
                            <TitleComponents.Subtitle typoStyle={{ color: "littleBand.main" }}>
                                어린 예술가들의 감성 놀이터
                            </TitleComponents.Subtitle>
                            <TitleComponents.MainTitle>
                                <span>즐기는 음악의 가치</span>
                                <span>NO.1 브랜드</span>
                                <span>어린음악대</span>
                            </TitleComponents.MainTitle>
                            <TitleComponents.Description>
                                <span>우리 아이에게 필요한</span>
                                <span>올바른 교육은 무엇일까요?</span>
                            </TitleComponents.Description>
                        </TitleComponents>
                        <TitleComponents sx={{ alignItems: "center" }}>
                            <TitleComponents.MainTitle
                                stackStyle={{ textAlign: "center" }}
                                typoStyle={{ color: "littleBand.main" }}>
                                <span>우리들은</span>
                                <span>어린음악대!</span>
                            </TitleComponents.MainTitle>
                            <TitleComponents.Description stackStyle={{ textAlign: "center" }}>
                                <span>악보대로만 연주하는 것이 올바른 음악교육일까요?</span>
                                <span>악기 연주만으로 음악적 감성이 풍부해질까요?</span>
                            </TitleComponents.Description>
                        </TitleComponents>
                        <TitleComponents>
                            <TitleComponents.MainTitle size="small">
                                <span>피아노학원 어린음악대, 블렌디드</span>
                                <span>음악 수업 The all new 뮤직고고</span>
                                <span>'러너' 런칭</span>
                            </TitleComponents.MainTitle>
                        </TitleComponents>
                    </Stack>
                    <Stack>
                        <ImgHeaderCard></ImgHeaderCard>
                        <ImgHeaderCard headerTitle="레슨교재">
                            <span>리듬, 교구, 음악이론 교재와 연계되어</span>
                            <span>차별화 된 피아노 수업이 가능합니다</span>
                        </ImgHeaderCard>

                        <ImgTabCard tabList={tabList}></ImgTabCard>
                    </Stack>
                    {/* <Stack>
                        <NewsCard news></NewsCard>
                    </Stack> */}
                    {/* <Stack>
                        <ImgSlickCard />
                    </Stack> */}
                    <Stack>
                        <ImgCard />
                    </Stack>
                    {/* <Stack flexDirection="row">
                        <TrapezoidCard />
                        <TrapezoidCard2 />
                    </Stack> */}
                    <Outlet />
                </Container>
                <Footer />
            </div>
        </main>
    );
}
