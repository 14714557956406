// MUI stuff
import { createStyles } from "@mui/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        "@global": {
            "*": {
                boxSizing: "border-box",
                margin: 0,
                padding: 0,
            },
            html: {
                "-webkit-font-smoothing": "antialiased",
                "-moz-osx-font-smoothing": "grayscale",
                height: "100%",
                width: "100%",
            },
            body: {
                backgroundColor: "#ffffff",
                height: "100%",
                width: "100%",
                "&::-webkit-scrollbar": {
                    display: "none",
                },
            },
            a: {
                textDecoration: "none",
            },
            "#root": {
                height: "100%",
                width: "100%",
            },
            img: {
                maxWidth: "100%",
                height: "auto",
            },
        },
    }),
);

const GlobalStyles = () => {
    useStyles();

    return null;
};

export default GlobalStyles;
