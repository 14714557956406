import React from "react";

import { Container, Stack } from "@mui/material";

export default function ContainerLayout({ bgColor = "constant.white", bgUrl, sx, children }) {
    return (
        <Stack
            sx={{
                bgcolor: bgColor,
                ...sx,
                backgroundImage: `url(${bgUrl})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}>
            <Container maxWidth="xl">{children}</Container>
        </Stack>
    );
}
