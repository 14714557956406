import React from "react";
import { TitleComponents } from "../titleComponent/TitleComponents";
import { Stack } from "@mui/material";

const IMG_COUNT = 31;
const ImgUrl = Array.from(
    { length: IMG_COUNT },
    (_, index) => `/images/littleband/brandstory/brandstory${index + 1}.webp`,
);

export default function BrandStory() {
    return (
        <Stack sx={{ p: "64px 16px" }}>
            {/* 타이틀 */}
            <Stack sx={{ mb: "44px" }}>
                <TitleComponents sx={{ alignItems: "center" }}>
                    <TitleComponents.MainTitle
                        stackStyle={{ textAlign: "center" }}
                        typoStyle={{ color: "text.primary" }}>
                        <span>브랜드 스토리</span>
                    </TitleComponents.MainTitle>
                    <TitleComponents.Description stackStyle={{ textAlign: "center" }}>
                        <span>어린음악대의 시작 찍찍이 교구 탄생 이야기</span>
                    </TitleComponents.Description>
                </TitleComponents>
            </Stack>
            {/* 이미지 */}
            <Stack justifyContent="center" alignItems={{ xs: "center" }}>
                {ImgUrl.map((url, index) => {
                    return (
                        <Stack key={index} sx={{ mb: "16px", width: { lg: "400px" } }}>
                            <img src={url} />
                        </Stack>
                    );
                })}
            </Stack>
        </Stack>
    );
}
