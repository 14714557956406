import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Container, Stack } from "@mui/material";

import RoundedButton from "../../components/elements/RoundedButton";
import News from "../../components/littleband/News";
import Gallery from "../../components/littleband/Gallery";
import ContainerLayout from "../../components/layouts/ContainerLayout";

const MENUS = [
    { title: "소식&뉴스" },
    {
        title: "갤러리",
    },
];

export default function Notice() {
    const location = useLocation();
    const backToGalleryTab = location.state?.gallerydetail;
    const [selectedMenu, setSelectedMenu] = useState(backToGalleryTab ? "갤러리" : "소식&뉴스");
    const getBrandName = window.location.pathname.split("/")[1];
    const brandName =
        getBrandName === "littleband" ? "어린음악대" : getBrandName === "littleartists" ? "어린화가들" : "주은교육";

    return (
        <>
            <Helmet>
                <title>{`${brandName} 공지`}</title>
                <meta name="description" content={`${brandName} 공지 뉴스&소식, 갤러리 페이지`} />
                {/* 파비콘 */}
                <link rel="icon" type="image/x-icon" href="/favicon/jueun-edu/favicon-16x16.ico" />
                {/* OG 태그 */}
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="ko_KR" />
                <meta property="og:url" content="https://jueun-edu.com/notice" />
                <meta property="og:site_name" content={`${brandName} 공지`} />
                <meta property="og:title" content={`${brandName} 공지`} />
                <meta property="og:description" content={`${brandName} 공지 뉴스&소식, 갤러리 페이지`} />
                <meta property="og:image" content="https://jueun-edu.com/images/og-jueun-edu.png" />
                {/* 트위터 OG 태그 */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`${brandName} 공지`} />
                <meta name="twitter:description" content={`${brandName} 공지 뉴스&소식, 갤러리 페이지`} />
                <meta name="twitter:image" content="https://jueun-edu.com/images/og-jueun-edu.png" />
            </Helmet>
            <Stack>
                <ContainerLayout
                    sx={{
                        position: "sticky",
                        top: -40,
                        zIndex: 11,
                        pl: { xs: 0 },
                        pr: { xs: 0 },
                        pb: 1,
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        backdropFilter: "blur(30px)",
                    }}>
                    {/* 소메뉴 */}
                    <Stack flexDirection="row" justifyContent="center" alignItems="center" sx={{ mt: "48px" }}>
                        {MENUS.map((menu, index) => {
                            return (
                                <RoundedButton
                                    key={index}
                                    onClick={() => {
                                        setSelectedMenu(menu.title);
                                        const ele = document.getElementById("content");
                                        ele.scrollIntoView({ behavior: "smooth" });
                                    }}
                                    variant={selectedMenu === menu.title ? "contained" : "outlined"}
                                    color={
                                        getBrandName === "littleband"
                                            ? "littleBand.main"
                                            : getBrandName === "littleartists"
                                              ? "littleArtist.main"
                                              : "primary.main"
                                    }
                                    otherHoverColor="white"
                                    stickyColor
                                    sx={{
                                        ml: index === 1 ? "8px" : 0,
                                        mr: index === 1 ? "8px" : 0,
                                    }}>
                                    {menu.title}
                                </RoundedButton>
                            );
                        })}
                    </Stack>
                </ContainerLayout>
                {/* 컨텐츠 */}
                <Container id="content" maxWidth="xl" sx={{ pt: 8, pl: { xs: 0 }, pr: { xs: 0 } }}>
                    {selectedMenu === "소식&뉴스" && <News brand={brandName} />}
                    {selectedMenu === "갤러리" && <Gallery brand={brandName} />}
                </Container>
            </Stack>
        </>
    );
}
