import React from "react";
import theme from "../../styles/theme";
import { Divider, Stack } from "@mui/material";
import { TitleComponents } from "../titleComponent/TitleComponents";
import { TrapezoidCard } from "../cards/TrapezoidCard";
import ImgCard from "../cards/ImgCard";
import ImgSlickCard from "../cards/ImgSlickCard";
import ContainerLayout from "../layouts/ContainerLayout";
import ImuzmallLinkButton from "../elements/ImuzmallLinkButton";

function BrandIntro1() {
    return (
        <ContainerLayout>
            <Stack sx={{ alignItems: "center", p: "64px 0" }}>
                <TitleComponents sx={{ alignItems: "center" }}>
                    <TitleComponents.MainTitle
                        stackStyle={{ textAlign: "center" }}
                        typoStyle={{ color: "littleArtist.main" }}>
                        <span>우리들은</span>
                        <span>어린화가들!</span>
                    </TitleComponents.MainTitle>
                    <TitleComponents.Description stackStyle={{ textAlign: "center" }}>
                        <span>똑같은 것을 보고 똑같이 그리는 것이</span>
                        <span>과연 올바른 미술 교육일까요?</span>
                    </TitleComponents.Description>
                </TitleComponents>
                <Stack sx={{ mt: 5.5, width: { xs: "100%", md: "60%" } }}>
                    <LittleArtistWithlogo />
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}

export function LittleArtistWithlogo() {
    return (
        <ImgCard
            height="360px"
            descFontSize="18px"
            bodyColor={theme.palette.littleArtist.medium}
            smallImgSrc="/images/littleartists/littleArtist_logo.svg"
            smallImgWidth="243px"
            smallImgTop={{ xs: "26px", md: "60px" }}
            smallImgWidthForMd="350px">
            <span>예술교육의 리더 주은교육(주)의 경영노하우를 결합하여 한국아동예술창의연구소의 연구진의</span>
            <span> 검증된 과정을 통해 만들어 낸 컬러풀 통합미술 교육 프랜차이즈입니다</span>
        </ImgCard>
    );
}

function BrandIntro2() {
    return (
        <ContainerLayout bgColor="constant.darkWhite">
            <Stack sx={{ p: "64px 0px" }}>
                <TitleComponents>
                    <TitleComponents.MainTitle>
                        <span>특별한 예술가는</span>
                        <span>어린화가들에서</span>
                        <span>시작됩니다</span>
                    </TitleComponents.MainTitle>
                </TitleComponents>
                <Stack sx={{ width: "100%", mt: "44px" }}>
                    <Stack justifyContent="center" flexDirection={{ xs: "row" }} sx={{ flexWrap: "wrap" }}>
                        <TrapezoidCard
                            number={1}
                            title="독자적인"
                            coloredTitle2="창의 미술"
                            title2="커리큘럼"
                            bodyColor={theme.palette.littleArtist.medium}
                            brandName="littleArtist">
                            <span>매월 한국아동예술창의</span>
                            <span> 연구소는 전문 연구인력들의 </span>
                            <span> 노력으로 탄생되는 체계적인</span>
                            <span>미술 커리큘럼을 제시합니다.</span>
                        </TrapezoidCard>
                        <TrapezoidCard
                            number={2}
                            coloredTitle="블랜디드"
                            title="미술 교육"
                            title2="프로그램"
                            bodyColor={theme.palette.littleArtist.light}
                            brandName="littleArtist">
                            <span>미술학원도 스마트다!</span>
                            <span> 다채로운 경험을 위한 온라인</span>
                            <span> 미술교육을 지향합니다.</span>
                        </TrapezoidCard>
                        <TrapezoidCard
                            number={3}
                            title="체계적인"
                            coloredTitle2="학원 관리"
                            title2="시스템"
                            bodyColor={theme.palette.littleArtist.light}
                            brandName="littleArtist">
                            <span>최적의 학원관리 시스템을</span>
                            <span>도입하여 아이들의 미술교육에 있어 학부모님과 원생</span>
                            <span> 모두에게 만족을 드립니다.</span>
                        </TrapezoidCard>
                        <TrapezoidCard
                            number={4}
                            title="차별화된"
                            coloredTitle2="학원 경영"
                            title2="노하우"
                            bodyColor={theme.palette.littleArtist.medium}
                            brandName="littleArtist">
                            <span>온오프라인 융합</span>
                            <span>수업에 차별화 된 콘텐츠를</span>
                            <span> 바탕으로 한 최적의 학원경영 특급 노하우를 제공합니다</span>
                        </TrapezoidCard>
                    </Stack>
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}

export function BrandIntro3() {
    return (
        <ContainerLayout>
            <Stack sx={{ p: "64px 0px" }}>
                <TitleComponents.MainTitle
                    stackStyle={{ textAlign: "center" }}
                    typoStyle={{ color: "littleArtist.main" }}>
                    <span>컬러풀 미술교육의</span>
                    <span>차이를 느껴보세요</span>
                </TitleComponents.MainTitle>
                <Stack sx={{ mt: "44px", width: { md: "70%" }, mx: { md: "auto" } }}>
                    <ImgCard
                        smallImgSrc="/images/littleartists/brandIntro/creativitylab.webp"
                        smallImgWidth="304px"
                        smallImgTop="32px">
                        두뇌를 깨우는 힘을 길러주는 것이 중요합니다
                    </ImgCard>
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}

export function BrandIntro4({ cardStyle }) {
    return (
        <ContainerLayout>
            <Stack
                justifyContent={{ md: "space-between" }}
                flexDirection={{ xs: "column", md: "row" }}
                sx={{ p: "64px 0px" }}>
                <Stack sx={{ pt: { md: 5 } }}>
                    <TitleComponents>
                        <TitleComponents.Subtitle
                            stackStyle={{ textAlign: "start" }}
                            typoStyle={{ color: "littleArtist.main" }}>
                            체계적인 미술교육 커리큘럼
                        </TitleComponents.Subtitle>
                        <TitleComponents.MainTitle stackStyle={{ textAlign: "start" }}>
                            <span>단계별</span>
                            <span>연령별</span>
                            <span>맞춤수업</span>
                        </TitleComponents.MainTitle>
                        <TitleComponents.Description stackStyle={{ textAlign: "start" }}>
                            <span>아이의 성장과정에 맞춘 수업</span>
                            <span>커리큘럼 프로그램을 매월 제공</span>
                        </TitleComponents.Description>
                    </TitleComponents>
                </Stack>

                <Stack sx={{ mt: "44px", width: { md: "60%" } }}>
                    {cardStyle === "slickCard" ? (
                        <ImgSlickCard
                            sliderArr={[
                                { imgSrc: "/images/littleband/brandIntro/전문음악교구.webp" },
                                { imgSrc: "/images/littleband/brandIntro/스마트뮤직클래스.webp" },
                                { imgSrc: "/images/littleband/brandIntro/심층레슨.webp" },
                            ]}></ImgSlickCard>
                    ) : (
                        <img src="/images/jueun-edu/Step-by-Step.webp" />
                    )}
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}

export function BrandIntro5() {
    return (
        <ContainerLayout>
            <Stack
                flexDirection={{ md: "row-reverse" }}
                justifyContent={{ md: "space-between" }}
                sx={{ p: "64px 0px" }}>
                <Stack sx={{ pt: { md: 5 } }}>
                    <TitleComponents>
                        <TitleComponents.Subtitle
                            stackStyle={{ textAlign: "end" }}
                            typoStyle={{ color: "littleArtist.main" }}>
                            어린화가들에서 뻗어나가는
                        </TitleComponents.Subtitle>
                        <TitleComponents.MainTitle stackStyle={{ textAlign: "end" }}>
                            <span>교과연계</span>
                            <span>가정연계</span>
                            <span>미술교육</span>
                        </TitleComponents.MainTitle>
                        <TitleComponents.Description stackStyle={{ textAlign: "end" }}>
                            <span>누리과정, 초등교과과정 연계하며</span>
                            <span>생활에 필요한 다양한 영역발달 도모</span>
                        </TitleComponents.Description>
                    </TitleComponents>
                </Stack>

                <Stack sx={{ mt: "44px", width: { md: "60%" } }}>
                    <ImgSlickCard
                        sliderArr={[
                            { imgSrc: "/images/littleartists/brandIntro/교과연계/교과01.jpg" },
                            { imgSrc: "/images/littleartists/brandIntro/교과연계/교과02.png" },
                            { imgSrc: "/images/littleartists/brandIntro/교과연계/교과03.jpg" },
                        ]}
                        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
                    />
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}

export function BrandIntro6() {
    return (
        <ContainerLayout bgColor="constant.darkWhite">
            <Stack flexDirection={{ md: "row" }} justifyContent={{ md: "space-between" }} sx={{ p: "64px 0px" }}>
                <Stack sx={{ mt: { md: 5 } }}>
                    <TitleComponents>
                        <TitleComponents.Subtitle typoStyle={{ color: "littleArtist.main" }}>
                            표현하고 싶은 것이 있다면
                        </TitleComponents.Subtitle>
                        <TitleComponents.MainTitle>
                            <span>미술의</span>
                            <span>기본개념</span>
                            <span>확립</span>
                        </TitleComponents.MainTitle>
                        <TitleComponents.Description>
                            <span>표현하고 싶은 것을 마음껏 펼칠 수 </span>
                            <span>있도록 기본기를 탄탄히 하는 수업</span>
                        </TitleComponents.Description>
                    </TitleComponents>
                </Stack>

                <Stack sx={{ mt: "44px", width: { md: "60%" } }}>
                    <ImgSlickCard
                        sliderArr={[
                            { imgSrc: "/images/littleartists/brandIntro/기본개념/개념01.jpg" },
                            { imgSrc: "/images/littleartists/brandIntro/기본개념/개념02.jpg" },
                            { imgSrc: "/images/littleartists/brandIntro/기본개념/개념03.jpg" },
                        ]}
                        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
                    />
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}

export function BrandIntro7() {
    return (
        <ContainerLayout>
            <Stack
                flexDirection={{ md: "row-reverse" }}
                justifyContent={{ md: "space-between" }}
                sx={{ p: "64px 0px" }}>
                <Stack sx={{ pt: { md: 5 } }}>
                    <TitleComponents>
                        <TitleComponents.Subtitle
                            stackStyle={{ textAlign: "end" }}
                            typoStyle={{ color: "littleArtist.main" }}>
                            융합 미디어 자료와 함께하는
                        </TitleComponents.Subtitle>
                        <TitleComponents.MainTitle stackStyle={{ textAlign: "end" }}>
                            <span>오감만족</span>
                            <span>융합발달</span>
                            <span>감성교육</span>
                        </TitleComponents.MainTitle>
                        <TitleComponents.Description stackStyle={{ textAlign: "end" }}>
                            <span>따라하는 미술이 아닌 미디어 자료와</span>
                            <span>함께 상상력을 키우는 교육</span>
                        </TitleComponents.Description>
                    </TitleComponents>
                </Stack>

                <Stack sx={{ mt: "44px", width: { md: "60%" } }}>
                    <ImgSlickCard
                        sliderArr={[
                            { imgSrc: "/images/littleartists/brandIntro/오감만족/오감01.jpg" },
                            { imgSrc: "/images/littleartists/brandIntro/오감만족/오감02.jpg" },
                            { imgSrc: "/images/littleartists/brandIntro/오감만족/오감03.jpg" },
                            { imgSrc: "/images/littleartists/brandIntro/오감만족/오감04.jpg" },
                            { imgSrc: "/images/littleartists/brandIntro/오감만족/오감05.jpg" },
                        ]}
                        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
                    />
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}

function BrandIntro8() {
    return (
        <ContainerLayout bgColor="constant.darkWhite">
            <Stack sx={{ p: "64px 0px" }}>
                <TitleComponents sx={{ alignItems: "center" }}>
                    <TitleComponents.MainTitle
                        stackStyle={{ textAlign: "center" }}
                        typoStyle={{ color: "text.primary" }}>
                        <span>아이뮤즈몰</span>
                    </TitleComponents.MainTitle>
                    <TitleComponents.Description stackStyle={{ textAlign: "center" }}>
                        <span>아이뮤즈몰에서도 예술교육 NO.1</span>
                        <span>어린화가들을 만날 수 있습니다</span>
                    </TitleComponents.Description>
                </TitleComponents>
                <Stack alignItems={{ md: "center" }}>
                    <Stack sx={{ mt: "44px", width: { md: "60%" } }}>
                        <ImgCard
                            smallImgSrc="/images/littleband/brandIntro/아이뮤즈몰.webp"
                            bodyColor="#ffffff"
                            height="230px"
                            smallImgWidth="250px"
                            smallImgTop="70px"
                        />
                    </Stack>
                </Stack>
                <Stack alignItems="center" sx={{ m: "40px 64px 46px" }}>
                    <ImuzmallLinkButton />
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}

export function BrandIntroForArt() {
    return (
        <Stack>
            <BrandIntro1 />
            <Divider />
            <BrandIntro2 />
            <Divider />
            <BrandIntro3 />
            <Divider />
            <BrandIntro4 />
            <Divider />
            <BrandIntro5 />
            <Divider />
            <BrandIntro6 />
            <Divider />
            <BrandIntro7 />
            <Divider />
            <BrandIntro8 />
        </Stack>
    );
}
