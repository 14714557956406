import React from "react";
import { Stack, Typography } from "@mui/material";

export default function ImgHeaderCard({
    headerTitle = "headerTitle",
    headerBgColor = "constant.darkWhite",
    headerFontColor = "text.primary",
    footerBgColor = "constant.darkWhite",
    imgSrc,
    mainCharacterSrc,
    mainCharacterWidth,
    mainCharacterHeight,
    otherCharacterSrc,
    otherCharacterWidth,
    otherCharacterHeight,
    imgStyle,
    cardStyle,
    children,
}) {
    return (
        <Stack sx={cardStyle}>
            {/* Header Title */}
            <Stack
                position="relative"
                sx={{
                    py: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "30px 30px 0 0",
                    borderLeft: "1px solid",
                    borderTop: "1px solid",
                    borderRight: "1px solid",
                    borderColor: headerBgColor !== "constant.darkWhite" ? headerBgColor : "constant.lightGray",
                    backgroundColor: headerBgColor,
                }}>
                {mainCharacterSrc && (
                    <Stack
                        position="absolute"
                        sx={{
                            top: "-60px",
                            left: 0,
                            width: mainCharacterWidth,
                            height: mainCharacterHeight,
                        }}>
                        <img src={mainCharacterSrc} alt="card image" />
                    </Stack>
                )}
                {otherCharacterSrc && (
                    <Stack
                        position="absolute"
                        sx={{
                            top: "10px",
                            left: "30px",
                            width: otherCharacterWidth,
                            height: otherCharacterHeight,
                        }}>
                        <img src={otherCharacterSrc} alt="card image" />
                    </Stack>
                )}
                <Typography variant="h3" sx={{ color: headerFontColor }}>
                    {headerTitle}
                </Typography>
            </Stack>
            {/* Image */}
            {imgSrc && (
                <Stack
                    sx={{
                        width: "100%",
                        minHeight: 212,
                        borderLeft: "1px solid",
                        borderRight: "1px solid",
                        borderBottom: children ? null : "1px solid",
                        borderColor: "#e5e5e5",
                        borderRadius: children ? null : "0 0 32px 32px",
                    }}>
                    <img src={imgSrc} style={imgStyle} alt="card image" />
                </Stack>
            )}
            {/* Footer Description */}
            {children && (
                <Stack
                    sx={{
                        justifyContent: "center",
                        alignItems: otherCharacterSrc ? "flex-end" : "center",
                        backgroundColor: "constant.darkWhite",
                        borderRadius: "0 0 32px 32px",
                        borderLeft: "1px solid",
                        borderRight: "1px solid",
                        borderBottom: "1px solid",
                        borderColor: footerBgColor !== "constant.darkWhite" ? footerBgColor : "constant.lightGray",
                        span: { display: "block", textAlign: "center" },
                    }}>
                    <Typography
                        variant="body1"
                        sx={{ p: "20px 22px 25px", width: otherCharacterSrc && "65%", fontSize: { md: "18px" } }}>
                        {children}
                    </Typography>
                </Stack>
            )}
        </Stack>
    );
}
