import React, { createContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { Stack } from "@mui/material";

import HomePageNavBar from "../navbar/HomepageNavbar";
import Footer from "./Footer";
import JumboTitleForMain from "./JumboTitleForMain";
import JumboTitle from "./JumboTitle";
import ScrollToTop from "../elements/ScrollToTop";

export const TabContext = createContext(null);

export function HomePageLayout({ menu }) {
    const location = useLocation();
    const navigate = useNavigate();

    const [redirected, setRedirected] = useState(false);
    const [currentTab, setCurrentTab] = useState("");

    // ikidsband.com 리다이렉트 -> /littleband
    useEffect(() => {
        const currentHostName = window.location.hostname;
        const pathName = window.location.pathname;

        // /default 경로로 url이 전달된 경우가 있어서 추가해 둠.
        if (!redirected && pathName === "/default") {
            navigate("/");
        }
        // ikidsband.com으로 처음 진입 시에 /littleband로 리다이렉트되는 조건
        if (!redirected && currentHostName === "ikidsband.com" && pathName.startsWith("/")) {
            setRedirected(true);
            navigate(`/littleband`);
        } else if (!redirected && currentHostName === "ikidsart.co.kr" && pathName.startsWith("/")) {
            // ikidsart.co.kr으로 처음 진입 시에 /littleartists로 리다이렉트되는 조건
            setRedirected(true);
            navigate(`/littleartists`);
        }
    }, [navigate, redirected]);

    const isMainPage =
        location.pathname === "/" || location.pathname === "/littleband" || location.pathname === "/littleartists";

    return (
        <Stack
            sx={{
                backgroundColor: "white",
                width: "100%",
                height: "100%",
            }}>
            {/* NavBar */}
            {isMainPage ? (
                <JumboTitleForMain isMainPage={isMainPage}>
                    <HomePageNavBar menu={menu} location={location} />
                </JumboTitleForMain>
            ) : (
                <JumboTitle currentTab={currentTab}>
                    <HomePageNavBar menu={menu} location={location} />
                </JumboTitle>
            )}
            <TabContext.Provider value={{ setCurrentTab }}>
                <Outlet />
            </TabContext.Provider>
            <ScrollToTop />
            <Footer />
        </Stack>
    );
}
