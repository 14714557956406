import React, { useRef, useState } from "react";

import { styled } from "@mui/styles";
import { Divider, Menu, MenuItem, Stack, Typography, Fade, alpha, Link, Box } from "@mui/material";
import { ArrowDropDownRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useFooterLink from "../../hooks/useFooterLink";
import ContainerLayout from "./ContainerLayout";

export default function Footer() {
    const familySitesRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenFamilySites, setIsOpenFamilySites] = useState(false);
    const [menuWidth, setMenuWidth] = useState(0);
    const [links] = useFooterLink();

    const navigate = useNavigate();

    const familySites = [
        { label: "e피아노고고", link: "https://pianogogo.co.kr" },
        { label: "뮤직고고", link: "https://musicgogo.co.kr" },
        { label: "아이뮤즈몰", link: "https://imuz.co.kr" },
        { label: "가맹상담", link: "http://jueunedu.com" },
        { label: "어린음악대 카페", link: "https://cafe.naver.com/jueun2010" },
        { label: "어린화가들 카페", link: "https://cafe.naver.com/jueunart" },
        { label: "닫기" },
    ];

    const handleClose = () => {
        setIsOpenFamilySites(!isOpenFamilySites);
    };

    const handleOpen = event => {
        setIsOpenFamilySites(true);
        setAnchorEl(event.currentTarget);
        if (familySitesRef.current) {
            setMenuWidth(familySitesRef.current.clientWidth);
        }
    };

    return (
        <Stack sx={{ width: "100%", backgroundColor: "footer.background", color: "footer.content" }}>
            <Divider />
            {/* Link buttons */}
            <ContainerLayout bgColor="transparent">
                <Stack
                    direction="row"
                    spacing={5}
                    sx={{
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        height: 56,
                        img: {
                            "&:hover": {
                                cursor: "pointer",
                            },
                        },
                    }}>
                    {links.map(l => (
                        <a key={l.alt} href={`${l.link}`} target="_blank" rel="noreferrer">
                            <img src={`/images/footer/${l.img}`} alt={`${l.alt}`} />
                        </a>
                    ))}
                </Stack>
            </ContainerLayout>
            <Divider sx={{ backgroundColor: "footer.divider" }} />
            {/* 패밀리 사이트 */}
            <ContainerLayout bgColor="transparent">
                <Stack sx={{ my: 2, px: 2 }} alignItems="center">
                    <Stack sx={{ width: "100%", maxWidth: "sm" }}>
                        <Stack
                            ref={familySitesRef}
                            onClick={handleOpen}
                            direction="row"
                            sx={{
                                width: "100%",
                                py: 1,
                                pl: 3,
                                pr: 2,
                                border: "1px solid",
                                borderColor: "footer.divider",
                                alignItems: "center",
                                borderRadius: 6,
                                ":hover": { cursor: "pointer" },
                            }}>
                            <Typography variant="caption" sx={{ flex: 1, fontSize: 14 }}>
                                패밀리 사이트
                            </Typography>
                            <ArrowDropDownRounded />
                        </Stack>
                        <StyledMenu
                            anchorEl={anchorEl}
                            open={isOpenFamilySites}
                            disablePortal={false}
                            TransitionComponent={Fade}
                            sx={{ ".MuiPaper-root": { width: menuWidth } }}>
                            {familySites.map(item => {
                                return (
                                    <MenuItem
                                        key={item.label}
                                        onClick={() => handleClose(item.link)}
                                        sx={{ justifyContent: item.label === "닫기" ? "flex-end" : "flex-start" }}>
                                        <Link
                                            href={item.link}
                                            target="_blank"
                                            underline="none"
                                            variant="caption"
                                            rel="noreferrer">
                                            <Typography
                                                variant="caption"
                                                sx={{ fontSize: 14, color: "footer.content" }}>
                                                {item.label}
                                            </Typography>
                                        </Link>
                                    </MenuItem>
                                );
                            })}
                        </StyledMenu>
                    </Stack>
                </Stack>
            </ContainerLayout>
            {/* 상담 문의 */}
            <ContainerLayout bgColor="transparent">
                <Stack direction={{ xs: "column", sm: "column", md: "row" }} justifyContent="space-evenly">
                    <Stack sx={{ my: 2, px: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontSize: 20 }}>
                            상담문의
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{ mt: 1, fontSize: 40, letterSpacing: -0.4, fontWeight: 700 }}>
                            1688-5884
                        </Typography>

                        <Typography variant="body1" sx={{ my: 2, span: { display: "block" } }}>
                            <span>상담시간 : 주중 오전 10시 ~ 오후 6시</span>
                            <span>점심 시간 : 주중 오후 12시 ~ 오후 1시</span>
                            <span>휴무 : 주말, 공휴일</span>
                        </Typography>
                    </Stack>
                    <Divider sx={{ bgcolor: "footer.divider" }} flexItem />
                    {/* 상세 설명 */}
                    <Stack sx={{ my: 3, px: 2 }}>
                        <Typography
                            variant="caption"
                            sx={{
                                fontWeight: 500,
                                span: { display: "block", lineHeight: 1.67 },
                            }}>
                            <span>주은교육(주) | 대표자 : 홍혜숙 김덕주</span>
                            <span>대구 본부 : (42706) 대구광역시 달서구 달구벌대로1130 401호</span>
                            <span>서울 본부 : (05826) 서울특별시 송파구 동남로157 GB타워 4층</span>
                            <span>사업자등록번호 : 503-81-80292</span>
                        </Typography>
                        <Typography
                            variant="caption"
                            sx={{
                                fontWeight: 500,
                                span: { display: "inline", lineHeight: 1.67 },
                            }}>
                            <span>통신판매업신고번호 : 제2008-대구달서-0204호</span>
                            <Link
                                rel="noreferer"
                                color="inherit"
                                style={{ cursor: "pointer" }}
                                underline="none"
                                target="_blank"
                                onClick={() =>
                                    window.open(
                                        "https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5038180292",
                                        "주은교육(주)",
                                        "width=600px, height=600px",
                                    )
                                }>
                                <span> [사업자정보확인]</span>
                            </Link>
                        </Typography>
                        <Typography
                            variant="caption"
                            sx={{
                                fontWeight: 500,
                                span: { display: "block", lineHeight: 1.67 },
                            }}>
                            <span style={{ display: "inline" }}>Email : </span>
                            <span style={{ display: "inline", userSelect: "text" }}>customer@jueun-edu.com</span>
                            <span>Tel : 1688-5884</span>
                            &nbsp;
                            <span>copyrightⓒ2008 JUEUNEDU. All rights reserved.</span>
                        </Typography>
                    </Stack>
                </Stack>
            </ContainerLayout>
            <Divider sx={{ backgroundColor: "footer.divider" }} />
            {/* 문서 */}
            <ContainerLayout bgColor="transparent">
                <Stack
                    direction="row"
                    sx={{
                        justifyContent: "space-evenly",
                        my: 1.5,
                        px: { xs: 2, md: 6 },
                        span: {
                            fontSize: 12,
                            fontWeight: 600,
                            whiteSpace: "nowrap",
                            "&:hover": {
                                cursor: "pointer",
                            },
                        },
                    }}>
                    <span onClick={() => navigate("/")}>회사소개</span>
                    <span>|</span>
                    <span onClick={() => navigate("/littleband")}>어린음악대</span>
                    <span>|</span>
                    <span onClick={() => navigate("/littleartists")}>어린화가들</span>
                    <span>|</span>
                    <Link href={"http://jueunedu.com"} target="_blank" underline="none" variant="span" rel="noreferrer">
                        <Box component="span" sx={{ color: "footer.content" }}>
                            가맹문의
                        </Box>
                    </Link>
                </Stack>
            </ContainerLayout>
        </Stack>
    );
}

const StyledMenu = styled(props => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 24,
        marginTop: theme.spacing(1),
        color: theme.palette.footer.content,
        backgroundColor: theme.palette.footer.background,
        border: `1px solid ${theme.palette.footer.content}`,
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "8px 6px",
        },
        "& .MuiMenuItem-root": {
            padding: "8px 8px 8px 16px",
            "&:active": {
                backgroundColor: alpha(theme.palette.footer.background, 0.04),
            },
            "&:not(:last-child)": { borderBottom: `1px solid ${theme.palette.footer.divider}` },
        },
    },
}));
