import React from "react";

import { Stack, Typography } from "@mui/material";

function Subtitle({ children, stackStyle, typoStyle, startIcon, endIcon }) {
    return (
        <Stack sx={{ span: { display: "block" }, ...stackStyle }}>
            {startIcon && startIcon}
            <Typography variant="subtitle1" sx={{ fontSize: { lg: "24px" }, ...typoStyle }}>
                {children}
            </Typography>
            {endIcon && endIcon}
        </Stack>
    );
}

function MainTitle({ children, stackStyle, typoStyle, size = "large" }) {
    return (
        <Stack sx={{ my: 1.5, span: { display: "block" }, ...stackStyle }}>
            <Typography
                variant={size === "large" ? "h1" : size === "medium" ? "h2" : "h4"}
                sx={{ fontSize: { lg: "50px" }, ...typoStyle }}>
                {children}
            </Typography>
        </Stack>
    );
}

function Description({ children, stackStyle, typoStyle }) {
    return (
        <Stack sx={{ mt: 0.5, span: { display: "block" }, ...stackStyle }}>
            <Typography variant="body1" sx={{ lineHeight: 1.5, fontSize: { lg: "18px" }, ...typoStyle }}>
                {children}
            </Typography>
        </Stack>
    );
}

function TitleComponent({ children, sx }) {
    return <Stack sx={{ ...sx }}>{children}</Stack>;
}

export const TitleComponents = Object.assign(TitleComponent, {
    Subtitle,
    MainTitle,
    Description,
});
