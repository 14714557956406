import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Stack } from "@mui/material";

import { TitleComponents } from "../titleComponent/TitleComponents";
import ScrollButton from "../elements/ScrollButton";
import ScrollMouse from "../elements/ScrollMouse";

export default function JumboTitle({ children }) {
    const location = useLocation();
    const [info, setInfo] = useState({ title: <></>, subtitle: "", subtitleColor: "", backgroundImage: "url('')" });

    useEffect(() => {
        switch (location.pathname) {
            case "/":
                setInfo({
                    title: (
                        <>
                            <span>아이들의 눈으로</span>
                            <span>세상을 향합니다</span>
                        </>
                    ),
                    subtitle: "어린 예술가들의 감성 놀이터",
                    subtitleColor: "primary.main",
                    backgroundImage: "url('/images/jueun-edu/jumbo-background.webp')",
                });
                break;
            case "/littleband":
                setInfo({
                    title: (
                        <>
                            <span>즐기는 음악의 가치</span>
                            <span>NO.1 브랜드</span>
                            <span>어린음악대</span>
                        </>
                    ),
                    subtitle: "열 손가락으로 두뇌를 열다",
                    subtitleColor: "littleBand.main",
                    backgroundImage: "url('/images/littleband/jumbo-background.webp')",
                });
                break;
            case "/littleartists":
                setInfo({
                    title: (
                        <>
                            <span>컬러풀 통합미술</span>
                            <span>감성놀이터</span>
                            <span>어린화가들</span>
                        </>
                    ),
                    subtitle: "어린 예술가들이 꿈꾸는 행복한 미술",
                    subtitleColor: "littleArtist.main",
                    backgroundImage: "url('/images/littleartists/jumbo-background.webp')",
                });
                break;
        }
    }, [location]);

    return (
        <Stack
            sx={{
                position: "relative",
                minHeight: window.innerHeight,
                backgroundImage: info.backgroundImage,
                backgroundSize: "cover",
                backgroundAttachment: { xs: "scroll", md: "fixed" },
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}>
            {/* HomepageNavbar */}
            {children}
            <Stack
                sx={{
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    backdropFilter: "blur(5px)",
                }}
            />
            <TitleComponents
                sx={{
                    position: "absolute",
                    top: "calc(50% - 179px)",
                    left: "calc(50% - 152px)",
                    textAlign: "center",
                }}>
                <TitleComponents.Subtitle typoStyle={{ color: info.subtitleColor }}>
                    {info.subtitle}
                </TitleComponents.Subtitle>
                <TitleComponents.MainTitle typoStyle={{ color: "constant.white" }}>
                    {info.title}
                </TitleComponents.MainTitle>
            </TitleComponents>
            {/* md 이상 */}
            <ScrollButton
                onClick={() => {
                    const element = document.getElementById("content");
                    element.scrollIntoView({ behavior: "smooth" });
                }}
            />
            {/* md 미만 */}
            <ScrollMouse
                onClick={() => {
                    const element = document.getElementById("content");
                    element.scrollIntoView({ behavior: "smooth" });
                }}
            />
        </Stack>
    );
}
