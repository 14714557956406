import React from "react";
import { Divider, Grid, Stack } from "@mui/material";
import ImgHeaderCard from "../cards/ImgHeaderCard";
import ContainerLayout from "../layouts/ContainerLayout";

const MainCharacter = [
    {
        name: "또리 (남, 8세)",
        desc: "파란색 베레모를 쓰고 있는 또리는 호기심과 모험심이 강한 밝고 씩씩한 소년입니다. 미술이라면 무엇이든지 좋아하는데 그 중에서도 그림 그리기를 할 때 가장 신이 납니다. 또리의 손에는 항상 팔레트와 붓이 쥐어져 있답니다. 또리는 여동생 소리와 함께 항상 컬러풀한 세상을 꿈꿉니다",
        imgUrl: "/images/littleartists/character/또리.svg",
        color: "#217ec1",
        width: "100px",
    },
    {
        name: "소리 (여, 7세)",
        desc: "빨간색 베레모를 쓰고 있는 소리는 또리의 여동생입니다. 만약 세상에 미술이 없다면 얼마나 심심하고 재미없을까라는 생각을 할 만큼 미술을 사랑하고 좋아하는 소녀랍니다. 그래서 미술 이론에 대해서는 모르는 것이 없습니다. 작품 감상을 좋아하고 그 느낌을 마음껏 표현하는 감수성도 풍부합니다. 큰 붓을 들고 세상의 모든 것을 감상하고 미술로 표현하기 위해 힘차게 걸어갑니다",
        imgUrl: "/images/littleartists/character/소리.svg",
        color: "#da4840",
        width: "115.6px",
    },
];

const OtherCharacter = [
    {
        name: "르네",
        desc: "규칙을 중요시하는 완벽주의자 르네랍니다",
        imgUrl: "/images/littleartists/character/르네.svg",
        color: "#717171",
        width: "97px",
        height: "99px",
    },
    {
        name: "로코코",
        desc: "우아하고 화려한 장식을 좋아하는 귀족 출신, 로코코랍니다",
        imgUrl: "/images/littleartists/character/로코코.svg",
        color: "#3881c3",
        width: "89px",
        height: "99px",
    },
    {
        name: "팝",
        desc: "순수 미술 세계에서 알아주는 유명한 대중스타 팝이랍니다",
        imgUrl: "/images/littleartists/character/팝.svg",
        color: "#409dbf",
        width: "95px",
        height: "89px",
    },
    {
        name: "바로크",
        desc: "가장 자유롭고, 크고 화려하며 생동감 넘치는 바로크랍니다",
        imgUrl: "/images/littleartists/character/바로크.svg",
        color: "#729552",
        width: "93px",
        height: "90px",
    },
    {
        name: "다다",
        desc: "생각없이 뒤엎고, 무엇이든 가지고 노는 유치한 다다랍니다",
        imgUrl: "/images/littleartists/character/다다.svg",
        color: "#f8c644",
        width: "83px",
        height: "90px",
    },
    {
        name: "고딕",
        desc: "복잡하고 세련된 문양이 그려져 있고 키가 큰 고딕이랍니다",
        imgUrl: "/images/littleartists/character/고딕.svg",
        color: "#a77861",
        width: "95px",
    },
];
export default function Character() {
    return (
        <>
            <ContainerLayout sx={{ pt: 8 }}>
                {/* 메인 캐릭터 */}
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    alignItems={{ md: "center" }}
                    justifyContent={{ md: "space-between" }}
                    spacing={{ xs: 10, md: 2 }}
                    sx={{ p: "64px 16px" }}>
                    {MainCharacter.map((character, index) => {
                        return (
                            <ImgHeaderCard
                                key={index}
                                headerTitle={character.name}
                                headerBgColor={character.color}
                                headerFontColor="white"
                                mainCharacterSrc={character.imgUrl}
                                mainCharacterWidth={character.width}
                                mainCharacterHeight="120.5px"
                                cardStyle={{ width: "100%" }}>
                                {character.desc}
                            </ImgHeaderCard>
                        );
                    })}
                </Stack>
            </ContainerLayout>
            <Divider />
            {/* 그 외 캐릭터 */}
            <ContainerLayout bgColor="constant.darkWhite">
                <Grid container spacing={{ xs: 6, md: 2 }} sx={{ py: 8 }}>
                    {OtherCharacter.map((character, index) => {
                        return (
                            <Grid item key={index} xs={12} md={6} lg={4}>
                                <ImgHeaderCard
                                    headerTitle={character.name}
                                    headerBgColor={character.color}
                                    headerFontColor="white"
                                    otherCharacterSrc={character.imgUrl}
                                    otherCharacterWidth={character.width}
                                    otherCharacterHeight={character.height}>
                                    {character.desc}
                                </ImgHeaderCard>
                            </Grid>
                        );
                    })}
                </Grid>
            </ContainerLayout>
            {/*<Stack*/}
            {/*    flexDirection={{ md: "row" }}*/}
            {/*    alignItems={{ md: "center" }}*/}
            {/*    justifyContent={{ md: "space-between" }}*/}
            {/*    spacing={{ xs: 6, md: 0 }}*/}
            {/*    sx={{*/}
            {/*        bgcolor: "#f5f5f5",*/}
            {/*        p: "85px 16px 63.6px 16px",*/}
            {/*        borderBottom: "1px solid #c6c6c6",*/}
            {/*        flexWrap: "wrap",*/}
            {/*    }}>*/}
            {/*    {OtherCharacter.map((character, index) => {*/}
            {/*        return (*/}
            {/*            <ImgHeaderCard*/}
            {/*                key={index}*/}
            {/*                headerTitle={character.name}*/}
            {/*                headerBgColor={character.color}*/}
            {/*                headerFontColor="white"*/}
            {/*                otherCharacterSrc={character.imgUrl}*/}
            {/*                otherCharacterWidth={character.width}*/}
            {/*                otherCharacterHeight={character.height}>*/}
            {/*                {character.desc}*/}
            {/*            </ImgHeaderCard>*/}
            {/*        );*/}
            {/*    })}*/}
            {/*</Stack>*/}
        </>
    );
}
