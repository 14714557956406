import React from "react";

import { Stack, Typography } from "@mui/material";

export default function ImgCard({
    width = "100%",
    height = "311px",
    bodyColor = "#f5f5f5",
    footerColor = "white",
    footerPadding = "20px 33px",
    fullImgSrc,
    smallImgSrc,
    smallImgWidth,
    smallImgWidthForMd,
    smallImgTop = "50px",
    title,
    descFontSize = "14px",
    textAlign = "center", //initial
    descStyle,
    children,
    cardStyle,
}) {
    return (
        <Stack alignItems="center" sx={cardStyle}>
            <Stack
                position="relative"
                alignItems="center"
                sx={{
                    width: { xs: width },
                    height: { xs: height },
                    bgcolor: bodyColor,
                    border: "1px solid #e5e5e5",
                    borderRadius: "30px",
                }}>
                {/* fullimg, smallimg 분리 */}
                {fullImgSrc ? (
                    <Stack position="absolute" alignItems="center">
                        <img
                            src={fullImgSrc}
                            style={{ top: "0px", width: "100%", height: "220px", borderRadius: "30px" }}
                        />
                    </Stack>
                ) : smallImgSrc ? (
                    <Stack
                        position="absolute"
                        sx={{
                            width: { xs: "80%", sm: smallImgWidth, md: `calc(${smallImgWidthForMd} * 0.8)` },
                            top: smallImgTop,
                        }}>
                        <img src={smallImgSrc} />
                    </Stack>
                ) : (
                    <Stack position="absolute">{/* <img src={smallImgSrc} /> */}</Stack>
                )}
                <Stack
                    position="absolute"
                    sx={{
                        bottom: "0px",
                        width: "100%",
                        padding: footerPadding,
                        textAlign: textAlign,
                        bgcolor: footerColor,
                        color: "black",
                        borderBottomLeftRadius: "30px",
                        borderBottomRightRadius: "30px",
                        span: { display: "block" },
                        ...descStyle,
                    }}>
                    {title && (
                        <Typography variant="h3" sx={{ color: "black", fontSize: "20px", fontWeight: "bold" }}>
                            {title}
                        </Typography>
                    )}
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: { xs: descFontSize, md: "18px" },
                        }}>
                        {children}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
}

// function Content() {
//     return (
//         <Stack position="relative" flexDirection="row" justifyContent="center" sx={{ width: "100%", top: "35px" }}>
//             <Stack
//                 position="absolute"
//                 justifyContent="center"
//                 alignItems="center"
//                 sx={{
//                     width: "154px",
//                     height: "154px",
//                     borderRadius: "50%",
//                     border: `1px solid ${theme.palette.littleBand.main}`,
//                     fontSize: theme.typography.h4,
//                     bgcolor: "rgba(51, 144, 255, 0.15)",
//                     ml: 15,
//                 }}>
//                 <span>과학적</span>
//                 <span>창조력</span>
//             </Stack>
//             <Stack
//                 position="absolute"
//                 justifyContent="center"
//                 alignItems="center"
//                 sx={{
//                     width: "154px",
//                     height: "154px",
//                     borderRadius: "50%",
//                     border: `1px solid ${theme.palette.littleBand.main}`,
//                     fontSize: theme.typography.h4,
//                     bgcolor: "rgba(51, 144, 255, 0.15)",
//                     mr: 15,
//                 }}>
//                 <span>인문학적</span>
//                 <span>소양</span>
//             </Stack>
//             <Stack
//                 position="absolute"
//                 sx={{
//                     top: 80,
//                     width: "1px",
//                     height: "89px",
//                     bgcolor: theme.palette.littleBand.main,
//                 }}></Stack>
//             <Stack
//                 position="absolute"
//                 sx={{
//                     top: 79,
//                     width: "5px",
//                     height: "5px",
//                     bgcolor: theme.palette.littleBand.main,
//                     borderRadius: "50%",
//                 }}
//             />
//             <Typography
//                 sx={{
//                     position: "absolute",
//                     top: 180,
//                     fontSize: theme.typography.h4,
//                     color: theme.palette.littleBand.main,
//                 }}>
//                 창의 융합형 인재 형성
//             </Typography>
//         </Stack>
//     );
// }
