import { Divider, Grid, Stack } from "@mui/material";
import React from "react";
import { TitleComponents } from "../../titleComponent/TitleComponents";
import ImgCard from "../../cards/ImgCard";
import PictogramCard from "../../cards/PictogramCard";
import ContainerLayout from "../../layouts/ContainerLayout";

const infoArr = [
    {
        title: "프로그램 개발",
        desc: "음악 · 미술 교육 전 영역의 독자적 컨텐츠 개발 및 교수 방법 연구",
        imgUrl: "/images/brand/creativelab/pictogram1.webp",
    },
    {
        title: "전문교구 개발",
        desc: "흥미롭고 교육적 가치가 있는 교구, 기획과 개발을 통한 우수한 교구 제공",
        imgUrl: "/images/brand/creativelab/pictogram2.webp",
    },
    {
        title: "전문교재 개발",
        desc: "음악과 미술의 이론과 실기 등 단계별 커리큘럼 및 교재 개발",
        imgUrl: "/images/brand/creativelab/pictogram3.webp",
    },
    {
        title: "창작곡 & 율동 제공",
        desc: "음악과 미술의 이론과 실기 등 단계별 커리큘럼 및 교재 개발",
        imgUrl: "/images/brand/creativelab/pictogram4.webp",
    },
    {
        title: "교사 연수 및 출강",
        desc: "우수한 교사 양성 배출, 지역별 세미나,초청 강의 등을 통해 교육의 질 향상",
        imgUrl: "/images/brand/creativelab/pictogram5.webp",
    },
];

export default function CreativeLab() {
    return (
        <>
            <ContainerLayout>
                {/* 창의연구소 로고 */}
                <Stack alignItems={{ md: "center" }}>
                    <Stack sx={{ p: "0px 16px 64px 16px", width: { md: "80%" } }}>
                        <ImgCard
                            smallImgSrc="/images/brand/creativelab/creativeLab_logo.svg"
                            // smallImgWidth="284px"
                            smallImgTop="70px"
                            smallImgWidthForMd="650px"
                            height="370px"
                            bodyColor="#fff7de">
                            <span>주은교육(주) 부설연구소인 한국아동예술창의연구소는</span>
                            <span>
                                음악 · 미술 교육 전문연구진과 실제 현장 경험이 풍부한 우수한 강사와 연구진으로
                                구성되었으며, 우리나라 음악 교육 실정에 적합한{" "}
                            </span>
                            <span>
                                교육 프로그램과 교구, 교재의 기획부터 개발, 실제 수업에 적용 가능한 교육까지 체계적,
                                전문적 지원을 제공함
                            </span>
                        </ImgCard>
                    </Stack>
                </Stack>
            </ContainerLayout>
            <Divider />

            {/* 역할 */}

            <ContainerLayout bgColor="constant.posterBg">
                <Stack sx={{ p: "64px 16px", borderBottom: "1px solid #e5e5e5" }}>
                    <TitleComponents>
                        <TitleComponents.MainTitle stackStyle={{ textAlign: "start" }}>역할</TitleComponents.MainTitle>
                    </TitleComponents>

                    <Grid container spacing={2} sx={{ py: 8 }}>
                        {infoArr.map((info, index) => {
                            return (
                                <Grid item key={index} xs={12} md={6} lg={4}>
                                    <PictogramCard key={index} title={info.title} imgUrl={info.imgUrl}>
                                        {info.desc}
                                    </PictogramCard>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Stack>
            </ContainerLayout>
        </>
    );
}
