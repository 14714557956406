import { Divider, Stack } from "@mui/material";
import React from "react";
import { BrandIntro4, BrandIntro5, BrandIntro6, BrandIntro7, LittlebandWithLogo } from "../../littleband/BrandIntro";
import ContainerLayout from "../../layouts/ContainerLayout";

export default function LittleBandBrand() {
    return (
        <Stack>
            {/* 어린음악대 로고 */}
            <ContainerLayout>
                <Stack alignItems={{ md: "center" }}>
                    <Stack sx={{ p: "0 16px 63px 16px", width: { md: "80%" } }}>
                        <LittlebandWithLogo />
                    </Stack>
                </Stack>
            </ContainerLayout>
            <Divider />
            <BrandIntro4 />
            <Divider />
            <BrandIntro5 bgcolor="#f5f5f5" />
            <Divider />
            <BrandIntro6 bgcolor="white" />
            <Divider />
            <BrandIntro7 bgcolor="#f5f5f5" />
            <Divider />
        </Stack>
    );
}
