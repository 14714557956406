import React, { Fragment } from "react";

import { Divider, Stack } from "@mui/material";

import { TitleComponents } from "../../titleComponent/TitleComponents";
import ImgHeaderCard from "../../cards/ImgHeaderCard";
import ImgSlickCard from "../../cards/ImgSlickCard";
import ContainerLayout from "../../layouts/ContainerLayout";
import ImuzmallLinkButton from "../../elements/ImuzmallLinkButton";

export default function BookTab() {
    return (
        <>
            {/* 아이뮤즈몰 탭 */}
            <ContainerLayout>
                <Stack sx={{ mt: 8, alignItems: "center" }}>
                    <ImgHeaderCard
                        headerTitle="어린음악대 교재는 오직!"
                        cardStyle={{ width: { xs: "100%", md: "50%" } }}
                        imgSrc="/images/imuzemall/imuzmall-logo.svg"
                        imgStyle={{
                            width: 244,
                            margin: "auto",
                        }}></ImgHeaderCard>
                    <Stack sx={{ mt: 5, mb: 8, alignItems: "center" }}>
                        <ImuzmallLinkButton />
                    </Stack>
                </Stack>
            </ContainerLayout>
            {/* 교재 Array */}
            {templateArr.map((item, index) => (
                <Fragment key={`${item.title}-${index}`}>
                    <Divider />
                    <ContainerLayout bgColor={index % 2 === 0 ? "constant.darkWhite" : "constant.white"} sx={{ py: 8 }}>
                        <Stack direction={{ xs: "column", md: "row" }} spacing={10}>
                            {/* Title */}
                            <TitleComponents sx={{ mb: 5.5, mr: "auto", textAlign: { xs: "center", md: "left" } }}>
                                <TitleComponents.MainTitle>{item.title}</TitleComponents.MainTitle>
                                <TitleComponents.Description>{item.subTitle}</TitleComponents.Description>
                            </TitleComponents>
                            {/* 교재 소개 */}
                            <ImgSlickCard
                                sliderArr={item.sliderArr}
                                descArr={item.descArr}
                                imgStyle={{
                                    height: 240,
                                    margin: "auto",
                                    marginTop: index === 0 ? 0 : 16,
                                    marginBottom: index === 0 ? 0 : 16,
                                }}
                                sliderStyle={{ width: { md: 300, lg: 600 } }}
                            />
                        </Stack>
                    </ContainerLayout>
                </Fragment>
            ))}
        </>
    );
}

const templateArr = [
    // 피아노고고 베이직
    {
        title: "피아노고고 베이직",
        subTitle: <span>피아노 입문 교육을 통한 기본 음악성 형성</span>,
        sliderArr: [{ imgSrc: "/images/imuzemall/book/image559.jpg", backgroundColor: "#fff1cb" }],
        descArr: ["피아노 시작시 단계별 실기 학습", "이론고고 베이직과 병행, 인지능력 향상"],
    },
    // 이론고고 베이직
    {
        title: "이론고고 베이직",
        subTitle: <span>실기 교재에 맞춘 체계적 학습이 가능한 이론</span>,
        sliderArr: [
            { imgSrc: "/images/imuzemall/book/image567.jpg", backgroundColor: "#fff5d5" },
            { imgSrc: "/images/imuzemall/book/image565.jpg", backgroundColor: "#fff5d5" },
            { imgSrc: "/images/imuzemall/book/image564.jpg", backgroundColor: "#fff5d5" },
        ],
        descArr: ["소근육 발달을 고려한 이론", "다양한 교구활동 연계방법 제시"],
    },
    // 씽씽고고 베이직
    {
        title: "씽씽고고 베이직",
        subTitle: <span>스토리텔링과 함께하는 피아노 입문 레슨교재</span>,
        sliderArr: [
            { imgSrc: "/images/imuzemall/book/image563.jpg", backgroundColor: "#ffd6e5" },
            { imgSrc: "/images/imuzemall/book/image562.jpg", backgroundColor: "#ffd6e5" },
            { imgSrc: "/images/imuzemall/book/image561.jpg", backgroundColor: "#ffd6e5" },
        ],
        descArr: ["블록리듬을 이용한 쉬운 접근", "스토리텔링으로 이해력과 창의력을 UP!"],
    },
    // 피아노고고
    {
        title: "피아노고고",
        subTitle: <span>아이들의 눈높이에 맞춘 피아노 초급교재</span>,
        sliderArr: [
            { imgSrc: "/images/imuzemall/book/pianogogo-1.jpg", backgroundColor: "#cce6ff" },
            { imgSrc: "/images/imuzemall/book/pianogogo-2.jpg", backgroundColor: "#cce6ff" },
            { imgSrc: "/images/imuzemall/book/pianogogo-3.jpg", backgroundColor: "#cce6ff" },
            { imgSrc: "/images/imuzemall/book/pianogogo-4.jpg", backgroundColor: "#cce6ff" },
        ],
        descArr: ["어린음악대 전용 교재", "교육현장을 반영한 디테일한 구성"],
    },
    // 씽씽고고
    {
        title: "씽씽고고",
        subTitle: <span>재밌는 응용곡이 스토리와 만나다!</span>,
        sliderArr: [
            { imgSrc: "/images/imuzemall/book/image573.jpg", backgroundColor: "#d8eaff" },
            { imgSrc: "/images/imuzemall/book/image574.jpg", backgroundColor: "#d8eaff" },
            { imgSrc: "/images/imuzemall/book/image575.jpg", backgroundColor: "#d8eaff" },
            { imgSrc: "/images/imuzemall/book/image576.jpg", backgroundColor: "#d8eaff" },
        ],
        descArr: ["음악&스토리텔링 접목 응용교재", "연주에 적합한 음악 선곡"],
    },
    // HI재즈ABC
    {
        title: "HI재즈ABC",
        subTitle: <span>클래식과 재즈를 결합한 아동용 실용음악교재</span>,
        sliderArr: [
            { imgSrc: "/images/imuzemall/book/image524.jpg", backgroundColor: "#fffcd0" },
            { imgSrc: "/images/imuzemall/book/image522.jpg", backgroundColor: "#fffcd0" },
            { imgSrc: "/images/imuzemall/book/image520.jpg", backgroundColor: "#fffcd0" },
            { imgSrc: "/images/imuzemall/book/image518.jpg", backgroundColor: "#fffcd0" },
            { imgSrc: "/images/imuzemall/book/image516.jpg", backgroundColor: "#fffcd0" },
            { imgSrc: "/images/imuzemall/book/image514.jpg", backgroundColor: "#fffcd0" },
        ],
        descArr: ["기초과정도 연주가 가능한 구성", "실용반주 최초 교과과정 연계"],
    },
    // 간추린 하농
    {
        title: "간추린 하농",
        subTitle: <span>손가락 훈련을 위한 핵심교재</span>,
        sliderArr: [{ imgSrc: "/images/imuzemall/book/image572.jpg", backgroundColor: "#d8e2ff" }],
        descArr: ["손가락 훈련 필수 교재", "핵심만 간추린 구성"],
    },
    // 이론고고
    {
        title: "이론고고",
        subTitle: <span>차시별 단계수업으로 점진적 이론실력 UP!</span>,
        sliderArr: [
            { imgSrc: "/images/imuzemall/book/image541.jpg", backgroundColor: "#ffcce5" },
            { imgSrc: "/images/imuzemall/book/image539.jpg", backgroundColor: "#ffcce5" },
            { imgSrc: "/images/imuzemall/book/image538.jpg", backgroundColor: "#ffcce5" },
            { imgSrc: "/images/imuzemall/book/image537.jpg", backgroundColor: "#ffcce5" },
            { imgSrc: "/images/imuzemall/book/image536.jpg", backgroundColor: "#ffcce5" },
            { imgSrc: "/images/imuzemall/book/image535.jpg", backgroundColor: "#ffcce5" },
            { imgSrc: "/images/imuzemall/book/image534.jpg", backgroundColor: "#ffcce5" },
            { imgSrc: "/images/imuzemall/book/image532.jpg", backgroundColor: "#ffcce5" },
            { imgSrc: "/images/imuzemall/book/image531.jpg", backgroundColor: "#ffcce5" },
            { imgSrc: "/images/imuzemall/book/image530.jpg", backgroundColor: "#ffcce5" },
            { imgSrc: "/images/imuzemall/book/image528.jpg", backgroundColor: "#ffcce5" },
            { imgSrc: "/images/imuzemall/book/image526.jpg", backgroundColor: "#ffcce5" },
        ],
        descArr: ["어린음악대 전용교재와 연계", "주차별, 일별 학습이 가능한 구성"],
    },
    // 계이름고고
    {
        title: "계이름고고",
        subTitle: <span>이론고고와 병행하는 계이름 이론 학습</span>,
        sliderArr: [
            { imgSrc: "/images/imuzemall/book/image557.jpg", backgroundColor: "#ffd8e9" },
            { imgSrc: "/images/imuzemall/book/image555.jpg", backgroundColor: "#ffd8e9" },
            { imgSrc: "/images/imuzemall/book/image553.jpg", backgroundColor: "#ffd8e9" },
            { imgSrc: "/images/imuzemall/book/image552.jpg", backgroundColor: "#ffd8e9" },
            { imgSrc: "/images/imuzemall/book/image551.jpg", backgroundColor: "#ffd8e9" },
            { imgSrc: "/images/imuzemall/book/image550.jpg", backgroundColor: "#ffd8e9" },
            { imgSrc: "/images/imuzemall/book/image549.jpg", backgroundColor: "#ffd8e9" },
            { imgSrc: "/images/imuzemall/book/image548.jpg", backgroundColor: "#ffd8e9" },
            { imgSrc: "/images/imuzemall/book/image546.jpg", backgroundColor: "#ffd8e9" },
            { imgSrc: "/images/imuzemall/book/image545.jpg", backgroundColor: "#ffd8e9" },
            { imgSrc: "/images/imuzemall/book/image544.jpg", backgroundColor: "#ffd8e9" },
            { imgSrc: "/images/imuzemall/book/image543.jpg", backgroundColor: "#ffd8e9" },
        ],
        descArr: ["이론고고 내용 개념이해, 복습", "학습 필수요소 계이름 교재"],
    },
    // 와우이론고고
    {
        title: "와우이론고고",
        subTitle: <span>One Step 통합 이론 교육</span>,
        sliderArr: [
            { imgSrc: "/images/imuzemall/book/image592.jpg", backgroundColor: "#d0eeff" },
            { imgSrc: "/images/imuzemall/book/image591.jpg", backgroundColor: "#d0eeff" },
            { imgSrc: "/images/imuzemall/book/image590.jpg", backgroundColor: "#d0eeff" },
            { imgSrc: "/images/imuzemall/book/image588.jpg", backgroundColor: "#d0eeff" },
            { imgSrc: "/images/imuzemall/book/image586.jpg", backgroundColor: "#d0eeff" },
            { imgSrc: "/images/imuzemall/book/image585.jpg", backgroundColor: "#d0eeff" },
            { imgSrc: "/images/imuzemall/book/image583.jpg", backgroundColor: "#d0eeff" },
            { imgSrc: "/images/imuzemall/book/image593.jpg", backgroundColor: "#d0eeff" },
            { imgSrc: "/images/imuzemall/book/image606.jpg", backgroundColor: "#d0eeff" },
            { imgSrc: "/images/imuzemall/book/image605.jpg", backgroundColor: "#d0eeff" },
        ],
        descArr: ["레슨고고&씽씽고고 진도 연계과정 학습", "이론과 창의성을 연결한 이론 학습"],
    },
    // 와우계이름고고
    {
        title: "와우계이름고고",
        subTitle: <span>One Step 통합 음악 계이름 교육</span>,
        sliderArr: [
            { imgSrc: "/images/imuzemall/book/image603.jpg", backgroundColor: "#fff6cc" },
            { imgSrc: "/images/imuzemall/book/image601.jpg", backgroundColor: "#fff6cc" },
            { imgSrc: "/images/imuzemall/book/image599.jpg", backgroundColor: "#fff6cc" },
            { imgSrc: "/images/imuzemall/book/image597.jpg", backgroundColor: "#fff6cc" },
            { imgSrc: "/images/imuzemall/book/image596.jpg", backgroundColor: "#fff6cc" },
            { imgSrc: "/images/imuzemall/book/image595.jpg", backgroundColor: "#fff6cc" },
            { imgSrc: "/images/imuzemall/book/image594.jpg", backgroundColor: "#fff6cc" },
            { imgSrc: "/images/imuzemall/book/image604.jpg", backgroundColor: "#fff6cc" },
            { imgSrc: "/images/imuzemall/book/image608.jpg", backgroundColor: "#fff6cc" },
            { imgSrc: "/images/imuzemall/book/image607.jpg", backgroundColor: "#fff6cc" },
        ],
        descArr: ["레슨고고&씽씽고고 진도 연계 학습", "이론과 창의성을 연결한 계이름 학습"],
    },
    // 소리Q & 또리Q 정답을 찾아라
    {
        title: "소리Q & 또리Q 정답을 찾아라",
        subTitle: (
            <>
                <span>One-Stop 통합음악교육으로 레슨고고& 씽씽고고와</span>
                <span>진도를 맞춘 이론 내용을</span>
            </>
        ),
        sliderArr: [
            { imgSrc: "/images/imuzemall/book/image613.jpg", backgroundColor: "#ffd1e2" },
            { imgSrc: "/images/imuzemall/book/image612.jpg", backgroundColor: "#ffd1e2" },
            { imgSrc: "/images/imuzemall/book/image611.jpg", backgroundColor: "#ffd1e2" },
            { imgSrc: "/images/imuzemall/book/image610.jpg", backgroundColor: "#ffd1e2" },
            { imgSrc: "/images/imuzemall/book/image609.jpg", backgroundColor: "#ffd1e2" },
            { imgSrc: "/images/imuzemall/book/image616.jpg", backgroundColor: "#ffd1e2" },
            { imgSrc: "/images/imuzemall/book/image615.jpg", backgroundColor: "#ffd1e2" },
            { imgSrc: "/images/imuzemall/book/image614.jpg", backgroundColor: "#ffd1e2" },
        ],
        descArr: ["다양한 음악 문제 유형을 경험", "다각도 이론 접근으로 사고력 향상 도움"],
    },
];
