import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { Stack } from "@mui/material";

export default function ScrollButton({ onClick }) {
    return (
        <Stack sx={{ display: { xs: "none", md: "flex" }, position: "absolute", bottom: 66, left: "50%" }}>
            <ScrollToDown onClick={onClick} />
        </Stack>
    );
}

const ToDown = keyframes`
    0% {
        transform: rotate(-45deg) translate(0, 0);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: rotate(-45deg) translate(-10px, 10px);
        opacity: 0;
    }
`;

const ScrollToDown = styled.span`
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: rotate(-45deg);
    box-sizing: border-box;
    cursor: pointer;
    animation: ${ToDown} 1.5s infinite;
    -webkit-animation: ${ToDown} 1.5s infinite;
`;
