import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Divider, IconButton, Stack } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import Footer from "../../components/layouts/Footer";
import RoundedButton from "../../components/elements/RoundedButton";
import ContainerLayout from "../../components/layouts/ContainerLayout";
import PianoGogoIntro from "../../components/littleband/piano-gogo/PianoGogoIntro";
import PianoGogoTool from "../../components/littleband/piano-gogo/PianoGogoTool";

export default function PianoGoGoDetail() {
    const navigate = useNavigate();

    const goToLittleBandProgram = () => {
        navigate("/littleband/program");
    };

    const goToMusicGogo = () => {
        const openNewWindow = window.open("about:blank");
        openNewWindow.location.href = "https://musicgogo.co.kr/enroll/pianogogo";
    };

    const goToEPianoGogo = () => {
        const openNewWindow = window.open("about:blank");
        openNewWindow.location.href = "https://pianogogo.co.kr";
    };

    // 최초 진입시 스크롤 최상단으로 이동
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>어린음악대 - 피아노고고 소개</title>
                <meta
                    name="description"
                    content="어린음악대의 독창적인 독점 음악 교구를 활용한 자기주도적 몰입(FLOW)과정을 통해 두뇌를 열어주는 통합형 레슨 프로그램"
                    data-react-helmet="true"
                />
                <link rel="icon" type="image/x-icon" href="/favicon/little-band/favicon-16x16.ico" />
                <meta property="og:type" content="website" data-react-helmet="true" />
                <meta property="og:locale" content="ko_KR" data-react-helmet="true" />
                <meta property="og:url" content="https://ikidsband.com/pianogogo" data-react-helmet="true" />
                <meta property="og:site_name" content="어린음악대" data-react-helmet="true" />
                <meta property="og:title" content="어린음악대 - 피아노고고" data-react-helmet="true" />
                <meta
                    property="og:description"
                    content="어린음악대의 독창적인 독점 음악 교구를 활용한 자기주도적 몰입(FLOW)과정을 통해 두뇌를 열어주는 통합형 레슨 프로그램"
                    data-react-helmet="true"
                />
                <meta
                    property="og:image"
                    content="https://jueun-edu.com/images/og-jueun-edu.png"
                    data-react-helmet="true"
                />

                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
                <meta name="twitter:title" content="어린음악대 - 피아노고고" data-react-helmet="true" />
                <meta
                    name="twitter:description"
                    content="어린음악대의 독창적인 독점 음악 교구를 활용한 자기주도적 몰입(FLOW)과정을 통해 두뇌를 열어주는 통합형 레슨 프로그램"
                    data-react-helmet="true"
                />
                <meta
                    name="twitter:image"
                    content="https://jueun-edu.com/images/og-jueun-edu.png"
                    data-react-helmet="true"
                />
            </Helmet>
            <ContainerLayout
                sx={{ position: "fixed", width: "100%", top: 0, zIndex: 1, backgroundColor: "constant.white" }}>
                <Stack direction="row" sx={{ py: 2, alignItems: "center", justifyContent: "space-between" }}>
                    <IconButton onClick={goToLittleBandProgram} sx={{ backgroundColor: "constant.darkWhite" }}>
                        <ArrowBackIosNewIcon />
                    </IconButton>
                    <img src="/images/littleband/pianogogo-logo.svg" alt="pianogogo logo" style={{ maxWidth: 78 }} />
                    <Stack sx={{ width: 40 }}></Stack>
                </Stack>
            </ContainerLayout>
            <Divider />
            {/* 인트로 */}
            <PianoGogoIntro />
            <Divider />
            {/* 교구 설명 */}
            <PianoGogoTool />
            {/* 뮤직고고 바로가기 */}
            <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={2}
                sx={{ width: "100%", justifyContent: "center", alignItems: "center", mb: 8 }}>
                <RoundedButton
                    onClick={goToMusicGogo}
                    size="large"
                    variant="outlined"
                    otherColor="littleBand.pianogogo"
                    bgcolor="littleBand.pianogogo"
                    otherHoverColor="constant.white">
                    피아노고고 체험신청
                </RoundedButton>
                <RoundedButton
                    onClick={goToEPianoGogo}
                    variant="contained"
                    size="large"
                    color="littleBand.main"
                    sx={{ textTransform: "lowercase" }}>
                    e피아노고고 바로가기
                </RoundedButton>
            </Stack>
            <Footer />
        </>
    );
}
