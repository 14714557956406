import React, { useEffect, useState } from "react";

import { Stack, Typography } from "@mui/material";
import { KeyboardArrowUpRounded } from "@mui/icons-material";

export default function ScrollToTop() {
    const [display, setDisplay] = useState(false);
    const [scrollY, setScrollY] = useState(0);
    const innerHeight = window.innerHeight / 1.3; // 스크롤이 75% 넘어갔을 때

    const handleScrollToTop = () => {
        window.scrollTo({ behavior: "smooth", top: 0, left: 0 });
    };

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (innerHeight < scrollY) {
            setDisplay(true);
        } else {
            setDisplay(false);
        }
    }, [scrollY, innerHeight]);

    return (
        <Stack
            onClick={handleScrollToTop}
            sx={{
                display: display ? "flex" : "none",
                position: "fixed",
                bottom: 0,
                right: 0,
                zIndex: 20,
                alignItems: "center",
                justifyContent: "center",
                mr: { xs: 2, md: 4 },
                mb: { xs: 2, md: 4 },
                width: { xs: 50, md: 74 },
                height: { xs: 50, md: 74 },
                backgroundColor: "constant.lightGray",
                borderRadius: "50%",
                border: "1px solid",
                borderColor: "constant.gray",
                cursor: "pointer",
            }}>
            <KeyboardArrowUpRounded sx={{ fontSize: { xs: "1.25rem", md: "1.5rem" }, color: "text.disabled" }} />
            <Typography
                sx={{
                    mb: 1,
                    fontFamily: "Pretendard",
                    fontWeight: "bold",
                    fontSize: { xs: 14, md: 22 },
                    lineHeight: { xs: 1.24, md: 1.12 },
                    color: "text.disabled",
                }}>
                TOP
            </Typography>
        </Stack>
    );
}
