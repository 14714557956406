import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function useSetJumboTitle({ contextState }) {
    const location = useLocation();
    const [jumboInfo, setJumboInfo] = useState({ title: <></>, backgroundImage: "url('')" });

    useEffect(() => {
        switch (location.pathname) {
            case "/brand":
                setJumboInfo({
                    title: (
                        <>
                            <span>주은교육의</span>
                            <span>다양한 브랜드를</span>
                            <span>소개합니다</span>
                        </>
                    ),
                    backgroundImage: "url('/images/littleband/jumbo-background-small.webp')",
                });
                break;
            case "/notice":
                setJumboInfo({
                    title: (
                        <>
                            <span>주은교육의</span>
                            <span>다양한 모습과</span>
                            <span>소식을 보여드립니다</span>
                        </>
                    ),
                    backgroundImage: "url('/images/littleband/jumbo-background-small.webp')",
                });
                break;
            case "/littleband/program":
                if (contextState === "프로그램") {
                    setJumboInfo({
                        title: (
                            <>
                                <span>어린음악대만의</span>
                                <span>독창적인</span>
                                <span>음악 교육 프로그램</span>
                            </>
                        ),
                        backgroundImage: "url('/images/littleband/jumbo-background-small.webp')",
                    });
                    break;
                } else if (contextState === "교재") {
                    setJumboInfo({
                        title: (
                            <>
                                <span>두뇌밸런스 음악교육</span>
                                <span>어린음악대</span>
                                <span>전용 교재</span>
                            </>
                        ),
                        backgroundImage: "url('/images/littleband/jumbo-background-small.webp')",
                    });
                    break;
                } else if (contextState === "교구") {
                    setJumboInfo({
                        title: (
                            <>
                                <span>특허인증 음악교구</span>
                                <span>어린음악대</span>
                                <span>전용 교구</span>
                            </>
                        ),
                        backgroundImage: "url('/images/littleband/jumbo-background-small.webp')",
                    });
                    break;
                }
                break;
            case "/littleband/notice":
                setJumboInfo({
                    title: (
                        <>
                            <span>어린음악대의</span>
                            <span>다양한 모습과</span>
                            <span>소식을 전해드립니다</span>
                        </>
                    ),
                    backgroundImage: "url('/images/littleband/jumbo-background-small.webp')",
                });
                break;
            case "/littleartists/program":
                setJumboInfo({
                    title: (
                        <>
                            <span>어린화가들의</span>
                            <span>감성컬러한가득</span>
                            <span>미술 교육 프로그램</span>
                        </>
                    ),
                    backgroundImage: "url('/images/littleband/jumbo-background-small.webp')",
                });
                break;
            case "/littleartists/notice":
                setJumboInfo({
                    title: (
                        <>
                            <span>어린화가들의</span>
                            <span>다양한 모습과</span>
                            <span>소식을 전해드립니다</span>
                        </>
                    ),
                    backgroundImage: "url('/images/littleband/jumbo-background-small.webp')",
                });
                break;
        }
    }, [location, contextState]);

    return [jumboInfo];
}
