import React from "react";
import { Grid } from "@mui/material";
import ImgHeaderCard from "../../cards/ImgHeaderCard";
import ContainerLayout from "../../layouts/ContainerLayout";

export default function MusicGogoTool() {
    return (
        <ContainerLayout sx={{ py: 8 }}>
            <Grid container spacing={2}>
                {toolArr.map((item, index) => (
                    <Grid key={index} item xs={12} md={6} lg={3}>
                        <ImgHeaderCard
                            headerTitle={item.title}
                            headerBgColor="littleBand.musicgogo"
                            headerFontColor="constant.white"
                            imgSrc={item.imgSrc}>
                            {item.description}
                        </ImgHeaderCard>
                    </Grid>
                ))}
            </Grid>
        </ContainerLayout>
    );
}

const toolArr = [
    {
        imgSrc: "/images/littleband/music-gogo/picture-5.png",
        title: "연령별 맞춤 커리큘럼",
        description: (
            <>
                <span>연령별 맞춤 커리큘럼으로</span>
                <span>수업의 집중도 UP!</span>
            </>
        ),
    },
    {
        imgSrc: "/images/littleband/music-gogo/picture-6.png",
        title: "영상 컨텐츠",
        description: (
            <>
                <span>전문교사의 영상컨텐츠로</span>
                <span>수업의 몰입도 UP!</span>
            </>
        ),
    },
    {
        imgSrc: "/images/littleband/music-gogo/picture-7.png",
        title: "1인 음악 전문교구",
        description: (
            <>
                <span>음악전문 1인교구 제공으로</span>
                <span>수업의 참여도 UP!</span>
            </>
        ),
    },
    {
        imgSrc: "/images/littleband/music-gogo/picture-8.png",
        title: "워크북",
        description: (
            <>
                <span>워크북으로 한번 더 정리</span>
                <span>수업의 이해도 UP!</span>
            </>
        ),
    },
];
