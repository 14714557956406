import { Divider, Stack } from "@mui/material";
import React from "react";
import {
    BrandIntro4,
    BrandIntro5,
    BrandIntro6,
    BrandIntro7,
    LittleArtistWithlogo,
} from "../../littleartist/BrandIntroForArt";
import ContainerLayout from "../../layouts/ContainerLayout";

export default function LittleArtistBrand() {
    return (
        <Stack>
            {/* 어린음악대 로고 */}
            <ContainerLayout>
                <Stack alignItems={{ md: "center" }}>
                    <Stack sx={{ p: "0 16px 63px 16px", width: { md: "80%" } }}>
                        <LittleArtistWithlogo />
                    </Stack>
                </Stack>
            </ContainerLayout>
            <Divider />
            {/* 단계별 맞춤수업 */}
            <BrandIntro4 />
            <Divider />
            {/* 교과연계 미술교육 */}
            <BrandIntro5 bgcolor="#f5f5f5" />
            <Divider />
            {/* 미술의 기본개념 확립 */}
            <BrandIntro6 />
            <Divider />
            {/* 오감만족 */}
            <BrandIntro7 />
            <Divider />
        </Stack>
    );
}
