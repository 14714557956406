import React from "react";

import { Divider, Grid, Stack, Typography } from "@mui/material";

import { TitleComponents } from "../../titleComponent/TitleComponents";
import ImgHeaderCard from "../../cards/ImgHeaderCard";
import ContainerLayout from "../../layouts/ContainerLayout";
import ImuzmallLinkButton from "../../elements/ImuzmallLinkButton";

export default function ToolTab() {
    return (
        <>
            {/* 아이뮤즈몰 탭 */}
            <ContainerLayout>
                <Stack sx={{ mt: 8, alignItems: "center" }}>
                    <ImgHeaderCard
                        headerTitle="어린음악대 교재는 오직!"
                        cardStyle={{ width: { xs: "100%", md: "50%" } }}
                        imgSrc="/images/imuzemall/imuzmall-logo.svg"
                        imgStyle={{
                            width: 244,
                            margin: "auto",
                        }}></ImgHeaderCard>
                    <Stack sx={{ mt: 5, mb: 8, alignItems: "center" }}>
                        <ImuzmallLinkButton />
                    </Stack>
                </Stack>
            </ContainerLayout>
            {/* 찍찍이 교구 */}
            <Divider />
            <ContainerLayout>
                <TitleComponents sx={{ mb: 5.5, pt: 8, textAlign: "center" }}>
                    <TitleComponents.MainTitle>찍찍이 교구</TitleComponents.MainTitle>
                    <TitleComponents.Description>
                        <span>이론 내용을 재미있고 자연스럽게 익히고</span>
                        <span>초등 음악교과정을 연계, 유익한 내용이 가득한</span>
                        <span>신개념 음악 교구</span>
                    </TitleComponents.Description>
                </TitleComponents>
                <Grid container spacing={2} sx={{ pb: 5, justifyContent: "center" }}>
                    {hookTools.map((item, index) => (
                        <Grid item key={`${item.title}-${index}`} xs={12} md={6} lg={4}>
                            <ImgHeaderCard
                                headerTitle={item.headerTitle}
                                headerBgColor={item.headerBgColor}
                                imgSrc={item.imgSrc}
                                imgStyle={{ maxWidth: 300, margin: "auto" }}>
                                {item.desc}
                            </ImgHeaderCard>
                        </Grid>
                    ))}
                </Grid>
            </ContainerLayout>
            {/* 음악 교구 */}
            <Divider sx={{ mt: 4 }} />
            <ContainerLayout bgColor="constant.darkWhite">
                <TitleComponents sx={{ pt: 8, textAlign: "center" }}>
                    <TitleComponents.MainTitle>음악 교구</TitleComponents.MainTitle>
                    <TitleComponents.Description>
                        <span>이론 내용을 재미있고 자연스럽게 익히고</span>
                        <span>초등 음악교과정을 연계, 유익한 내용이 가득한</span>
                        <span>신개념 음악 교구</span>
                    </TitleComponents.Description>
                </TitleComponents>
                {/* 음악교구 */}
                <Grid container columnSpacing={2} sx={{ mt: 3, mb: 5 }}>
                    {musicTools.map((item, index) => (
                        <Grid key={`${item.title}-${index}`} item xs={6} md={3} sx={{ py: 2 }}>
                            <Stack
                                sx={{
                                    borderRadius: "30px",
                                    overflow: "hidden",
                                    border: "1px solid",
                                    borderColor: "constant.lightGray",
                                }}>
                                <Stack sx={{ px: 2, py: 3, backgroundColor: "white" }}>
                                    <img
                                        src={item.imgSrc}
                                        style={{
                                            margin: "auto",
                                            width: 130,
                                            height: 170,
                                            objectFit: "contain",
                                        }}
                                        alt={item.title}
                                    />
                                </Stack>
                                <Stack sx={{ py: 2 }}>
                                    <Typography variant="body1" sx={{ textAlign: "center" }}>
                                        {item.title}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </ContainerLayout>
        </>
    );
}

const hookTools = [
    {
        headerTitle: "STEP 1 - 8종",
        headerBgColor: "rgba(254, 210, 0, 0.5)",
        imgSrc: "/images/imuzemall/tool/step-1.png",
        desc: (
            <>
                <span>피아노건반의 다양한 계이름, 손가락 번호 등 음악의</span>
                <span>기초를 즐겁고 재미있게 배울 수 있는 찍찍이 교구</span>
            </>
        ),
    },
    {
        headerTitle: "STEP 2 - 15종",
        headerBgColor: "rgba(241, 156, 193, 0.5)",
        imgSrc: "/images/imuzemall/tool/step-2.png",
        desc: (
            <>
                <span>음표&쉼표, 높은음자리표, 계이름, 박자 등 다양한</span>
                <span>악기에 대해 쉽고 재미있게 배울 수 있는 찍찍이 교구</span>
            </>
        ),
    },
    {
        headerTitle: "STEP 3 - 17종",
        headerBgColor: "rgba(130, 192, 45, 0.5)",
        imgSrc: "/images/imuzemall/tool/step-3.png",
        desc: (
            <>
                <span>셈여림, 빠르기, 화음, 코드 등 복잡하고 어려운</span>
                <span>음악이론을 쉽고 재미있게 배우는 찍찍이 교구</span>
            </>
        ),
    },
];

const musicTools = [
    { imgSrc: "/images/imuzemall/tool/music-tool-1.png", title: "발바닥 스티커" },
    { imgSrc: "/images/imuzemall/tool/music-tool-2.png", title: "컵쌓기" },
    { imgSrc: "/images/imuzemall/tool/music-tool-3.png", title: "마카북" },
    { imgSrc: "/images/imuzemall/tool/music-tool-4.png", title: "포켓 교구" },
    { imgSrc: "/images/imuzemall/tool/music-tool-5.png", title: "발건반, 발오선 시트" },
    { imgSrc: "/images/imuzemall/tool/music-tool-6.png", title: "환경판" },
    { imgSrc: "/images/imuzemall/tool/music-tool-7.png", title: "하회탈 가면 만들기" },
    { imgSrc: "/images/imuzemall/tool/music-tool-8.png", title: "오페라 가면 만들기" },
    { imgSrc: "/images/imuzemall/tool/music-tool-9.png", title: "그랜드 피아노 만들기" },
    { imgSrc: "/images/imuzemall/tool/music-tool-10.png", title: "업라이트 피아노 만들기" },
    { imgSrc: "/images/imuzemall/tool/music-tool-11.png", title: "또리의 손인형 만들기" },
    { imgSrc: "/images/imuzemall/tool/music-tool-12.png", title: "계이름&음이름 피자책" },
    { imgSrc: "/images/imuzemall/tool/music-tool-13.png", title: "음이름&계이름 물고기" },
    { imgSrc: "/images/imuzemall/tool/music-tool-14.png", title: "춤추는 손인형 만들기" },
    { imgSrc: "/images/imuzemall/tool/music-tool-15.png", title: "작곡가 카드 12종" },
    { imgSrc: "/images/imuzemall/tool/music-tool-16.png", title: "장구 만들기" },
];
