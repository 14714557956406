import React, { useState } from "react";

import { Stack, Typography } from "@mui/material";

export default function ImgTabCard({ tabList, footerBgColor = "constant.darkWhite" }) {
    const [tab, setTab] = useState(tabList[0]);

    const handleTabClick = index => {
        setTab(tabList[index]);
    };

    return (
        <Stack sx={{ bgcolor: "white", borderRadius: "30px" }}>
            {/* Tab */}
            <Stack
                direction="row"
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    "div:first-of-type": {
                        borderRadius: "30px 0 0 0",
                    },
                    "div:last-of-type": {
                        borderRadius: "0 30px 0 0",
                    },
                }}>
                {tabList.map((item, index) => {
                    return (
                        <Stack
                            key={item.tabTitle}
                            onClick={() => handleTabClick(index)}
                            sx={{
                                flex: 1,
                                py: 2,
                                border: "1px solid",
                                cursor: "pointer",
                                color: tab.tabTitle === item.tabTitle ? "text.white" : "text.disabled",
                                borderColor: tab.tabTitle === item.tabTitle ? item.activeBgColor : "constant.lightGray",
                                backgroundColor:
                                    tab.tabTitle === item.tabTitle ? item.activeBgColor : "constant.darkWhite",
                            }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: "bold", textAlign: "center" }}>
                                {item.tabTitle}
                            </Typography>
                        </Stack>
                    );
                })}
            </Stack>
            {/* Image */}
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                    height: "216px",
                    width: "247px",
                    // borderLeft: "1px solid",
                    // borderRight: "1px solid",
                    // borderColor: "constant.lightGray",
                    margin: "auto",
                }}>
                <img src={tab.tabImg} alt="tab img" />
            </Stack>
            {/* Description */}
            <Stack
                sx={{
                    p: 2.5,
                    backgroundColor: "constant.darkWhite",
                    borderRadius: "0 0 32px 32px",
                    border: "1px solid",
                    borderColor: footerBgColor !== "constant.darkWhite" ? footerBgColor : "constant.lightGray",
                }}>
                {tab.tabDescription}
            </Stack>
        </Stack>
    );
}
