import React from "react";
import { IconButton } from "@mui/material";
import { NavigateBeforeRounded } from "@mui/icons-material";

export default function PrevArrowBtn({ onClick, btnBgColor = "constant.white" }) {
    return (
        <IconButton
            id="slick-prev-button"
            onClick={onClick}
            sx={{ position: "absolute", zIndex: 10, backgroundColor: btnBgColor }}>
            <NavigateBeforeRounded />
        </IconButton>
    );
}
