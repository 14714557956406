import { createTheme } from "@mui/material";

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 410,
            md: 770,
            lg: 1024,
            xl: 1320,
        },
    },
    palette: {
        primary: {
            main: "#fe3a54",
            light: "#fcdc5d",
            dark: "#f3c500",
            hover: "rgba(254, 58, 84, 0.04)",
        },
        secondary: {
            main: "#00b83a",
            light: "#00b83a",
            dark: "#00b83a",
            hover: "rgba(0, 184, 58, 0.04)",
        },
        littleBand: {
            main: "#3390ff",
            hover: "rgba(51, 144, 255, 0.04)",
            pianogogo: "#2a68c6",
            pianogogoLight: "#b4d2ff",
            pianogogoDesc: "#d5e9ff",
            musicgogo: "#ff641d",
            musicgogoLight: "#ffd6c9",
        },
        littleArtist: {
            main: "#ffa324",
            medium: "#ffe1b7",
            light: "#ffedd5",
            hover: "rgba(255, 149, 33, 0.04)",
        },
        text: {
            primary: "#343434",
            secondary: "#555555",
            disabled: "#a0a0a0",
            white: "#ffffff",
        },
        constant: {
            posterBg: "#fbfbfa",
            white: "#ffffff",
            darkWhite: "#f5f5f5",
            lightGray: "#e5e5e5",
            gray: "#c6c6c6",
            darkGray: "#a0a0a0",
            black: "#343434",
        },
        footer: {
            background: "#555555",
            content: "#c6c6c6",
            divider: "#a0a0a0",
        },
    },
    typography: {
        useNextVariant: false,
        h1: {
            fontFamily: "JalnanGothic",
            fontSize: 36,
            fontWeight: "bold",
            lineHeight: "normal",
            letterSpacing: -0.29,
            userSelect: "none",
        },
        h2: {
            fontFamily: "JalnanGothic",
            fontSize: 28,
            fontWeight: 700,
            lineHeight: 1.36,
            letterSpacing: -0.22,
            userSelect: "none",
        },
        h3: {
            fontFamily: "JalnanGothic",
            fontSize: 20,
            fontWeight: 700,
            lineHeight: 1.95,
            letterSpacing: -0.16,
            userSelect: "none",
        },
        h4: {
            fontFamily: "JalnanGothic",
            fontSize: 18,
            lineHeight: 1.56,
            letterSpacing: -0.14,
            userSelect: "none",
        },
        subtitle1: {
            fontFamily: "Pretendard",
            fontWeight: 700,
            fontSize: 16,
            lineHeight: 1.44,
            letterSpacing: -0.26,
            userSelect: "none",
        },
        subtitle2: {
            fontFamily: "Pretendard",
            fontWeight: 500,
            fontSize: 16,
            lineHeight: 1.38,
            letterSpacing: -0.26,
            userSelect: "none",
        },
        subtitle3: {
            fontFamily: "Pretendard",
            fontWeight: 700,
            fontSize: 14,
            lineHeight: "normal",
            letterSpacing: "normal",
            userSelect: "none",
        },
        body1: {
            fontFamily: "Pretendard",
            fontWeight: 500,
            fontSize: 14,
            lineHeight: 1.5,
            letterSpacing: -0.22,
            userSelect: "none",
        },
        body2: {
            fontFamily: "Pretendard",
            fontWeight: 500,
            fontSize: 13,
            lineHeight: 1.54,
            letterSpacing: -0.57,
            userSelect: "none",
        },
        caption: {
            fontFamily: "Pretendard",
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 1.83,
            letterSpacing: -0.1,
            userSelect: "none",
        },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    "@media (min-width: 410px)": {
                        paddingLeft: "16px",
                        paddingRight: "16px",
                    },
                    "@media (min-width: 770px)": {
                        paddingLeft: "16px",
                        paddingRight: "16px",
                    },
                    "@media (min-width: 1024px)": {
                        paddingLeft: "24px",
                        paddingRight: "24px",
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    userSelect: "text",
                },
            },
        },
    },
});

export default theme;
