import React from "react";
import { useLocation } from "react-router-dom";

import gallery_data from "../../components/data/gallery_data.js";

import GalleryDetailList from "../../components/layouts/GalleryDetailList.js";

export function GalleryDetail() {
    const location = useLocation();
    const { brand, params } = location?.state;

    const DATA =
        brand === "littleband" ? gallery_data.music : brand === "littleartists" ? gallery_data.art : gallery_data.jueun;
    const GALS = DATA.filter(d => String(d.params) === String(params))[0];
    const info = GALS.length === 0 ? DATA[0] : GALS;

    return <GalleryDetailList isNews={false} brand={brand} info={info} />;
}
