import React, { Fragment } from "react";

import { Divider, Stack, Typography } from "@mui/material";
import { TitleComponents } from "../../titleComponent/TitleComponents";
import HeaderCard from "../../cards/HeaderCard";
import ImgCard from "../../cards/ImgCard";
import ImgSlickCard from "../../cards/ImgSlickCard";
import RoundedButton from "../../elements/RoundedButton";
import ContainerLayout from "../../layouts/ContainerLayout";
import ImuzmallLinkButton from "../../elements/ImuzmallLinkButton";

export default function FusionTab() {
    const openArtistCafe = () => {
        const openNewWindow = window.open("about:blank");
        openNewWindow.location.href = "https://cafe.naver.com/jueunart";
    };

    return (
        <>
            {/* 요약 */}
            <ContainerLayout>
                <Stack sx={{ mt: 5, pb: 8 }}>
                    <TitleComponents sx={{ mb: 4, textAlign: "center" }}>
                        <TitleComponents.MainTitle>바탕&융합</TitleComponents.MainTitle>
                        <TitleComponents.Description>
                            <span>다양한 표현을 통해 미술영역의 감각, 창의, 인지능력을</span>
                            <span>깨워주는 연령&단계별 미술 교육 정규 프로그램 입니다</span>
                        </TitleComponents.Description>
                    </TitleComponents>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={2} sx={{ px: { xs: 0, md: 8 } }}>
                        <HeaderCard
                            headerTitle="바탕 프로그램"
                            headerBgColor="#ec6f64"
                            headerFontColor="constant.white"
                            cardStyle={{ flex: 1, mb: 2 }}
                            contentBoxStyle={{ backgroundColor: "constant.darkWhite", px: 3.5, py: 2 }}>
                            <Stack spacing={2}>
                                {natureDescriptions.map(item => (
                                    <Stack
                                        key={item}
                                        direction="row"
                                        spacing={1}
                                        sx={{ py: 1.5, px: 2, backgroundColor: "constant.white", borderRadius: 6 }}>
                                        <img src="/images/littleartists/icon/check-icon-red.svg" alt="check icon" />
                                        <Typography>{item}</Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </HeaderCard>
                        <HeaderCard
                            headerTitle="융합 프로그램"
                            headerBgColor="#f6b958"
                            headerFontColor="constant.white"
                            cardStyle={{ flex: 1 }}
                            contentBoxStyle={{ backgroundColor: "constant.darkWhite", px: 3.5, py: 2 }}>
                            <Stack spacing={2}>
                                {fusionDescriptions.map(item => (
                                    <Stack
                                        key={item}
                                        direction="row"
                                        spacing={1}
                                        sx={{ py: 1.5, px: 2, backgroundColor: "constant.white", borderRadius: 6 }}>
                                        <img src="/images/littleartists/icon/check-icon-yellow.svg" alt="check icon" />
                                        <Typography>{item}</Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </HeaderCard>
                    </Stack>
                </Stack>
            </ContainerLayout>
            {/* 연령별 5단계 */}
            <ContainerLayout bgColor="constant.darkWhite" sx={{ pt: 9, pb: 8 }}>
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={{ xs: 0, md: 15 }}
                    sx={{ px: { xs: 0, md: 8 } }}>
                    <TitleComponents sx={{ mb: 5 }}>
                        <TitleComponents.MainTitle>
                            <span>연령별 5단계</span>
                            <span>프로그램</span>
                        </TitleComponents.MainTitle>
                    </TitleComponents>
                    <ImgCard
                        bodyColor="constant.white"
                        smallImgSrc="/images/littleartists/five-level.png"
                        smallImgWidth="272px"
                        smallImgTop="48px"
                        cardStyle={{ flex: 1 }}
                        descStyle={{
                            span: { display: "block" },
                            borderTop: "1px solid",
                            borderColor: "constant.lightGray",
                        }}>
                        <span>연령에 따라 5가지로 제공되는</span>
                        <span>타임별 소수정예 시스템</span>
                    </ImgCard>
                </Stack>
            </ContainerLayout>
            {/* 5단계 설명 */}
            {/* 놀이미술 */}
            <Stack sx={{ pt: 1, pb: 0.5, backgroundColor: "#00ae86" }}>
                <Typography variant="h3" sx={{ color: "constant.white", textAlign: "center" }}>
                    놀이미술
                </Typography>
            </Stack>
            {playArtArr.map((item, index) => (
                <Fragment key={item.mainTitle}>
                    {index !== 0 && <Divider sx={{ backgroundColor: "constant.gray" }} />}
                    <ContainerLayout>
                        <Stack direction={{ xs: "column", md: "row" }} sx={{ px: { xs: 0, md: 8 } }}>
                            <TitleComponents sx={{ mt: { xs: 8, md: 10 }, mb: { md: 10 }, mr: "auto" }}>
                                <TitleComponents.Subtitle
                                    stackStyle={{ flexDirection: "row", alignItems: "center" }}
                                    typoStyle={{ color: item.subtitleColor }}
                                    endIcon={item.endIcon}>
                                    {item.subtitle}
                                </TitleComponents.Subtitle>
                                <TitleComponents.MainTitle>{item.mainTitle}</TitleComponents.MainTitle>
                                <TitleComponents.Description>{item.description}</TitleComponents.Description>
                            </TitleComponents>
                            <Stack sx={{ mt: 5, mb: 8 }}>
                                <ImgSlickCard
                                    sliderArr={item.sliderArr}
                                    stackStyle={{ height: 212 }}
                                    btnBgColor="transparent"
                                    sliderStyle={{ width: { md: 300, lg: 400 } }}
                                    imgStyle={{ width: "80%", height: "100%", objectFit: "contain" }}></ImgSlickCard>
                            </Stack>
                        </Stack>
                    </ContainerLayout>
                </Fragment>
            ))}
            {/* 통합미술 */}
            <Stack sx={{ pt: 1, pb: 0.5, backgroundColor: "#00a1ad" }}>
                <Typography variant="h3" sx={{ color: "constant.white", textAlign: "center" }}>
                    통합미술
                </Typography>
            </Stack>
            {combineArtArr.map((item, index) => (
                <Fragment key={item.mainTitle}>
                    {index !== 0 && <Divider sx={{ backgroundColor: "constant.gray" }} />}
                    <ContainerLayout>
                        <Stack direction={{ xs: "column", md: "row" }} sx={{ px: { xs: 0, md: 8 } }}>
                            <TitleComponents sx={{ mt: { xs: 8, md: 10 }, mb: { md: 10 }, mr: "auto" }}>
                                <TitleComponents.Subtitle
                                    stackStyle={{ flexDirection: "row", alignItems: "center" }}
                                    typoStyle={{ color: item.subtitleColor }}
                                    endIcon={item.endIcon}>
                                    {item.subtitle}
                                </TitleComponents.Subtitle>
                                <TitleComponents.MainTitle>{item.mainTitle}</TitleComponents.MainTitle>
                                <TitleComponents.Description>{item.description}</TitleComponents.Description>
                            </TitleComponents>
                            <Stack sx={{ mt: 5, mb: 8 }}>
                                <ImgSlickCard
                                    sliderArr={item.sliderArr}
                                    stackStyle={{ height: 212 }}
                                    btnBgColor="transparent"
                                    sliderStyle={{ width: { md: 300, lg: 400 } }}
                                    imgStyle={{ width: "80%", height: "100%", objectFit: "contain" }}></ImgSlickCard>
                            </Stack>
                        </Stack>
                    </ContainerLayout>
                </Fragment>
            ))}
            {/* 전문미술 */}
            <Stack sx={{ pt: 1, pb: 0.5, backgroundColor: "#5386b7" }}>
                <Typography variant="h3" sx={{ color: "constant.white", textAlign: "center" }}>
                    전문미술
                </Typography>
            </Stack>
            {seniorArtArr.map((item, index) => (
                <Fragment key={item.mainTitle}>
                    {index !== 0 && <Divider sx={{ backgroundColor: "constant.gray" }} />}
                    <ContainerLayout>
                        <Stack direction={{ xs: "column", md: "row" }} sx={{ px: { xs: 0, md: 8 } }}>
                            <TitleComponents sx={{ mt: { xs: 8, md: 10 }, mb: { md: 10 }, mr: "auto" }}>
                                <TitleComponents.Subtitle
                                    stackStyle={{ flexDirection: "row", alignItems: "center" }}
                                    typoStyle={{ color: item.subtitleColor }}
                                    endIcon={item.endIcon}>
                                    {item.subtitle}
                                </TitleComponents.Subtitle>
                                <TitleComponents.MainTitle>{item.mainTitle}</TitleComponents.MainTitle>
                                <TitleComponents.Description>{item.description}</TitleComponents.Description>
                            </TitleComponents>
                            <Stack sx={{ mt: 5, mb: 8 }}>
                                <ImgSlickCard
                                    sliderArr={item.sliderArr}
                                    stackStyle={{ height: 212 }}
                                    btnBgColor="transparent"
                                    sliderStyle={{ width: { md: 300, lg: 400 } }}
                                    imgStyle={{ width: "80%", height: "100%", objectFit: "contain" }}></ImgSlickCard>
                            </Stack>
                        </Stack>
                    </ContainerLayout>
                </Fragment>
            ))}
            {/* SNS 버튼 */}
            <Stack spacing={2} sx={{ mb: 8, alignItems: "center" }}>
                <RoundedButton onClick={openArtistCafe} size="large" variant="contained" bgcolor="#3cb400">
                    네이버 카페 바로가기
                </RoundedButton>
                <ImuzmallLinkButton />
            </Stack>
        </>
    );
}

const playArtArr = [
    {
        mainTitle: "오르세",
        subtitle: "감각 중심의 놀이 학습",
        endIcon: (
            <Stack
                justifyContent="center"
                sx={{ ml: 1, px: 1, height: 19, backgroundColor: "#db838f", borderRadius: 5 }}>
                <Typography variant="caption" sx={{ height: 19, color: "constant.white" }}>
                    4~5세
                </Typography>
            </Stack>
        ),
        subtitleColor: "#00ae86",
        description: (
            <>
                <span>유치부 연령을 대상으로 누리과정과 연계된 교육 프로그램</span>
                <span>놀이를 통해 다양한 재료를 경험하며 미술에 대한 흥미를</span>
                <span>유발하고, 소, 대근육 발달, 탐색을 통한 오감 발달을</span>
                <span>유도하는 감각 활동 중심의 놀이 학습 단계</span>
            </>
        ),
        sliderArr: [
            { imgSrc: "/images/littleartists/program/orsay/오르세-1.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/orsay/오르세-2.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/orsay/오르세-3.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/orsay/오르세-4.jpg", backgroundColor: "constant.white" },
        ],
    },
    {
        mainTitle: "오르세플러스",
        subtitle: "스토리와 놀이로 배우는 미술 표현력 확장",
        endIcon: (
            <Stack
                justifyContent="center"
                sx={{ ml: 1, px: 1, height: 19, backgroundColor: "#f18b23", borderRadius: 5 }}>
                <Typography variant="caption" sx={{ height: 19, color: "constant.white" }}>
                    6~7세
                </Typography>
            </Stack>
        ),
        subtitleColor: "#00ae86",
        description: (
            <>
                <span>유치부 연령을 대상으로 누리과정과 연계된 교육 프로그램.</span>
                <span>다양한 미술 재료를 경험해 보며 미술에 대한 흥미를 유발하고,</span>
                <span>여러 가지 주제를 스토리와 놀이로 배우는 미술 경험을</span>
                <span>토대로 자기 표현력을 키우고 창의적인 사고를 확장하는 단계</span>
            </>
        ),
        sliderArr: [
            {
                imgSrc: "/images/littleartists/program/orsay-plus/오르세플러스-1.jpg",
                backgroundColor: "constant.white",
            },
            {
                imgSrc: "/images/littleartists/program/orsay-plus/오르세플러스-2.jpg",
                backgroundColor: "constant.white",
            },
            {
                imgSrc: "/images/littleartists/program/orsay-plus/오르세플러스-3.jpg",
                backgroundColor: "constant.white",
            },
            {
                imgSrc: "/images/littleartists/program/orsay-plus/오르세플러스-4.jpg",
                backgroundColor: "constant.white",
            },
        ],
    },
];

const combineArtArr = [
    {
        mainTitle: "루브르",
        subtitle: "주제를 구체적으로 표현해내는 미술 표현력",
        endIcon: (
            <Stack
                justifyContent="center"
                sx={{ ml: 1, px: 1, height: 19, backgroundColor: "#8cbc36", borderRadius: 5 }}>
                <Typography variant="caption" sx={{ height: 19, color: "constant.white" }}>
                    8~9세
                </Typography>
            </Stack>
        ),
        subtitleColor: "#00a1ad",
        description: (
            <>
                <span>초등 저학년을 대상으로 초등통합교과과정과 연계된</span>
                <span>교육 프로그램. 바른 선행학습을 통해 또래보다 앞서 나가는</span>
                <span>미술실력을 토대로 자신감을 키우고, 스토리텔링을 통해</span>
                <span>주제와 해결에 대한 생각을 나누며, 이야기 발상 활동을 통해</span>
                <span>주제 표현력을 키우는 단계</span>
            </>
        ),
        sliderArr: [
            { imgSrc: "/images/littleartists/program/louvre/루브르-1.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/louvre/루브르-2.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/louvre/루브르-3.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/louvre/루브르-4.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/louvre/루브르-5.jpg", backgroundColor: "constant.white" },
        ],
    },
];

const seniorArtArr = [
    {
        mainTitle: "메트로",
        subtitle: "주제에 대한 통합 사고와 표현력",
        endIcon: (
            <Stack
                justifyContent="center"
                sx={{ ml: 1, px: 1, height: 19, backgroundColor: "#db516a", borderRadius: 5 }}>
                <Typography variant="caption" sx={{ height: 19, color: "constant.white" }}>
                    10~11세
                </Typography>
            </Stack>
        ),
        subtitleColor: "#5386b7",
        description: (
            <>
                <span>초등 중학년을 대상으로 초등통합교과과정과 연계된</span>
                <span>교육 프로그램. 사물을 관찰하는 방법을 배우며 정물화, 풍경화와</span>
                <span>같은 회화 수업에 필요한 기초 표현력을 기르고, 창의력과</span>
                <span>집중력을 필요로 하는 발상 수업을 통해 융합적 사고와</span>
                <span>시각적 표현을 경험하는 단계</span>
            </>
        ),
        sliderArr: [
            { imgSrc: "/images/littleartists/program/metro/메트로-1.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/metro/메트로-2.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/metro/메트로-3.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/metro/메트로-4.jpg", backgroundColor: "constant.white" },
            { imgSrc: "/images/littleartists/program/metro/메트로-5.jpg", backgroundColor: "constant.white" },
        ],
    },
    {
        mainTitle: "메트로플러스",
        subtitle: "수행 대비, 통합사고, 발상으로 표현의 심화",
        subtitleColor: "#5386b7",
        description: (
            <>
                <span>초등 고학년을 대상으로 초중등미술교과과정과 연계된</span>
                <span>교육 프로그램. 주제의 관점을 창의적으로 재해석하여</span>
                <span>응용하는 단계이며 전문적인 회화 표현에 필요한 기법을</span>
                <span>배우고 중학교 미술 수업에 효율적인 대비가 가능한 심화단계</span>
            </>
        ),
        sliderArr: [
            {
                imgSrc: "/images/littleartists/program/metro-plus/메트로플러스-1.jpg",
                backgroundColor: "constant.white",
            },
            {
                imgSrc: "/images/littleartists/program/metro-plus/메트로플러스-2.jpg",
                backgroundColor: "constant.white",
            },
            {
                imgSrc: "/images/littleartists/program/metro-plus/메트로플러스-3.jpg",
                backgroundColor: "constant.white",
            },
            {
                imgSrc: "/images/littleartists/program/metro-plus/메트로플러스-4.jpg",
                backgroundColor: "constant.white",
            },
            {
                imgSrc: "/images/littleartists/program/metro-plus/메트로플러스-5.jpg",
                backgroundColor: "constant.white",
            },
        ],
    },
];

const natureDescriptions = [
    "선긋기에서 드로잉으로",
    "미술의 기초와 기본",
    "차근차근 단계별 수업",
    "미술이 쉬워지는 바탕 프로그램",
];

const fusionDescriptions = [
    "통합 미술 경험의 확장",
    "스토리텔링 & 스토리두잉",
    "연령별 주제 통합 수업",
    "생각이 보이는 융합 프로그램",
];
