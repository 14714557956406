import React from "react";
import { Button, Typography } from "@mui/material";

/* variant = "contained" || "outlined"
color는 theme에 설정되어 있는 palette에서 사용
sx로 추가 style 적용 가능
 *  */
export default function RoundedButton({
    size = "small",
    variant = "outlined",
    children = "content",
    color = "primary.main",
    otherColor,
    otherHoverColor,
    bgcolor,
    stickyColor,
    startIcon,
    endIcon,
    sx,
    onClick,
}) {
    const main = color.split(".")[0] + ".main";

    const style =
        variant === "outlined"
            ? {
                  color: otherColor ? otherColor : main,
                  borderColor: bgcolor ? bgcolor : main,
                  backgroundColor: stickyColor ? "white" : "transparent",
                  ":hover": {
                      color: otherHoverColor ? otherHoverColor : main,
                      borderColor: bgcolor ? bgcolor : main,
                      backgroundColor: bgcolor ? bgcolor : main,
                  },
                  ...sx,
              }
            : {
                  color: "white",
                  backgroundColor: bgcolor ? bgcolor : main,
                  borderColor: bgcolor ? bgcolor : main,
                  ":hover": {
                      borderColor: bgcolor ? bgcolor : main,
                      backgroundColor: bgcolor ? bgcolor : main,
                  },
                  ...sx,
              };

    switch (size) {
        case "small":
            return (
                <Button variant={variant} sx={{ width: 110, height: 46, borderRadius: 6, ...style }} onClick={onClick}>
                    {startIcon && startIcon}
                    <Typography variant="subtitle3">{children}</Typography>
                    {endIcon && endIcon}
                </Button>
            );
        case "large":
            return (
                <Button variant={variant} sx={{ width: 236, height: 50, borderRadius: 8, ...style }} onClick={onClick}>
                    {startIcon && startIcon}
                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                        {children}
                    </Typography>
                    {endIcon && endIcon}
                </Button>
            );
    }
}
