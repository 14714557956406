import React from "react";
import { useLocation } from "react-router-dom";

import NoticDetail from "../../components/layouts/NoticeDetail.js";
import news_data from "../../components/data/news_data.js";

export function NewsDetail() {
    const location = useLocation();
    const { brand, params } = location?.state;

    const DATA = brand === "littleband" ? news_data.music : brand === "littleartists" ? news_data.art : news_data.jueun;

    const NEWS = DATA.filter(d => String(d.params) === String(params))[0];
    const info = NEWS.length === 0 ? DATA[0] : NEWS;
    return <NoticDetail isNews brand={brand} info={info} />;
}
