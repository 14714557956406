import React from "react";
import { Dialog, DialogActions, IconButton, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ArrowBackRounded, ArrowForwardRounded, CloseRounded } from "@mui/icons-material";

export default function GalleryDetailSlide({ open, setOpen, info, index, setIndex }) {
    const matches = useMediaQuery("(min-width:600px)");
    const handleLeft = () => {
        if (index > 0) {
            setIndex(index - 1);
        }
    };
    const handleRight = () => {
        if (index < info.des.length - 1) {
            setIndex(index + 1);
        }
    };
    return (
        <Dialog
            open={open}
            fullScreen={!matches}
            fullWidth
            maxWidth="lg"
            onClose={() => setOpen(false)}
            PaperComponent={Stack}
            sx={{
                "& .MuiPaper-root": {
                    background: "#000",
                },
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0,0,0,0.8)", // "transparent",
                },
            }}>
            {!matches && (
                <Stack alignItems="center">
                    <IconButton
                        onClick={() => setOpen(false)}
                        sx={{
                            mt: 2,
                            color: "white",
                            backgroundColor: "rgba(0, 0, 0, 0.4)",
                            borderRadius: "50%",
                            width: "60px",
                            height: "60px",
                        }}>
                        <CloseRounded sx={{ fontSize: 50 }} />
                    </IconButton>
                </Stack>
            )}
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                    height: "100%",
                    width: "100%",
                }}>
                <img src={info.des[index]} width="100%" style={{ boxShadow: "5px 5px 5px #000" }} />
            </Stack>
            {matches && (
                <DialogActions>
                    <IconButton onClick={() => setOpen(false)}>
                        <CloseRounded sx={{ color: "white", fontSize: 30 }} />
                    </IconButton>
                </DialogActions>
            )}
            <Stack alignItems="center" justifyContent="center" sx={{ position: "relative", width: "100%" }}>
                <Stack direction="row" spacing={10} sx={{ position: "absolute", bottom: "60px" }}>
                    <IconButton
                        onClick={handleLeft}
                        color="primary"
                        sx={{
                            backgroundColor: "rgba(0, 0, 0, 0.9)",
                            borderRadius: "50%",
                            width: "60px",
                            height: "60px",
                            ":hover": {
                                backgroundColor: index == 0 ? "none" : "primary.main",
                            },
                            ":focus": {
                                backgroundColor: index == 0 ? "none" : "primary.main",
                            },
                        }}>
                        <ArrowBackRounded sx={{ color: "white", fontSize: 30 }} />
                    </IconButton>
                    <IconButton
                        onClick={handleRight}
                        color="primary"
                        sx={{
                            backgroundColor: "rgba(0, 0, 0, 0.9)",
                            borderRadius: "50%",
                            width: "60px",
                            height: "60px",
                            ":hover": {
                                backgroundColor: index === info.des.length - 1 ? "none" : "primary.main",
                            },
                            ":focus": {
                                backgroundColor: index === info.des.length - 1 ? "none" : "primary.main",
                            },
                        }}>
                        <ArrowForwardRounded sx={{ color: "white", fontSize: 30 }} />
                    </IconButton>
                </Stack>
            </Stack>
        </Dialog>
    );
}
