import React from "react";
import { IconButton } from "@mui/material";
import { NavigateNextRounded } from "@mui/icons-material";

export default function NextArrowBtn({ onClick, btnBgColor = "constant.white" }) {
    return (
        <IconButton
            id="slick-next-button"
            onClick={onClick}
            sx={{ position: "absolute", zIndex: 10, backgroundColor: btnBgColor }}>
            <NavigateNextRounded />
        </IconButton>
    );
}
