import React from "react";

import { Grid, Stack, Typography } from "@mui/material";
import { AddCircleRounded, KeyboardArrowDownOutlined } from "@mui/icons-material";

import { TitleComponents } from "../../titleComponent/TitleComponents";
import HeaderCard from "../../cards/HeaderCard";
import ContainerLayout from "../../layouts/ContainerLayout";

export default function MusicGogoIntro() {
    return (
        <ContainerLayout bgColor="constant.darkWhite" sx={{ py: 8 }}>
            <TitleComponents sx={{ mt: 6, mb: 5.5 }}>
                <TitleComponents.Subtitle typoStyle={{ color: "littleBand.musicgogo" }}>
                    블렌디드 음악체험 통합 놀이 교육!
                </TitleComponents.Subtitle>
                <TitleComponents.MainTitle>뮤직고고란?</TitleComponents.MainTitle>
                <TitleComponents.Description>
                    <span>유·아동에게 최적화된 악기연주, 신체활동,</span>
                    <span>음악이론, 감상&표현을 포함한 음악교육 전 영역의</span>
                    <span>음악체험 통합놀이 교육 프로그램입니다</span>
                </TitleComponents.Description>
            </TitleComponents>
            <Stack>
                <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
                    <HeaderCard
                        headerTitle="온라인 교육"
                        headerBgColor="littleBand.musicgogo"
                        headerFontColor="constant.white"
                        cardStyle={{ width: "100%" }}
                        contentBoxStyle={{ px: 2, py: 2, backgroundColor: "constant.white" }}>
                        <Typography sx={{ textAlign: "center", span: { display: "block" } }}>
                            <span>교육영상을 활용한</span>
                            <span>주제 및 활동 제시하기</span>
                        </Typography>
                    </HeaderCard>
                    <AddCircleRounded fontSize="large" sx={{ color: "littleBand.musicgogo" }} />
                    <HeaderCard
                        headerTitle="오프라인 교육"
                        headerBgColor="littleBand.musicgogo"
                        headerFontColor="constant.white"
                        cardStyle={{ width: "100%" }}
                        contentBoxStyle={{ px: 2, py: 2, backgroundColor: "constant.white" }}>
                        <Typography sx={{ textAlign: "center", span: { display: "block" } }}>
                            <span>교구, 워크북을 활용한</span>
                            <span>인지, 학습, 활동하기</span>
                        </Typography>
                    </HeaderCard>
                </Stack>
                <KeyboardArrowDownOutlined fontSize="large" sx={{ mx: "auto", color: "littleBand.musicgogo" }} />
                <Stack sx={{ mb: 5.5, py: 1, backgroundColor: "littleBand.musicgogo", borderRadius: 8 }}>
                    <Typography variant="h3" sx={{ textAlign: "center", color: "constant.white" }}>
                        알파세대 맞춤교육!
                    </Typography>
                </Stack>
                <Grid container spacing={2}>
                    {imageArr.map(item => (
                        <Grid key={item.alt} item xs={6} md={3}>
                            <div style={{ position: "relative", width: "100%", paddingTop: "100%" }}>
                                <img
                                    src={item.imgSrc}
                                    alt={item.alt}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "56px",
                                        objectFit: "cover",
                                    }}
                                />
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        </ContainerLayout>
    );
}

const imageArr = [
    { imgSrc: "/images/littleband/music-gogo/picture-1.png", alt: "picture-1.png" },
    { imgSrc: "/images/littleband/music-gogo/picture-2.png", alt: "picture-2.png" },
    { imgSrc: "/images/littleband/music-gogo/picture-3.png", alt: "picture-3.png" },
    { imgSrc: "/images/littleband/music-gogo/picture-4.png", alt: "picture-4.png" },
];
